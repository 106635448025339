import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useOverlay } from '../hooks/useOverlay'
import { PopupHeader as Header } from './Container'
import tw, { styled, css } from 'twin.macro'


const wrapperStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1100,
}

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 1101,
}

const PopupContainer = styled.div`
  ${tw`w-full max-w-[425px] min-h-[200px] px-8 pt-6 pb-8 rounded-2xl`}
  background: var(--modal-bg);
  border: 2px solid var(--modal-border);
  position: 'relative';
  z-index: 1102;
`

export const HeaderTitle = styled.div(({ bold }) => [
  css`color: var(--modal-title-primary);`,
  tw`text-xl`,
  bold ? tw`font-bold` : tw`font-medium`
])

export const Content = styled.div(({ spaceBetween }) => [
  spaceBetween && tw`flex flex-col justify-between h-[calc(100vh - 100px)] max-h-[500px]`,
])

export const ContentSection = styled.div(({ center }) => [
  center && tw`text-center flex flex-col items-center`,
])

export const Label = styled.span`
  ${tw`font-bold`}
  color: var(--modal-label);
  + p {
    ${tw`mt-2`}
  }
`

export const Text = styled.p`
  ${tw`font-medium`}
  color: var(--modal-text);
  + p {
    ${tw`mt-2`}
  }
`

const Container = ({ children, isOpen = false, onOverlayClick, elementId = 'root' }) => {
  const ref = useRef(null)
  useOverlay(isOpen, onOverlayClick, ref)

  if (isOpen === false) {
    return null
  }
  return createPortal(
    <div role="dialog" aria-modal style={wrapperStyle}>
      <div style={overlayStyle} onClick={onOverlayClick} />
      <PopupContainer ref={ref}>
        {children}
      </PopupContainer>
    </div>,
    document.getElementById(elementId)
  )
}

const Popup = {
  Container,
  Header,
  HeaderTitle,
  Content,
  ContentSection,
  Label,
  Text,
}

export default Popup
