import React from 'react'
import tw, { styled } from 'twin.macro'

import AccountMenu from './AccountMenu'
import AutoLogin from '../components/AutoLogin'
import { Content } from '../layouts/Layout'

// background-color: darken(${(props) => props.theme.colors.primary}, 0.5);
const Container = styled.header`
  background-color: var(--header-bg);
  ${tw`w-full hidden md:flex items-center py-2 md:py-10 text-center`}
`

const LeftColumn = styled.div`
  ${tw``}
`

const CenterColumn = styled.div`
  ${tw`flex-1`};
`

const RightColumn = styled.div`
  
`

const HoraLogoText = styled.span`
  color: var(--header-text-logo);
  ${tw`inline-block font-semibold text-xl`};
`
const HoraLogoBeta = styled.span`
  ${tw`inline-block font-semibold text-accent ml-2`}
`

export function Header() {
  return (
    <Content isFullWidth>
      <Container>
        <LeftColumn>
          <HoraLogoText>Hora Hub</HoraLogoText>
          <HoraLogoBeta>(beta)</HoraLogoBeta>
        </LeftColumn>

        <CenterColumn>

        </CenterColumn>

        <RightColumn>
          <AccountMenu />
          <AutoLogin />
        </RightColumn>
      </Container>
    </Content>
  )
}
