import React from 'react'
import tw, { styled } from 'twin.macro'
import { IconArrowDropdown } from '../ui/Icons'

const Container = styled.div`
  ${tw`flex items-center`}
  color: var(--main-sections-title);
  fill: var(--main-sections-title);
`
const ButtonArrow = styled.button`
  ${tw`rotate-90 cursor-pointer p-2`}
  ${(props) => (props.isRight ? tw`-rotate-90` : '')}
  ${(props) => (props.disabled ? tw`opacity-50` : '')}
`

const Text = styled.span`
  ${tw`text-lg font-semibold min-w-[2.6rem] text-center`}
`

const Pagination = ({ option, page, pageCount, handlers }) => (
  <Container>
    <ButtonArrow onClick={handlers.prevPage} disabled={!handlers.prevPage}>
      <IconArrowDropdown/>
    </ButtonArrow>
    <Text>
      {option ?
        option
        :
        `${page + 1} / ${pageCount + 1}`
      }
    </Text>
    <ButtonArrow isRight onClick={handlers.nextPage} disabled={!handlers.nextPage}>
      <IconArrowDropdown/>
    </ButtonArrow>
  </Container>
)

export default Pagination
