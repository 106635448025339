export const LayoutEngineTypes = {
  SLIDE: 'slide',
  SLIDE_FIRST: 'slide_first',
}

export function useStackLayoutEngine(type) {
  let getClassName
  switch (type) {
  case LayoutEngineTypes.SLIDE:
    getClassName = (item, items, selectedItem) => {
      const count = items.length
      const isSelected = selectedItem === item
      const index = items.indexOf(item)
      const selectedIndex = items.indexOf(selectedItem)
      return `slideLayout stack-layout--count-${count} ${isSelected ?
        'stack-layout--selected' : 'stack-layout--non-selected'} ` +
          `stack-layout--index-${index} ` +
          `stack-layout--selected-index-${selectedIndex} `
    }
    break
  case LayoutEngineTypes.SLIDE_FIRST:
    getClassName = (item, items, selectedItem) => {
      const count = items.length
      const isSelected = selectedItem === item
      const itemsWithSelectedFirst = [selectedItem].concat(items.filter(i => i !== selectedItem))
      const index = itemsWithSelectedFirst.indexOf(item)
      return `slideFirstLayout stack-layout--count-${count} ${isSelected ? 'stack-layout--selected' : ''} ` +
          `stack-layout--index-${index} `
    }
    break
  }

  return {
    getClassName,
  }
}
