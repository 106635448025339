import React from 'react'
import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line no-unused-vars
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'
import ThemeStyles from './Themes'
import StackLayoutEngineStyles from './StackLayoutEngine'

const CustomStyles = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-fg-primary);
    background-color: var(--color-bg-secondary);
    overscroll-behavior: none;
  }
  
  .web3modal-modal-card {
    max-width: 400px !important;
  }

  #WEB3_CONNECT_MODAL_ID {
    z-index: 1200;
    position: absolute;
  }
  
  .card-collapsed .card-collapse-icon {
    transform: rotate(-90deg);
  }

  .card-collapse-icon {
    transition: all 0.2s ease-in-out;
  }

  .card-collapsed .card-content {
    display: none !important;
  }

  .card-content {
    transition: all 0.2s ease-in-out;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

const GlobalStyles = () => (
  <>
    <ThemeStyles />
    <BaseStyles />
    <CustomStyles />
    <StackLayoutEngineStyles />
  </>
)

export default GlobalStyles
