import React, { useContext } from 'react'
import { ButtonCTA as Button } from '../ui/Button'
import Card, { TokensContainer, ButtonsContainer } from '../ui/Card'
import { Divider } from '../ui/Divider'
import { TokenBalance } from '../ui/TokenBalance'
import { UiContext } from '../contexts/UiContext'
import { useGetUserModel } from '../hooks/useHoraHub'
import useCardCollapse from '../hooks/useCardCollapse'
import { currencies } from '../libs/constants'
// import { useNotifications } from '../hooks/useNotifications'


export function BalanceCard() {
  const { collapsed, collapseToggle } = useCardCollapse('balance')
  const { openModal } = useContext(UiContext)
  const user = useGetUserModel()
  const htokens = user.getTokenAmount(currencies.CURRENCY_HORA_TOKEN, { withdrawable: true, format: true })
  const hcoins = user.getTokenAmount(currencies.CURRENCY_HORA_COIN, { withdrawable: true, format: true })
  // const { NotificationsManager } = useNotifications()

  return (
    <Card.Container collapsed={collapsed}>
      <Card.Header onClick={collapseToggle}>
        <Card.HeaderTitle onClick={collapseToggle}>Balance</Card.HeaderTitle>
      </Card.Header>
      <Card.Content
        justifyContent="space-between"
        bgColor="var(--balance-bg)"
        borderColor="var(--balance-bg)"
      >
        <TokensContainer>
          <TokenBalance label="Hora Coin" value={hcoins} />
          <Divider color="var(--balance-divider)"/>
          <TokenBalance label="Hora Token" value={htokens} />
        </TokensContainer>

        <ButtonsContainer>
          {/* <Button variant='primary' onClick={() => {NotificationsManager.info('Info message')}}>Test</Button> */}
          <Button variant='primary' onClick={() => {openModal('deposit')}}>Deposit</Button>
          <Button variant='primary' onClick={() => {openModal('withdraw')}}>Withdraw</Button>
        </ButtonsContainer>
      </Card.Content>
    </Card.Container>
  )
}
