import React from 'react'
import tw, { styled } from 'twin.macro'
import { Spacer } from '../layouts/Layout'
import { IconPlus } from './Icons'

const Container = styled.div`
  ${tw`w-full h-[16rem] flex flex-col justify-between mt-11 p-5 rounded-2xl border-2 cursor-pointer`}
  border-color: var(--account-menu-pass-border);
  transition: all 0.2s ease-in-out;
  svg {
    margin: 0 auto;
  }
  &:hover {
    opacity: 0.7;
  }
`

const Row = styled.div`
  ${tw`flex justify-between`}
  color: var(--account-menu-pass-text);
`

const passCardClicked = function() {
  window.open('https://whitepaper.horaverse.xyz/hora-hub/horaverse-pass', '_blank')
}

// export function PassCard({ color, label, value }) {
export function PassCard() {
  return (
    <Container onClick={passCardClicked}>
      <Spacer h={2} />
      <IconPlus color={'var(--account-menu-pass-plus)'} />
      <Row>
        <span>Horaverse Pass</span>
        <span>-- / --</span>
      </Row>
    </Container>
  )
}
