import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { IconOpenLink } from './Icons'
import { shortenAddress } from '../libs/utils'

const Container = styled.div`
  ${tw`flex items-center h-9 mb-14`}
`

export const StepProgressLabel = styled.span`
  ${tw`block font-bold mb-8`}
  color: var(--modal-step-progress-label);
`

const Progress = styled.div`
 ${tw`flex justify-between h-full w-full`} 
`

const ProgressItem = styled.div`
  ${tw`w-[7px] rounded-full`}
  background-color: ${props => props.filled ? 'var(--modal-step-progress-filled)' : 'var(--modal-step-progress)'};
`

export function StepProgressBar({ progress, blocks, animated = true }) {
  const [progressVal, setProgressVal] = useState(progress)

  const unit = blocks / 100

  useEffect(() => {
    if (!animated) return

    // console.log('effect')
    if (progress > progressVal) {
      // console.log('setup')
      let interval = setInterval(() => {
        // console.log(progress, progressVal)
        if (progress > progressVal) {
          const v = Math.min(progressVal + 1, progress)
          // console.log('set', v)
          setProgressVal(v)
        } else {
          cleanup() // eslint-disable-line no-use-before-define
        }
      }, 10)

      const cleanup = () => {
        // console.log('cleanup')
        if (!interval) return
        clearInterval(interval)
        interval = null
      }
      return cleanup
    }
  })

  const fill = Math.floor(unit * (animated ? progressVal : progress))

  return (
    <Container>
      <Progress>
        {[...Array(blocks)].map((x, i) =>
          <ProgressItem key={i} filled={i < fill} />
        )}
      </Progress>
    </Container>
  )
}

const TxText = styled.span`
  ${tw`mr-5`}
  color: var(--modal-step-progress-tx);
`

const Link = styled.a`
  ${tw`flex`}
`

export function StepProgressTx({ tx, link }) {
  return (
    <Container>
      <Link href={link} target="_blank">
        <TxText>TXN Hash: {shortenAddress(tx)}</TxText>
        <IconOpenLink color="var(--modal-step-progress-tx)"/>
      </Link>
    </Container>
  )
}
