import { useState } from 'react'
import {
  getUserPrefs, setUserPrefs
} from '../services/HoraHub'

export function useStorage(type) {
  let [storageState, setStorageState] = useState({})
  const isBrowser = typeof window !== 'undefined'
  const storageType = `${type}Storage`

  let getItem = function(key) {
    // console.log('getItem', key, window[storageType].getItem(key))
    return isBrowser && window[storageType].getItem(key)
  }

  let setItem = function(key, value) {
    // console.log('setItem', key, value)
    setStorageState(Object.assign({}, storageState, Object.fromEntries([[key, value]])))
    return isBrowser && window[storageType].setItem(key, value)
  }

  let removeItem = function(key) {
    return isBrowser && window[storageType].removeItem(key)
  }

  return {
    setItem,
    removeItem,
    getItem,
  }
}

let userPrefsStorage = null

export function useStorageWithUserPrefs() {
  let { getItem, setItem, removeItem } = useStorage('local')

  if (userPrefsStorage) {
    return userPrefsStorage
  }

  const getItemFallback = getItem
  const setItemFallback = setItem
  const removeItemfallback = removeItem

  let prefsData = null

  const setPrefsData = (data) => {
    if (!data) {
      prefsData = null
    } else if (!prefsData) {
      prefsData = data
    } else {
      Object.assign(prefsData, data)
    }
  }

  const reload = function() {
    if (prefsData) return
    getUserPrefs().then(setPrefsData)
  }

  let updateTimer = null

  const update = function() {
    if (updateTimer) return
    updateTimer = setTimeout(() => {
      if (prefsData) {
        updateTimer = null
        setUserPrefs(prefsData).then(setPrefsData)
      }
    }, 5000)
  }

  getItem = function(key) {
    if (!prefsData) {
      return getItemFallback(key)
    }
    return prefsData[key]
  }

  setItem = function(key, value) {
    if (!prefsData) {
      return setItemFallback(key, value)
    }
    prefsData[key] = value
    update()
  }

  removeItem = function(key) {
    if (!prefsData) {
      return removeItemfallback(key)
    }
    delete prefsData[key]
    update()
  }

  userPrefsStorage = {
    setItem,
    getItem,
    removeItem,
    reload,
    update,
  }

  return userPrefsStorage
}
