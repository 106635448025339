import { createGlobalStyle } from 'styled-components'

const ThemesStyles = createGlobalStyle`
  // Default - dark
  @function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
  }
  
  .theme-color-set-1, :root {
    --color-bg-primary:           #030E17; // blue-charocal
    --color-bg-secondary:         #000000; // black
    
    --color-fg-primary:           #D9D9D9; // athens-grey
    --color-fg-secondary:         #7190A5; // weldon-blue
    --color-fg-tetriary:          #2A4354; // arapawa
    
    --color-accent:               #00E682; // medium-spring-green
    --color-alert:                #e94155; // paradise-pink
    
    --color-tron:                 #E24242; // paradise-pink
    --color-bsc:                  #E6C864; // golden-sand
    --color-polygon:              #8247E5; // lavender-indigo
    --color-ethereum:             #8A81C1; // cold-purple
    
    --color-txn-primary:          #F05082; // strawberry
    --color-txn-secondary:        #9632E6; // veronica
    --color-txn-tetriary:         #0082E6; // cool-blue
  }

  // Default - light
  .theme-color-set-1-light {
    --color-bg-primary:           #D9D9D9; // athens-grey
    --color-bg-secondary:         #7190A5; // weldon-blue

    --color-fg-primary:           #030E17; // blue-charocal
    --color-fg-secondary:         #000000; // black
    --color-fg-tetriary:          #000000; // black

    --color-accent:               #00E682; // medium-spring-green
    --color-alert:                #e94155; // paradise-pink

    --color-tron:                 #ff0000; // paradise-pink
    --color-bsc:                  #E6C864; // golden-sand
    --color-polygon:              #8247E5; // lavender-indigo
    --color-ethereum:             #8A81C1; // cold-purple

    --color-txn-primary:          #F05082; // strawberry
    --color-txn-secondary:        #9632E6; // veronica
    --color-txn-tetriary:         #0082E6; // cool-blue
  }
  
  // CIM (Legacy)
  .theme-color-set-2 {
    --color-bg-primary:           #064a80; // venice-blue
    --color-bg-secondary:         #1c3958; // regal-blue

    --color-fg-primary:           #e89b39; // tulip-tree
    --color-fg-secondary:         #4baefc; // blue-jeans
    --color-fg-tetriary:          #42729e; // queen-blue
    
    --color-accent:               #7beb8e; // light-green
    --color-alert:                #e94155; // paradise-pink
  }
  
  // Minimal
  .theme-color-set-3 {
    --color-bg-primary:           #0b0b16; // ebony
    --color-bg-secondary:         #0a0a14; // rich-black
    
    --color-fg-primary:           #b7b7ba; // french-grey
    --color-fg-secondary:         #9e9e9e; // dark-grey
    --color-fg-tetriary:          #334453; // cello
    
    --color-accent:               #98e8ae; // teal-deer
    --color-alert:                #e94155; // paradise-pink
  }

  // Default - Dark/Light
  .theme-color-map-1, :root {
    --header-bg:                  var(--color-bg-secondary);
    --header-text-logo:           var(--color-fg-secondary);
    --header-text-nav:            var(--color-fg-secondary);
    --header-text-nav-active:     var(--color-accent);
    --header-text-account:        var(--color-fg-secondary);

    --account-menu-bg:            var(--color-bg-primary);
    --account-menu-token-label:   var(--color-fg-tetriary);
    --account-menu-token-value:   var(--color-accent);
    --account-menu-pass-border:   var(--color-fg-tetriary);
    --account-menu-pass-plus:     var(--color-accent);
    --account-menu-pass-text:     var(--color-fg-tetriary);
    
    --sidebar-bg:                 var(--color-bg-primary);
    --sidebar-logo:               var(--color-fg-tetriary);
    --sidebar-text:               var(--color-fg-tetriary);
    --sidebar-text-active:        var(--color-fg-secondary);
    
    --main-bg:                    var(--color-bg-secondary);
    --main-text:                  var(--color-fg-secondary);
    --main-text-welcome:          var(--color-fg-primary);
    --main-sections-title:        var(--color-fg-secondary);
    --main-sections-buttons:      var(--color-fg-secondary);
    --main-dropdown-bg:           0,0,0;
    --main-dropdown-check-border: var(--color-fg-tetriary);
    --main-dropdown-checked:      var(--color-accent);
    
    --balance-bg:                 var(--color-bg-primary);
    --balance-border:             transparent;
    --balance-label:              var(--color-fg-secondary);
    --balance-value:              var(--color-accent);
    --balance-divider:            var(--color-fg-secondary);
    --balance-cta:                var(--color-fg-secondary);
    --balance-cta-active:         var(--color-accent);
    
    --networks-tron-bg:           var(--color-bg-secondary);
    --networks-tron-border:       var(--color-tron);
    --networks-tron-text:         var(--color-tron);

    --networks-bsc-bg:            var(--color-bg-secondary);
    --networks-bsc-border:        var(--color-bsc);
    --networks-bsc-text:          var(--color-bsc);

    --networks-polygon-bg:        var(--color-bg-secondary);
    --networks-polygon-border:    var(--color-polygon);
    --networks-polygon-text:      var(--color-polygon);

    --networks-ethereum-bg:       var(--color-bg-secondary);
    --networks-ethereum-border:   var(--color-ethereum);
    --networks-ethereum-text:     var(--color-ethereum);

    --networks-add-bg:            var(--color-bg-secondary);
    --networks-add-border:        var(--color-fg-secondary);
    --networks-add-text:          var(--color-fg-secondary);
    
    --chart-y-labels:             var(--color-fg-secondary);
    --chart-x-labels:             var(--color-fg-secondary);
    --chart-x-labels-active:      var(--color-accent);
    --chart-field:                var(--color-bg-primary);
    --chart-field-filled:         var(--color-fg-secondary);
    --chart-field-filled-active:  var(--color-accent);
    --chart-value-active:         var(--color-accent);
    
    --txn-icon-fg:                var(--color-bg-secondary);
    --txn-label:                  var(--color-fg-secondary);
    --txn-date:                   var(--color-fg-secondary);
    --txn-value:                  var(--color-fg-secondary);
    --txn-cta:                    var(--color-accent);
    /* --txn-hash:                   var(--color-bg-secondary); */
    --txn-status-pending:         var(--color-fg-secondary);
    --txn-status-completed:       var(--color-accent);
    --txn-status-failed:          var(--color-alert);
    --txn-deposit:                var(--color-txn-primary);
    --txn-withdraw:               var(--color-txn-secondary);
    --txn-transfer:               var(--color-txn-tetriary);

    --modal-bg:                   var(--color-bg-primary);
    --modal-border:               var(--color-fg-tetriary);
    --modal-title-primary:        var(--color-accent);
    --modal-title-secondary:      var(--color-fg-secondary);
    --modal-steps:                var(--color-fg-secondary);
    --modal-steps-active:         var(--color-accent);
    --modal-close:                var(--color-fg-secondary);
    --modal-form-label:           var(--color-fg-secondary);
    --modal-form-placeholder:     var(--color-accent);
    --modal-form-value:           var(--color-fg-secondary);
    --modal-form-value2:          var(--color-accent);
    --modal-form-arrow:           var(--color-fg-secondary);
    --modal-form-line:            var(--color-fg-secondary);
    --modal-form-input:           var(--color-bg-secondary);
    --modal-form-input-border:    var(--color-fg-tetriary);
    --modal-form-input-border-active:    var(--color-accent);
    --modal-form-input-text:      var(--color-fg-secondary);
    --modal-form-input-text-ph:   var(--color-fg-tetriary);
    --modal-form-input-text-accent: var(--color-accent);
    --modal-slider-label:         var(--color-fg-secondary);
    --modal-slider-slide:         var(--color-fg-tetriary);
    --modal-slider-slide-active:  var(--color-accent);
    --modal-label:                var(--color-fg-tetriary);
    --modal-label-accent:         var(--color-accent);
    --modal-text:                 var(--color-fg-secondary);
    --modal-text-darker:          var(--color-fg-tetriary);
    --modal-text-strong:          var(--color-accent);
    --modal-step-progress-label:  var(--color-accent);
    --modal-step-progress:        var(--color-fg-tetriary);
    --modal-step-progress-filled: var(--color-accent);
    --modal-step-progress-tx:     var(--color-fg-secondary);
  }

  // CIM (Legacy)
  .theme-color-map-2 {
    --header-bg:                  var(--color-bg-primary);
    --header-text-logo:           var(--color-fg-secondary);
    --header-text-nav:            var(--color-accent);
    --header-text-nav-active:     var(--color-accent);
    --header-text-account:        var(--color-fg-secondary);

    --sidebar-bg:                 var(--color-bg-secondary);
    --sidebar-logo:               var(--color-fg-tetriary);
    --sidebar-text:               var(--color-fg-tetriary);
    --sidebar-text-active:        var(--color-fg-secondary);

    --main-bg:                    var(--color-bg-primary);
    --main-text:                  var(--color-fg-secondary);
    --main-text-welcome:          var(--color-accent);
    --main-sections-title:        var(--color-fg-primary);
    --main-sections-buttons:      var(--color-fg-primary);

    --balance-bg:                 transparent;
    --balance-border:             transparent;
    --balance-label:              var(--color-fg-secondary);
    --balance-value:              var(--color-accent);
    --balance-divider:            var(--color-fg-primary);
    --balance-cta:                var(--color-fg-tetriary);
    --balance-cta-active:         var(--color-accent);

    --networks-tron-bg:           var(--color-bg-primary);
    --networks-tron-border:       var(--color-fg-secondary);
    --networks-tron-text:         var(--color-fg-secondary);

    --networks-bsc-bg:            var(--color-bg-primary);
    --networks-bsc-border:        var(--color-fg-secondary);
    --networks-bsc-text:          var(--color-fg-secondary);

    --networks-polygon-bg:        var(--color-bg-primary);
    --networks-polygon-border:    var(--color-fg-secondary);
    --networks-polygon-text:      var(--color-fg-secondary);

    --networks-ethereum-bg:       var(--color-bg-primary);
    --networks-ethereum-border:   var(--color-fg-secondary);
    --networks-ethereum-text:     var(--color-fg-secondary);

    --networks-add-bg:            var(--color-bg-primary);
    --networks-add-border:        var(--color-fg-tetriary);
    --networks-add-text:          var(--color-fg-tetriary);

    --chart-y-labels:             var(--color-fg-tetriary);
    --chart-x-labels:             var(--color-fg-tetriary);
    --chart-x-labels-active:      var(--color-accent);
    --chart-field:                var(--color-bg-secondary);
    --chart-field-filled:         var(--color-fg-secondary);
    --chart-field-filled-active:  var(--color-accent);
    --chart-value-active:         var(--color-accent);

    --txn-icon-fg:                var(--color-bg-primary);
    --txn-label:                  var(--color-fg-secondary);
    --txn-date:                   var(--color-fg-secondary);
    --txn-value:                  var(--color-accent);
    --txn-cta:                    var(--color-accent);
    --txn-hash:                   var(--color-bg-secondary);
    --txn-status-pending:         var(--color-fg-secondary);
    --txn-status-completed:       var(--color-accent);
    --txn-status-failed:          var(--color-alert);
    --txn-deposit:                var(--color-fg-secondary);
    --txn-withdraw:               var(--color-fg-secondary);
    --txn-transfer:               var(--color-fg-secondary);

    --modal-bg:                   var(--color-bg-primary);
    --modal-border:               var(--color-fg-tetriary);
    --modal-title-primary:        var(--color-accent);
    --modal-title-secondary:      var(--color-fg-secondary);
    --modal-steps:                var(--color-fg-secondary);
    --modal-steps-active:         var(--color-accent);
    --modal-close:                var(--color-fg-secondary);
    --modal-form-label:           var(--color-fg-secondary);
    --modal-form-placeholder:     var(--color-accent);
    --modal-form-value:           var(--color-fg-secondary);
    --modal-form-value2:          var(--color-accent);
    --modal-form-arrow:           var(--color-fg-secondary);
    --modal-form-line:            var(--color-fg-secondary);
    --modal-slider-label:         var(--color-fg-secondary);
    --modal-slider-slide:         var(--color-fg-tetriary);
    --modal-slider-slide-active:  var(--color-accent);
    --modal-label:                var(--color-fg-tetriary);
    --modal-label-accent:         var(--color-accent);
    --modal-text:                 var(--color-fg-secondary);
    --modal-text-darker:          var(--color-fg-tetriary);
    --modal-text-strong:          var(--color-accent);
    --modal-step-progress-label:  var(--color-accent);
    --modal-step-progress:        var(--color-fg-tetriary);
    --modal-step-progress-filled: var(--color-accent);
    --modal-step-progress-tx:     var(--color-fg-secondary);
  }
  
  // Minimal
  .theme-color-map-3 {
    --header-bg:                  var(--color-bg-primary);
    --header-text-logo:           var(--color-fg-secondary);
    --header-text-nav:            var(--color-fg-tetriary);
    --header-text-nav-active:     var(--color-fg-tetriary);
    --header-text-account:        var(--color-fg-tetriary);

    --sidebar-bg:                 var(--color-bg-primary);
    --sidebar-logo:               var(--color-fg-secondary);
    --sidebar-text:               var(--color-fg-tetriary);
    --sidebar-text-active:        var(--color-fg-secondary);

    --main-bg:                    var(--color-bg-primary);
    --main-text:                  var(--color-fg-secondary);
    --main-text-welcome:          var(--color-fg-primary);
    --main-sections-title:        var(--color-accent);
    --main-sections-buttons:      var(--color-fg-tetriary);

    --balance-bg:                 transparent;
    --balance-border:             transparent;
    --balance-label:              var(--color-fg-tetriary);
    --balance-value:              var(--color-accent);
    --balance-divider:            var(--color-fg-tetriary);
    --balance-cta:                var(--color-fg-secondary);
    --balance-cta-active:         var(--color-accent);

    --networks-tron-bg:           var(--color-bg-secondary);
    --networks-tron-border:       var(--color-fg-tetriary);
    --networks-tron-text:         var(--color-fg-primary);

    --networks-bsc-bg:            var(--color-bg-secondary);
    --networks-bsc-border:        var(--color-fg-tetriary);
    --networks-bsc-text:          var(--color-fg-primary);

    --networks-polygon-bg:        var(--color-bg-secondary);
    --networks-polygon-border:    var(--color-fg-tetriary);
    --networks-polygon-text:      var(--color-fg-primary);

    --networks-ethereum-bg:       var(--color-bg-secondary);
    --networks-ethereum-border:   var(--color-fg-tetriary);
    --networks-ethereum-text:     var(--color-fg-primary);

    --networks-add-bg:            transparent;
    --networks-add-border:        var(--color-fg-tetriary);
    --networks-add-text:          var(--color-fg-tetriary);

    --chart-y-labels:             var(--color-fg-tetriary);
    --chart-x-labels:             var(--color-fg-tetriary);
    --chart-x-labels-active:      var(--color-accent);
    --chart-field:                transparent;
    --chart-field-filled:         var(--color-fg-tetriary);
    --chart-field-filled-active:  var(--color-accent);
    --chart-value-active:         var(--color-accent);

    --txn-icon-fg:                var(--color-bg-primary);
    --txn-label:                  var(--color-fg-tetriary);
    --txn-date:                   var(--color-fg-tetriary);
    --txn-value:                  var(--color-accent);
    --txn-cta:                    var(--color-accent);
    --txn-hash:                   var(--color-fg-tetriary);
    --txn-status-pending:         var(--color-fg-tetriary);
    --txn-status-completed:       var(--color-accent);
    --txn-status-failed:          var(--color-alert);
    --txn-deposit:                var(--color-fg-tetriary);
    --txn-withdraw:               var(--color-fg-tetriary);
    --txn-transfer:               var(--color-fg-tetriary);

    --modal-bg:                   var(--color-bg-primary);
    --modal-border:               var(--color-fg-tetriary);
    --modal-title-primary:        var(--color-accent);
    --modal-title-secondary:      var(--color-fg-secondary);
    --modal-steps:                var(--color-fg-secondary);
    --modal-steps-active:         var(--color-accent);
    --modal-close:                var(--color-fg-secondary);
    --modal-form-label:           var(--color-fg-secondary);
    --modal-form-placeholder:     var(--color-accent);
    --modal-form-value:           var(--color-fg-secondary);
    --modal-form-value2:          var(--color-accent);
    --modal-form-arrow:           var(--color-fg-secondary);
    --modal-form-line:            var(--color-fg-secondary);
    --modal-slider-label:         var(--color-fg-secondary);
    --modal-slider-slide:         var(--color-fg-tetriary);
    --modal-slider-slide-active:  var(--color-accent);
    --modal-label:                var(--color-fg-tetriary);
    --modal-label-accent:         var(--color-accent);
    --modal-text:                 var(--color-fg-secondary);
    --modal-text-darker:          var(--color-fg-tetriary);
    --modal-text-strong:          var(--color-accent);
    --modal-step-progress-label:  var(--color-accent);
    --modal-step-progress:        var(--color-fg-tetriary);
    --modal-step-progress-filled: var(--color-accent);
    --modal-step-progress-tx:     var(--color-fg-secondary);
  }
`

export const themes = {
  auto: {
    name: 'Auto Light/Dark',
    default: true,
  },
  light: {
    name: 'Default (Light)',
    mode: 'light',
    classList: 'theme-color-set-1-light theme-color-map-1',
    default: true,
  },
  light2: {
    name: 'Test (Light)',
    mode: 'light',
    classList: 'theme-color-set-1-light theme-color-map-2',
  },
  dark: {
    name: 'Default (Dark)',
    mode: 'dark',
    classList: 'theme-color-set-1 theme-color-map-1',
    default: true,
  },
  cim: {
    name: 'CIM (Legacy)',
    mode: 'dark',
    classList: 'theme-color-set-2 theme-color-map-2',
  },
  minimal: {
    name: 'Minimal',
    mode: 'dark',
    classList: 'theme-color-set-3 theme-color-map-3',
  },
}

export default ThemesStyles
