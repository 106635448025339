const APP_NAME = 'HoraHub'
const APP_VERSION = process.env.APP_VERSION // eslint-disable-line
const APP_REVISION = process.env.APP_REVISION // eslint-disable-line
const DEBUG_MODE = Boolean(import.meta.env.VITE_DEBUG)
const API_URL = import.meta.env.VITE_API_URL || ''

const config = {
  apiUrl: API_URL,

  debugMode: DEBUG_MODE,
  appName: APP_NAME,
  appVersion: APP_VERSION,
  appRevision: APP_REVISION,

  useTransferWithPermit: true,

  horaHubPrefsNamespace: APP_NAME.toLowerCase(),

  routesWl: '/ /marketplace /launchpad /quests /swap',
  configRefreshInterval: 60 * 1000,
  hideAccountEmail: false,
}

const searchParams = sessionStorage.getItem('searchParams')
if (searchParams) {
  config.searchParams = JSON.parse(searchParams)
  sessionStorage.removeItem('searchParams')
}

let lastEtag

export async function loadConfig(key, extend = false) {
  const path = `/api/config/${key}${config.avQs}`
  const networkConfigUrl = API_URL ? new URL(path, API_URL).toString() : path
  const response = await fetch(networkConfigUrl)
  if (response.ok) {
    const remoteConfig = await response.json()
    if (extend) {
      Object.assign(config, remoteConfig)
    } else {
      config[key] = remoteConfig[key]
    }

    const etag = response.headers.get('etag')
    if (lastEtag && lastEtag === etag) {
      return null
    }
    lastEtag = etag

    console.log(`LoadConfig ${key}`, config, response)
    return config
  }

  throw new Error(`loadConfig = ${response.status} ${response.statusText}`)
}

console.log('CONFIG', config)

export default config
