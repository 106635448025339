import React from 'react'
import { PopupHeader as Header } from './Container'
import tw, { styled, css } from 'twin.macro'

const StepsContainer = styled.div`
  ${tw`absolute left-1/2 -translate-x-1/2`}
`

export const HeaderStepsItem = styled.span(({ active }) => [
  tw`font-medium text-xl cursor-pointer`,
  css`
    color: var(--modal-steps);
    + span {
      ${tw`ml-3`}
    }
  `,
  active && css`color: var(--modal-steps-active);`
])

export const HeaderTitle = styled.div(({ bold, colorSecondary }) => [
  tw`text-xl`,
  colorSecondary ? css`color: var(--modal-title-secondary);` : css`color: var(--modal-title-primary);`,
  bold ? tw`font-bold` : tw`font-medium`
])

export const HeaderSteps = ({ active, count }) => (
  <StepsContainer>
    {Array
      .apply(null, Array(count))
      .map((_, i) => i + 1)
      .map(n => <HeaderStepsItem key={n} active={n === active}>{n}</HeaderStepsItem>)}
  </StepsContainer>
)

export const Container = styled.div`
  ${tw`flex flex-col h-[calc(100vh - 100px)] max-h-[690px] overflow-y-scroll`}
  box-sizing: border-box;
`

export const Content = styled.div`
  ${tw`flex-1 flex flex-col justify-between`}
`

export const ContentSection = styled.div(({ center }) => [
  center && tw`text-center flex flex-col items-center`,
])

export const Text = styled.p(({ mode, center, color, textSize, paddingY, mt }) => [
  tw`font-medium`,
  'color: var(--modal-text);',
  'b {color: var(--color-accent); font-weight: 600;}',
  'a {color: var(--color-accent); font-weight: 600; :hover {text-decoration: underline;}}',
  center && tw`text-center`,
  mode === 'error' ? 'color: red;' : '',
  color === 'darker' && [
    'color: var(--modal-text-darker);',
  ],
  textSize === 'sm' && [
    tw` text-sm`,
  ],
  textSize === 'lg' && [
    tw` text-lg`,
  ],
  paddingY === 'sm' && [
    tw`py-3.5`,
  ],
  mt === 'lg' && [
    tw`mt-[1.125rem]`,
  ],
  mt === 'xl' && [
    tw`mt-5`,
  ],
  mt === '2xl' && [
    tw`mt-6`,
  ],
  'strong { color: var(--modal-text-strong); font-weight: 400;}',
  '+ p { margin-top: 1rem; }',
])

const Modal = {
  Header,
  HeaderTitle,
  HeaderSteps,
  Container,
  Content,
  ContentSection,
  Text,
}

export default Modal
