import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Follow, Tweet } from 'react-twitter-widgets'
import ConnectNetwork from './ConnectNetwork'
import ConnectTwitter from '../components/ConnectTwitter'
import { Item, Task } from '../ui/Launchpad'


const LinkToTweet = ({ twId }) => {
  const options = {
    cards: 'hidden',
    conversation: 'none',
    theme: 'dark',
    width: 500,
    align: 'left',
  }
  return <Tweet options={options} tweetId={twId} />
}

// eslint-disable-next-line max-statements,complexity
export const TaskItem = ({ item, task, itemState }) => {
  let icon, title, subTitle, button, error, done = false
  const taskState = itemState && itemState.tasks && itemState.tasks.find(t => t.id === task.id)
  if (taskState) {
    error = taskState.error
    done = taskState.done
  }
  // console.log(taskState)

  switch (task.id) {
  case 'connect-network':
    button = <ConnectNetwork network={item.network} />
    icon = <Task.IconWallet />
    if (!done) {
      title = 'Connect your wallet'
      subTitle = 'Connect wallet to proceed...'
    } else {
      title = 'Wallet is connected'
      subTitle = 'done'
    }

    break
  case 'twitter-login':
    button = <ConnectTwitter />
    icon = <Task.IconTwitter />
    if (!done) {
      title = 'Connect twitter'
      subTitle = 'subtitle'
    } else {
      title = 'Twitter is connected'
      subTitle = 'done...'
    }
    break
  case 'twitter-follow':
    button = task.twUserNames.map(username => <Follow key={username} username={username} options={{ size: 'large' }} />)
    subTitle = ''
    icon = <Task.IconTwitter />
    if (!done) {
      title = 'Follow ...'
    } else {
      title = 'Following done'
    }
    break
  case 'twitter-like':
    button = <LinkToTweet twId={task.twId} />
    subTitle = ''
    icon = <Task.IconTwitter />
    if (!done) {
      title = 'Like ...'
    } else {
      title = 'Like done'
    }
    break
  case 'twitter-retweet':
    button = <LinkToTweet twId={task.twId} />
    subTitle = ''
    icon = <Task.IconTwitter />
    if (!done) {
      title = 'Retweet ...'
    } else {
      title = 'Retweet done'
    }
    break
  case 'twitter-reply':
    button = <LinkToTweet twId={task.twId} />
    subTitle = ''
    icon = <Task.IconTwitter />
    if (!done) {
      title = 'Reply ...'
    } else {
      title = 'Reply done'
    }
    break
  case 'discord-code-verify':
    button = null
    subTitle = 'subtitle'
    if (!done) {
      title = 'Connect discord...'
    } else {
      title = 'Discord is connected'
    }
    icon = <Task.IconTwitter />
    if (taskState && taskState.code) {
      title = `Enter this code ${taskState.code} into discord channel...`
    }
    break
  case 'nft-presale':
    button = null
    subTitle = 'lorem ipsum'
    if (!done) {
      title = 'You\'re NOT whitelisted'
    } else {
      title = 'Whitelisted'
    }
    icon = <Task.IconWallet />
    break
  case 'hora-pay':
    button = null
    subTitle = `Have ${task.price} ${task.currency} on your balance`
    if (!done) {
      title = 'Not enough balance, <deposit button here>'
    } else {
      title = 'Balance ok'
    }
    icon = <Task.IconWallet />
    break
  default:
    return null
  }

  return <Task.Container>
    <Task.Title>{title}</Task.Title>
    <Task.SubTitle>{subTitle}</Task.SubTitle>
    {error ? <Task.Error>{error}</Task.Error> : null}
    {icon}
    {button}
  </Task.Container>
}

export const EnterButton = ({ state, path }) => {
  const navigate = useNavigate()

  const enter = () => {
    navigate(path)
  }

  let enterText

  switch (state) {
  case 'registration':
    enterText = 'Register'
    break
  case 'ended':
    enterText = 'Learn More'
    break
  case 'selection':
    enterText = 'Claim'
    break
  default:
    return null
  }


  return <Item.EnterButton onClick={enter}>{enterText}</Item.EnterButton>
}

export const StatusText = ({ state }) => {
  let statusText

  switch (state) {
  case 'registration':
    statusText = 'Registration Open'
    break
  case 'ended':
    statusText = 'Ended'
    break
  case 'selection':
    statusText = 'Selection Status'
    break
  default:
    return null
  }

  return <Item.StatusText>{statusText}</Item.StatusText>
}
