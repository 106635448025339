import React from 'react'
import { Outlet } from 'react-router-dom'

import { LayoutDashboard, LayoutSidebar, LayoutMain } from './Layout'
import Sidebar from '../components/Sidebar'
import { Header } from '../components/Header'

export function Dashboard() {
  return (
    <LayoutDashboard>
      <LayoutSidebar>
        <Sidebar />
      </LayoutSidebar>
      <LayoutMain>
        <Header />
        <Outlet />
      </LayoutMain>
    </LayoutDashboard>
  )
}
