import React from 'react'
import tw, { styled } from 'twin.macro'

const Container = styled.div`
  ${tw`relative pb-2 mb-6`}
  opacity: ${props => props.disabled && '0.4'};
`


const Label = styled.span`
  ${tw`block font-medium mb-1`}
  color: var(--modal-label);
`

const MenuContainer = styled.ul`
  ${tw`max-h-32 overflow-y-auto`}
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #224456;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #6891a7;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #00ea77;
  }
`

const ItemContainer = styled.div`
  ${tw`flex items-center py-1`}
  cursor: ${props => props.onClick && !props.disabled && 'pointer'};
`

const Value = styled.span`
  ${tw`text-2xl`}
  color: ${props => props.selected ? 'var(--modal-form-value2)' : 'var(--modal-form-value)'};
`

const List = ({ children, bgColor, borderColor }) => (
  <MenuContainer style={{ backgroundColor: bgColor, borderColor: borderColor }}>
    {children}
  </MenuContainer>
)

const ListItem = ({ onClick, option, selected }) => (
  <ItemContainer onClick={onClick} disabled={option?.disabled}>
    <Value selected={selected}>{option?.value}</Value>
  </ItemContainer>
)

const ListScrollable = {
  Container,
  Label,
  List,
  ListItem,
}

export default ListScrollable
