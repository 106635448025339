import Web3 from 'web3/dist/web3.min.js'
import { initializeWeb3Modal } from '../libs/web3Utils.js'
import { AllContracts } from '../libs/web3Contracts'
import config from '../config.js'

let queryClient
export function setQueryClient(qc) {
  queryClient = qc
}

class Web3NetworkService {
  constructor(network) {
    this.network = network
    this.config = config.network[network]
    this.connected = false
    this.address = ''
    this.chainId = 0
    this.provider = null
    this.web3 = null
    this.listenerSetup = false
    this.getProvider()
    this.initContracts()
  }

  log() {
    console.log(this.network, ...arguments)
  }

  logError(e) {
    console.error(this.network, e)
  }

  getProvider() {
    const { providerName } = this.config
    if (providerName) {
      this.provider = window[providerName]
    }
  }

  get networkName() {
    return this.config.title || this.config.name
  }

  async getAccountData() {
    this.chainId = await this.web3.eth.getChainId()
    const accounts = await this.web3.eth.getAccounts()
    this.address = this.provider.selectedAddress || accounts[0]
  }

  async connect() {
    if (!this.provider && this.config.web3Modal) {
      if (!this.web3Modal) {
        this.web3Modal = initializeWeb3Modal(Object.assign({}, this.config, { network: this.network }))
      }
      this.provider = await this.web3Modal.connect()
    } else {
      this.getProvider()
    }
    if (!this.provider) throw new Error('provider not initialized')
    if (!this.web3) {
      this.web3 = new Web3(this.provider)
    }
    if (await this.checkNetwork() === -2) {
      await this.addNetwork()
    }
    const ns = await this.checkNetwork()
    if (ns === -2) {
      throw new Error('Wrong network!')
    } else if (ns === -1) {
      throw new Error('Invalid address!')
    } else if (ns !== 1) {
      throw new Error('Wallet not connected!')
    }
    await this.AllContracts.initializeTokens()
    return this
  }

  listenEvents() {
    if (this.listenerSetup) return
    if (!this.provider.on) {
      this.log('unsupported event listener')
      return
    }
    this.provider.on('accountsChanged', () => {
      this.checkNetwork().catch(this.logError)
    })

    // this.provider.on('networkChanged', (networkId) => {
    //   this.checkNetwork().catch(this.logError)
    // })

    this.provider.on('chainChanged', () => {
      this.checkNetwork().catch(this.logError)
    })

    this.provider.on('disconnect', () => {
      this.checkNetwork().catch(this.logError)
    })
    this.listenerSetup = true
  }

  async checkNetwork() {
    this.log('checkNetwork')
    await this.getAccountData()

    if (!this.address) {
      this.setConnected()
      return -1
    }
    if (this.chainId !== this.config.chainId) {
      this.setConnected(false, '', 0, 'Please change the network in your wallet')
      return -2
    }
    this.setConnected(true, this.address, this.chainId, '')
    return 1
  }

  setConnected(connected = false, address = '', chainId = 0, connectMessage = '') {
    this.log('setConnected', JSON.stringify(arguments))
    this.address = address
    this.chainId = chainId
    this.connectMessage = connectMessage
    this.connected = connected
    if (connected) {
      this.listenEvents()
      // this.initTokens()
      // this.updateAccountBalance()
    }
    this.modified()
  }

  modified() {
    queryClient.invalidateQueries(this.network)
  }

  addNetwork() {
    const { chainId, name: chainName, nativeCurrency, rpc: rpcUrls, explorers } = this.config
    const blockExplorerUrls = [explorers[0].url]
    const chainIdHex = `0x${Number(chainId).toString(16)}`
    if (chainId === 3 || chainId === 1) {
      return this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId: chainIdHex,
        }],
      })
    }
    return this.provider.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: chainIdHex,
        chainName,
        nativeCurrency,
        rpcUrls,
        blockExplorerUrls,
      }],
    })
  }

  personalSign(message) {
    return this.web3.eth.personal.sign(message, this.address)
  }

  getWithdrawParams(options = {}) {
    if (this.connected) {
      const { address } = this
      for (const [method, data] of Object.entries(this.config.withdraw || {})) {
        if (data.free) {
          if (options.free) return { method, data, address }
        } else if (!options.free) {return { method, data, address }}
      }
    }
    return {}
  }

  initContracts() {
    this.log('initializing AllContracts')
    AllContracts.initialize(this)
    this.log('initializing AllContracts done')
  }

  getTransactionHash(tx, onReceipt) {
    return new Promise((resolve, reject) => {
      let receiptReceived = false
      /* const handler = */
      tx.once('receipt', (r) => {
        if (!receiptReceived && onReceipt) {
          receiptReceived = true
          onReceipt(r)
        }
      })
      tx.once('confirmation', (c, r) => {
        if (!receiptReceived && onReceipt) {
          receiptReceived = true
          onReceipt(r)
        }
      })
      tx.once('transactionHash', hash => {
        resolve(hash)
      })
      tx.on('error', e => reject(e.message || e))
    })
  }

  generateBlockExplorerLink({ txHash }) {
    let baseUrl = this.config?.explorers?.[0]?.url
    if (baseUrl) {
      if (txHash) return `${baseUrl}/tx/${txHash}`
    }
    return ''
  }
}

const connectMessageTexts = {
  pleaseLogin: 'Please login to your TronLink Wallet!',
  installTronLink:
    'Install TronLink https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec',
}

// eslint-disable-next-line camelcase
class Web3NetworkService_TRON extends Web3NetworkService {

  async connect() {
    this.getProvider()
    if (!this.provider) {
      this.setConnected(false, '', 0, connectMessageTexts.installTronLink)
      throw new Error(this.connectMessage)
    }
    if (this.provider?.defaultAddress?.base58) {
      this.setConnected(true, this.provider.defaultAddress.base58, 0)
    } else {
      this.setConnected(false, '', 0, connectMessageTexts.pleaseLogin)
      throw new Error(this.connectMessage)
    }
    await this.AllContracts.initializeTokens()
    return this
  }


  listenEvents() {
    if (this.listenerSetup) return
    window.addEventListener('message', res => {
      console.log(res.data.message)
      if (!res?.data?.message?.action) return
      let newAddress = this.address
      switch (res.data.message.action) {
      case 'setAccount':
        newAddress = res.data.message.data.address
        this.setConnected(!!newAddress, newAddress, this.chainId)
        break
      case 'setNode':
        if (!res?.data?.message?.data?.node?.chain) return
        if (res.data.message.data.node.chain === '_') {
          this.setConnected(!!newAddress, newAddress, 0)
        } else {
          this.setConnected(!!newAddress, newAddress, 1)
        }
        break
      case 'tabReply':
        if (!res?.data?.message?.data?.data?.node?.chain) return
        if (res.data.message.data.data.node.chain === '_') {
          this.setConnected(!!newAddress, newAddress, 0)
        } else {
          this.setConnected(!!newAddress, newAddress, 1)
        }
        break
      }
    })
    this.listenerSetup = true
  }

  personalSign(message) {
    const hexStrWithout0x = this.provider.toHex(message).replace(/^0x/, '')
    const byteArray = this.provider.utils.code.hexStr2byteArray(hexStrWithout0x)
    const strHash = this.provider.sha3(byteArray).replace(/^0x/, '')
    // log(`strHash=${strHash}`)
    return this.provider.trx.sign(strHash)
  }

  generateBlockExplorerLink({ txHash }) {
    let baseUrl = this.config?.explorers?.[0]?.url
    if (baseUrl) {
      if (txHash) return `${baseUrl}/#/transaction/${txHash}`
    }
    return ''
  }
}


function createWeb3NetworkService(network) {
  switch (network) {
  case 'tron':
    return new Web3NetworkService_TRON(network)
    // case 'bsc':
    //   return new Web3NetworkService_BSC(network)
  default:
    return new Web3NetworkService(network)
  }
}

const exp = {
  networks: {},
}

export function initWeb3Networks() {
  exp.networks = Object.fromEntries(Object.keys(config.network).map(key => [key, createWeb3NetworkService(key)]))
}

export default exp
