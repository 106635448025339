import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { styled } from 'twin.macro'
import { useNotifications } from '../hooks/useNotifications'

const Notification = ({
  type = 'info',
  title = null,
  message = null,
  timeOut = 5000,
  onClick = () => {}, // eslint-disable-line no-empty-function
  onRequestHide = () => {}, // eslint-disable-line no-empty-function
}) => {

  const requestHide = () => {
    if (onRequestHide) {
      onRequestHide()
    }
  }

  useEffect(() => {
    if (timeOut !== 0) {
      let timer = setTimeout(requestHide, timeOut)
      return () => {
        clearTimeout(timer)
      }
    }
  })

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    requestHide()
  }

  const className = classnames(['notification', `notification-${type}`])
  title = title ? (<h4 className="title">{title}</h4>) : null

  return (
    <div className={className} onClick={handleClick}>
      <div className="notification-message" role="alert">
        {title}
        <div className="message">{message}</div>
      </div>
    </div>
  )
}


// const Notifications = ({ notifications, onRequestHide, enterTimeout = 400, leaveTimeout = 400 }) => {
const Notifications = ({ notifications, onRequestHide }) => {
  const handleRequestHide = (notification) => () => {
    if (onRequestHide) {
      onRequestHide(notification)
    }
  }

  const className = classnames('notification-container', {
    'notification-container-empty': notifications.length === 0,
  })

  return (
    <div className={className}>
      {notifications.map((notification) => {
        const key = notification.id || new Date().getTime()
        return (
          <Notification
            type={notification.type}
            key={key}
            title={notification.title}
            message={notification.message}
            timeOut={notification.timeOut}
            onClick={notification.onClick}
            onRequestHide={handleRequestHide(notification)}
          />
        )
      })}
    </div>
  )
}

export const StyledNotificationsContainer = styled.div`
  .notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;

    .notification {
      box-sizing: border-box;
      padding: 15px;
      border-radius: 2px;
      color: #fff;
      background-color: #ccc;
      box-shadow: 0 0 12px #999;
      cursor: pointer;
      font-size: 1em;
      line-height: 1.2em;
      position: relative;
      opacity: 0.9;
      margin-top: 15px;

      .title {
        font-size: 1em;
        line-height: 1.2em;
      }
      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
`

export const NotificationsContainer = ({ enterTimeout = 400, leaveTimeout = 400 }) => {
  const [notifications, setNotifications] = useState([])
  const { NotificationsManager } = useNotifications()

  const handleStoreChange = (n) => {
    setNotifications(n)
  }

  useEffect(() => {
    NotificationsManager.addChangeListener(handleStoreChange)
    return () => {
      NotificationsManager.removeChangeListener(handleStoreChange)
    }
  })

  const handleRequestHide = (notification) => {
    NotificationsManager.remove(notification)
  }

  return (
    <Notifications
      enterTimeout={enterTimeout}
      leaveTimeout={leaveTimeout}
      notifications={notifications}
      onRequestHide={handleRequestHide}
    />
  )
}
