import React, { createContext, useState } from 'react'

export const UiContext = createContext(null)

export const UiContextProvider = ({ children }) => {
  const [toast, setToast] = useState(null)
  const [modal, setModal] = useState(null)

  const openModal = (modalType, params = {}) => {
    setModal({
      type: modalType,
      params,
    })
  }

  const closeModal = () => (setModal(null))

  return (
    <UiContext.Provider
      value={{
        toast,
        setToast,
        modal,
        setModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </UiContext.Provider>
  )
}
