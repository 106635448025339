import { EventEmitter } from 'events'
import { createUUID } from '../libs/utils'
import { AlertTypes } from '../libs/constants'

class NotificationsManagerClass extends EventEmitter {
  constructor() {
    super()
    this.listNotify = []
  }

  create(notify) {
    const defaultNotify = {
      id: createUUID(),
      type: 'info',
      title: null,
      message: null,
      timeOut: 5000,
    }
    if (notify.priority) {
      this.listNotify.unshift(Object.assign(defaultNotify, notify))
    } else {
      this.listNotify.push(Object.assign(defaultNotify, notify))
    }
    this.emitChange()
  }

  info(message, title, timeOut, onClick, priority) {
    this.create({
      type: AlertTypes.INFO,
      message,
      title,
      timeOut,
      onClick,
      priority,
    })
  }

  success(message, title, timeOut, onClick, priority) {
    this.create({
      type: AlertTypes.SUCCESS,
      message,
      title,
      timeOut,
      onClick,
      priority,
    })
  }

  warning(message, title, timeOut, onClick, priority) {
    this.create({
      type: AlertTypes.WARNING,
      message,
      title,
      timeOut,
      onClick,
      priority,
    })
  }

  error(message, title, timeOut, onClick, priority) {
    this.create({
      type: AlertTypes.ERROR,
      message,
      title,
      timeOut,
      onClick,
      priority,
    })
  }

  remove(notification) {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id)
    this.emitChange()
  }

  removeAll() {
    this.listNotify.length = 0
    this.emitChange()
  }

  emitChange() {
    this.emit(AlertTypes.CHANGE, this.listNotify)
  }

  addChangeListener(callback) {
    this.addListener(AlertTypes.CHANGE, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(AlertTypes.CHANGE, callback)
  }
}

const NotificationsManager = new NotificationsManagerClass()

export const useNotifications = () => (
  {
    NotificationsManager,
  }
)
