import React, { useContext, useState } from 'react'
import Card from '../ui/Card'
import { Divider } from '../ui/Divider'
import { TokenBalance } from '../ui/TokenBalance'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { useGetUserModel } from '../hooks/useHoraHub'
import { currencies } from '../libs/constants'
import { shortenAddress } from '../libs/utils'
import { NetworkLogo, IconConnected, IconArrowDropdown, IconPlus } from '../ui/Icons'
import { useStorage } from '../hooks/useStorage'
import { useStackLayoutEngine, LayoutEngineTypes } from '../hooks/useStackLayoutEngine'
import useCardCollapse from '../hooks/useCardCollapse'
import { UiContext } from '../contexts/UiContext'
import { ModalTypes } from '../modals/AppModals'
import tw, { css, styled } from 'twin.macro'

const HeaderSwitchContainer = styled.div`
  ${tw`flex items-center`}
`

const HeaderSwitchText = styled.span(({ color }) => [
  tw`inline-block font-semibold text-xl mx-1`,
  css`color: ${color ? color : 'var(--main-sections-title)'}`
])

const HeaderSwitchArrow = styled.button`
  ${tw`cursor-pointer rotate-90 p-2`}
  ${(props) => (props.isRight ? tw`-rotate-90` : '')}
  ${(props) => (props.disabled ? tw`opacity-50` : '')}
`

const ADD_NETWORK_CARD_ID = 'add'

function HeaderSwitch({ networks, selectNetwork, selectedNetwork }) {
  if (!selectedNetwork) return null
  const selectedNetworkIndex = networks.indexOf(selectedNetwork)
  const prevNetwork = networks[selectedNetworkIndex - 1]
  let nextNetwork = networks[selectedNetworkIndex + 1]
  if (nextNetwork && nextNetwork.networkId === ADD_NETWORK_CARD_ID) {
    nextNetwork = null
  }
  const { networkName, networkId } = selectedNetwork
  const color = `var(--networks-${networkId}-text)`
  const prev = prevNetwork ? `var(--networks-${prevNetwork.networkId}-text)` : 'var(--main-sections-title)'
  const next = nextNetwork ? `var(--networks-${nextNetwork.networkId}-text)` : 'var(--main-sections-title)'


  return (
    <HeaderSwitchContainer>
      <HeaderSwitchArrow onClick={() => selectNetwork(prevNetwork)} disabled={!prevNetwork}>
        <IconArrowDropdown color={prev}/>
      </HeaderSwitchArrow>
      <HeaderSwitchText color={color}>
        {networkName}
      </HeaderSwitchText>
      <HeaderSwitchArrow isRight onClick={() => selectNetwork(nextNetwork)} disabled={!nextNetwork}>
        <IconArrowDropdown color={next}/>
      </HeaderSwitchArrow>
    </HeaderSwitchContainer>
  )
}

const CardItem = ({ networkId, walletAddress, hcoins, htokens, connected, onClick, className }) => {
  let bgColor = `var(--networks-${networkId}-bg)`
  let borderColor = `var(--networks-${networkId}-border)`
  let textColor = `var(--networks-${networkId}-text)`

  if (networkId === ADD_NETWORK_CARD_ID) {
    return (
      <Card.Content
        isNetworkCard
        className={className}
        bgColor={bgColor}
        borderColor={borderColor}
        justifyContent="space-between"
        onClick={onClick}
      >
        <Card.Section justifyContent="center" alignItems="center" flex="1">
          <IconPlus color={borderColor}/>
        </Card.Section>
      </Card.Content>
    )
  }

  return (
    <Card.Content
      isNetworkCard
      className={className}
      bgColor={bgColor}
      borderColor={borderColor}
      justifyContent="space-between"
      onClick={onClick}
    >
      <Card.Section justifyContent="space-between" alignItems="self-start">
        <NetworkLogo networkId={networkId} />
        <Card.Section alignItems="center">
          <Card.NetworkAddress color={textColor}>
            {shortenAddress(walletAddress)}
          </Card.NetworkAddress>
          <Card.Status>
            {connected ? <IconConnected color="var(--color-accent)"/> : null}
          </Card.Status>
        </Card.Section>
      </Card.Section>
      <Card.Section flexDirection="column">
        <TokenBalance colorLabel={textColor} colorValue={textColor} label="Hora Coin" value={hcoins} />
        <Divider color={borderColor} />
        <TokenBalance colorLabel={textColor} colorValue={textColor} label="Hora Token" value={htokens} />
      </Card.Section>
    </Card.Content>
  )
}

const saveNetworkKey = 'selected-network'

// eslint-disable-next-line no-unused-vars
function addDummyNetworks(networks, addCount, removeCount) {
  let index = 1
  const toClone = networks[0]
  if (!toClone) return
  while (removeCount > 0) {
    networks.pop()
    --removeCount
  }
  let clone = () => Object.assign(
    {},
    toClone,
    {
      networkId: `${toClone.networkId} - ${String(index)}`,
      networkName: `${toClone.networkName} - ${String(index++)}`,
    }
  )
  while (addCount > 0) {
    networks.push(clone())
    --addCount
  }
}

export function NetworksCard() {
  const { collapsed, collapseToggle } = useCardCollapse('networks')
  const { getClassName } = useStackLayoutEngine(LayoutEngineTypes.SLIDE)
  const { eachNetwork } = useWeb3Service()
  const user = useGetUserModel()
  const { openModal } = useContext(UiContext)

  const mapNetworkService = (networkService) => {
    const networkId = networkService.network
    const networkName = networkService.networkName
    const walletAddress = user.getWalletAddress(networkService.network)
    const htokens = user.getTokenAmount(currencies.CURRENCY_HORA_TOKEN, { network: networkId, format: true })
    const hcoins = user.getTokenAmount(currencies.CURRENCY_HORA_COIN, { network: networkId, format: true })
    const connected = networkService.connected

    return {
      networkId,
      networkName,
      walletAddress,
      htokens,
      hcoins,
      connected,
    }
  }

  const addNetworkCard = {
    networkId: ADD_NETWORK_CARD_ID,
    networkName: 'Add Network',
  }

  const networks = eachNetwork()
    .filter(networkService => user.getWalletAddress(networkService.network))
    .map(mapNetworkService)

  // addDummyNetworks(networks, 2, 0)
  networks.push(addNetworkCard)

  const { setItem, getItem } = useStorage('local')
  const [selectedNetworkKey, setSelectedNetworkKey] = useState(getItem(saveNetworkKey))
  let selectedNetwork = networks.find(({ networkId }) => networkId === selectedNetworkKey)
  if (!selectedNetwork && networks.length >= 1) {
    selectedNetwork = networks[0]
  }

  const selectNetwork = (network) => {
    const { networkId } = network
    if (networkId === ADD_NETWORK_CARD_ID) {
      if (selectedNetworkKey === networkId || networks.length === 1) {
        openModal(ModalTypes.NETWORKS, { showConnected: true, allowDelete: true, closeOnAdd: false })
      } else {
        setSelectedNetworkKey(networkId)
      }
      return
    }
    setSelectedNetworkKey(networkId)
    setItem(saveNetworkKey, networkId)
  }

  return (
    <Card.Container collapsed={collapsed}>
      <Card.Header onClick={collapseToggle} isWallet>
        <Card.HeaderTitle onClick={collapseToggle}>Networks</Card.HeaderTitle>
        <Card.HeaderOptions>
          <HeaderSwitch networks={networks} selectedNetwork={selectedNetwork} selectNetwork={selectNetwork} />
        </Card.HeaderOptions>
      </Card.Header>

      <Card.Stack>
        {
          networks.map(
            (item) =>
              <CardItem
                key={item.networkId}
                {...item}
                className={getClassName(item, networks, selectedNetwork)}
                onClick={() => selectNetwork(item)}
              />
          )
        }
      </Card.Stack>
    </Card.Container>
  )
}
