import React, { useContext, useState } from 'react'
// import styled from 'styled-components'
// import tw from 'twin.macro'

import Modal from '../ui/Modal'
import { LoadingContainer } from '../ui/Loader'
import { EmailForm, CodeForm, WalletForm, RegisterForm, VerifyEmailForm } from '../components/LoginForms'
import { TOAST_TYPES } from '../ui/Toast'
import { UiContext } from '../contexts/UiContext'
import { BackToLoginButton } from '../ui/Button'

// const Container = styled.div`
//   ${tw`w-full max-w-lg`};
// `

export function Login({ params }) {
  const [email, setEmail] = useState(params.email || '')
  const [walletLogin, setWalletLogin] = useState(false)
  const [register, setRegister] = useState(false)
  const { setToast } = useContext(UiContext)
  const { closeModal } = useContext(UiContext)
  const [isLoading, setLoading] = useState(false)
  const [askEmail, setAskEmail] = useState(params.verifyEmail)

  const onLoggedIn = (user) => {
    setToast({
      type: TOAST_TYPES.SUCCESS,
      message: `Welcome ${user.name}`,
      preserve: false,
    })
    if (!user.verifiedEmail) {
      setAskEmail(true)
    } else {
      closeModal()
    }
  }

  const onRegister = () => setRegister(true)
  const onConnectWallet = () => setWalletLogin(true)
  const onClearEmail = () => setEmail('')
  const onCancelWallet = () => setWalletLogin(false)
  const onBack = () => {
    setEmail('')
    setWalletLogin(false)
    setRegister(false)
  }

  if (askEmail) {
    return (
      <Modal.Container>
        <Modal.Header>
          <Modal.HeaderTitle colorSecondary>Create new Hora ID</Modal.HeaderTitle>
        </Modal.Header>

        <LoadingContainer isLoading={isLoading}>
          <Modal.Content>
            <VerifyEmailForm email={email} setEmail={setEmail} setLoading={setLoading} closeModal={closeModal} />
          </Modal.Content>
        </LoadingContainer>
      </Modal.Container>
    )
  }

  if (register) {
    return (
      <Modal.Container>
        <Modal.Header>
          <Modal.HeaderTitle colorSecondary>Create new Hora ID</Modal.HeaderTitle>
        </Modal.Header>
        <Modal.Content>
          <RegisterForm />
        </Modal.Content>
        <BackToLoginButton onClick={onBack} />
      </Modal.Container>
    )
  }

  if (!walletLogin) {
    return (
      <Modal.Container>
        <Modal.Header>
          <Modal.HeaderTitle colorSecondary>Connect with your Hora ID</Modal.HeaderTitle>
        </Modal.Header>

        <LoadingContainer isLoading={isLoading}>
          <Modal.Content>
            <EmailForm
              onEmailSubmit={setEmail}
              onConnectWallet={onConnectWallet}
              disabled={!!email}
              setLoading={setLoading}
            />

            <CodeForm
              email={email}
              onLoggedIn={onLoggedIn}
              onRegister={onRegister}
              onClearEmail={onClearEmail}
              passCode={params.passCode}
              setLoading={setLoading}
            />
          </Modal.Content>
        </LoadingContainer>
      </Modal.Container>
    )
  }

  return (
    <Modal.Container>
      <Modal.Header>
        <Modal.HeaderTitle colorSecondary>Connect with your Hora ID</Modal.HeaderTitle>
      </Modal.Header>
      <LoadingContainer isLoading={isLoading}>
        <Modal.Content>
          <WalletForm onLoggedIn={onLoggedIn} onCancelWallet={onCancelWallet} setLoading={setLoading} params={params}/>
        </Modal.Content>
      </LoadingContainer>
      <BackToLoginButton onClick={onBack} />
    </Modal.Container>
  )
}
