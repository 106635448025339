import React from 'react'
import tw, { styled } from 'twin.macro'
import { IconArrowDropdown } from './Icons'

export const Container = styled.div`

`

export const Label = styled.label`
  ${tw`block font-medium mb-1`}
  color: var(--modal-form-label);
`

export const InputContainer = styled.div`
  ${tw`flex relative border-b-[2px] pb-3 mb-4`}
  border-color: var(--modal-form-line);
`

export const InputField = styled.input`
  ${tw`flex-1 bg-transparent text-2xl`}
  color: var(--modal-form-value2);
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    ${tw`appearance-none`}
  }
  &:focus {
    outline: none;
  }
  -moz-appearance: textfield;
`

export const Token = styled.span`
  ${tw`font-medium text-2xl`}
  color: var(--modal-form-value);
`

const SliderContainer = styled.div`
  ${tw`flex items-center mt-5 mb-8 select-none`}
`

const SliderLabel = styled.div`
  ${tw`font-bold cursor-pointer`}
  color: var(--modal-slider-label);
  &:hover {
    color: var(--modal-steps-active);
  }
`

const SliderInputContainer = styled.div`
  ${tw`relative flex-1 mx-2 mb-[13px]`}
`

const SliderInput = styled.input`
  ${tw`relative w-full h-[10px] mt-8 mb-4 rounded-full overflow-hidden appearance-none cursor-pointer z-50`}
  background-color: var(--modal-slider-slide);
  &::-webkit-slider-runnable-track {
    /* background: #ddd; */
  }
  &::-webkit-slider-thumb {
    ${tw`w-4 h-4`}
    -webkit-appearance: none;
    background: var(--modal-slider-slide-active);
    box-shadow: -200px 0 0 200px var(--modal-slider-slide-active);
  }
`

const SliderInputThumb = styled.span`
  ${tw`
    absolute top-0 h-14 w-14
    flex flex-col justify-between items-center
    translate-x-[-30px]
    cursor-[default]
  `}
  left: ${props => props.cssPosition ? props.cssPosition : '0%'};
  svg {
    ${tw`rotate-180`}
  }
`

const SliderInputPercentage = styled.span`
  ${tw`text-xl`}
  color: var(--modal-slider-slide-active);
`

export const Input = (props) =>
  <Container>
    <Label>{props.label}</Label>
    <InputContainer>
      <InputField type='number' {...props} />
      {props.value2 ? <Token>{props.value2}</Token> : null}
    </InputContainer>
  </Container>

export const Slider = (props) => {
  const sliderPosition = Math.max(Math.round(((props.value - props.min) / (props.max - props.min) * 100)) || 0, 0)
  const sliderIncrement = (props.max - props.min) / 100
  const sliderPositionOffset = 18 - (18 / 100 * sliderPosition)
  const sliderPositionCss = `calc(${sliderPosition}% + ${sliderPositionOffset}px)`

  return (
    <SliderContainer>
      <SliderLabel onClick={props.minClick}>min</SliderLabel>
      <SliderInputContainer>
        <SliderInput type="range" step={sliderIncrement} {...props} />
        <SliderInputThumb cssPosition={sliderPositionCss}>
          <SliderInputPercentage>{sliderPosition}%</SliderInputPercentage>
          <IconArrowDropdown color="var(--modal-slider-slide-active)"/>
        </SliderInputThumb>
      </SliderInputContainer>
      <SliderLabel onClick={props.maxClick}>max</SliderLabel>
    </SliderContainer>
  )
}
