import tw, { styled } from 'twin.macro'

const Container = styled.div`
  ${tw``}
`

const Item = styled.div`
  ${tw`mt-2`}
`

const ItemRow = styled.div`
  ${tw`flex justify-between items-center`}
`

const Network = styled.span`
  ${tw`text-xl font-medium`}
  color: var(--modal-text);
`

const Address = styled.span`
  ${tw`text-xs font-medium mt-[2px]`}
  color: var(--modal-text);
`

const WarningText = styled.p`
  ${tw`text-sm font-medium mt-2`}
  color: var(--modal-label);
`

const AddTokensToWalletForm = styled.div`
  ${tw`text-sm font-medium mt-2`}
  color: var(--modal-label);
  strong {
    color: var(--modal-text);
    font-weight: 500;
  }
  a {
    cursor: pointer;
    margin-left: 0.5rem;
    &:hover {
      ${tw`text-accent`}
    }
  }
`

const Options = styled.div`
  ${tw`flex items-center`}
`

const OptionDelete = styled.div`
  ${tw`cursor-pointer`}
`

const OptionText = styled.span`
  ${tw`cursor-pointer font-bold`}
  color: var(--modal-text);
  + span {
    ${tw`ml-6`}
  }
  &:hover {
    ${tw`text-accent`}
  }
`

const WalletItems = {
  Container,
  Item,
  ItemRow,
  Network,
  Address,
  Options,
  OptionDelete,
  OptionText,
  WarningText,
  AddTokensToWalletForm,
}

export default WalletItems
