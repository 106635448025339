/* eslint-disable */
import React, { useEffect } from 'react'
import { LiFiWidget, useWidgetEvents, WidgetEvent } from '@lifi/widget'
import { ContainerScrollable, Content } from '../layouts/Layout'
import { PageMasking } from '../layouts/PageMasking'

const WidgetEvents = () => {
  const widgetEvents = useWidgetEvents();

  useEffect(() => {
    const onRouteExecutionStarted = (route) => {
      console.log('onRouteExecutionStarted fired.');
    };
    const onRouteExecutionUpdated = (update) => {
      console.log('onRouteExecutionUpdated fired.');
    };
    const onRouteExecutionCompleted = (route) => {
      console.log('onRouteExecutionCompleted fired.');
    };
    const onRouteExecutionFailed = (update) => {
      console.log('onRouteExecutionFailed fired.');
    };
    widgetEvents.on(WidgetEvent.RouteExecutionStarted, onRouteExecutionStarted);
    widgetEvents.on(WidgetEvent.RouteExecutionUpdated, onRouteExecutionUpdated);
    widgetEvents.on(
      WidgetEvent.RouteExecutionCompleted,
      onRouteExecutionCompleted,
    );
    widgetEvents.on(WidgetEvent.RouteExecutionFailed, onRouteExecutionFailed);
    return () => widgetEvents.all.clear();
  }, [widgetEvents]);

  return null;
};


export function Swap() {
  return (<PageMasking masking="Swap will be available before marketplace 😎">
    <ContainerScrollable className="masked-children">

    </ContainerScrollable>
  </PageMasking>)

  // return (
  //   <Content>
  //     <LiFiWidget
  //       config={{
  //         containerStyle: {
  //           border: `1px solid rgb(234, 234, 234)`,
  //           borderRadius: '16px',
  //         },
  //         variant: 'expandable',
  //         fromChain: 57,
  //         fromToken: '0x64e21979539e7862d6e947cdc956c2570D5219Ef',
  //       }}
  //       integrator="horaHub/horaGames"
  //     />
  //     <WidgetEvents />
  //   </Content>
  // )
}
