import React from 'react'
import tw, { styled } from 'twin.macro'
import { IconPlus } from './Icons'
import useCardCollapse from '../hooks/useCardCollapse'
import Card from './Card'

export const ItemsList = styled.div`
  ${props =>
    props?.children?.length >= 3 ?
      tw`grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-14` :
      tw`grid grid-cols-1 gap-14`}
`

export const Item = {
  Container: styled.div`
    border: 2px solid rgba(28, 56, 89, 0.7);
    ${tw`relative rounded-2xl`}
    min-width: 330px;
    min-height: 236px;
  `,
  TextLayer: styled.div`
    ${tw`absolute w-full h-full left-0 top-0 p-6 box-border`}
  `,
  Name: styled.div`
    ${tw`text-xl font-bold`}
  `,
  By: styled.div`
    ${tw`text-sm`}
  `,
  When: styled.div`
    ${tw`text-sm absolute`}
    bottom: 3em;
  `,
  What: styled.div`
    ${tw`text-sm absolute bottom-6`}
  `,
  Image: styled.img`
    border-radius: 20px;
    height: 100%;
    width: auto;
    object-fit: cover;
  `,
  EnterButton: styled.button`
    ${tw`text-xl font-bold absolute right-6 bottom-6 text-center rounded-s-sm`}
    width: 160px;
    height: 44px;
    border: 2px solid #FFFFFF;
  `,
  StatusText: styled.div`
    display: none;
  `,
  Description: styled.div`
    display: none;
  `,
}

export const Page = {
  Container: styled.div`
    
  `,
  Name: styled.div`
    font-weight: bold;
  `,
  Description: styled.div`
  
  `,
  Image: styled.img`
  
  `,
  TaskList: styled.ul`
    
  `,
}

export const Task = {
  Container: styled.li`
    border: 1px solid red;  
    border-radius: 3px;
  `,
  Title: styled.div`
  `,
  SubTitle: styled.div`
  `,
  Error: styled.div`
    color: red;
    font-weight: bold;
  `,
  IconWallet: () => <IconPlus width={32} height={32} color="red" />,
  IconTwitter: () => <IconPlus width={32} height={32} color="lightblue" />,

}


export const ItemsListCard = ({ title, cardKey, children }) => {
  const { collapsed, collapseToggle } = useCardCollapse(cardKey)

  return (
    <Card.Container collapsed={collapsed}>
      <Card.Header onClick={collapseToggle}>
        <Card.HeaderTitle onClick={collapseToggle}>{title}</Card.HeaderTitle>
      </Card.Header>
      <Card.SimpleContent>
        {children}
      </Card.SimpleContent>
    </Card.Container>
  )
}


export default {
  ItemsList,
  Item,
  Page,
  Task,
  ItemsListCard,
}
