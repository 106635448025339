import React, { useContext } from 'react'
import { UiContext } from '../contexts/UiContext'

import { Login } from './Login'
import { Withdraw } from './Withdraw'
import { Deposit } from './Deposit'
import tw, { styled } from 'twin.macro'
import { AddNetworksPopup } from '../components/Popups'

export const ModalTypes = {
  LOGIN: 'login',
  WITHDRAW: 'withdraw',
  DEPOSIT: 'deposit',
  NETWORKS: 'networks',
}

const Backdrop = styled.div`
  ${tw`w-full h-full absolute flex justify-center items-center`}
  background: rgba(0,0,0,.8);
  z-index: 1000;
  backdrop-filter: blur(4px);
`

const Container = styled.div`
  ${tw`w-full max-w-[480px] min-h-[200px] px-8 pt-6 pb-8 rounded-2xl`}
  background: var(--modal-bg);
  ${(props) => (props.modal.type === ModalTypes.LOGIN && tw`pb-6`)}
  /* border: 2px solid var(--modal-border); */
`

const Content = ({ modal, closeModal }) => {
  switch (modal.type) {
  case ModalTypes.LOGIN:
    return <Login params={modal.params} />
  case ModalTypes.WITHDRAW:
    return <Withdraw params={modal.params} />
  case ModalTypes.DEPOSIT:
    return <Deposit params={modal.params} />
  case ModalTypes.NETWORKS:
    return <AddNetworksPopup isOpen={true} onClose={closeModal} {...modal.params} />
  }
}

export const AppModals = () => {
  const { modal, closeModal } = useContext(UiContext)

  if (!modal) return null

  return (
    <Backdrop>
      <Container modal={modal}>
        <Content modal={modal} closeModal={closeModal}/>
      </Container>
    </Backdrop>
  )
}
