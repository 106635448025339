import React, { useState } from 'react'
import Select from './Select'
import WalletList from './WalletList'
import Popup from '../ui/Popup'
import { ButtonCTA as Button } from '../ui/Button'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { useGetUserModel, useWalletAddress } from '../hooks/useHoraHub'
import { getMessageForAddress } from '../services/HoraHub'
import { ImgWalletAnimation } from '../ui/Images'
import useToast from '../hooks/useToast'

const SelectAddNetwork = ({ label, select, networks, onAddNetwork, disabled }) => {
  const [network, setNetwork] = useState(select)

  const makeOption = ({ networkService, walletAddress }) => {
    const networkId = networkService.network
    const networkName = networkService.networkName

    return {
      id: networkId,
      value: networkName,
      description: walletAddress ? `Connected (${walletAddress})` : 'Not connected',
    }
  }

  const options = networks
    .filter(netObj => !netObj.walletAddress)
    .map(netObj => makeOption(netObj))

  if (!options.length) {
    return <Popup.Text>
      All networks are connected, if you want to change wallet address,
      you must delete the network associated with that address.
    </Popup.Text>
  }

  return (
    <>
      <Select
        label={label}
        placeHolder='Network'
        value={network}
        onChange={setNetwork}
        options={options}
        listScrollable={true}
        disabled={disabled}
      />

      <Button
        style={{ marginTop: '00px' }}
        fullWidth
        variant='secondary'
        onClick={() => onAddNetwork(network)}
        disabled={!network}>Connect
      </Button>
    </>
  )
}

export const AddNetworksPopup = (
  { isOpen, select, onClose, showConnected, allowDelete, closeOnAdd, selectDisabled }) => {
  const [addingNetwork, setAddingNetwork] = useState(false)
  const { connect, eachNetwork } = useWeb3Service()
  const user = useGetUserModel()
  const { toastError } = useToast()
  const { updateWallet } = useWalletAddress()

  const addNetwork = async (network) => {
    const walletAddress = user.getWalletAddress(network)
    if (walletAddress) return
    setAddingNetwork(true)
    const ns = await connect(network)
    const address = ns.address
    const message = await getMessageForAddress(address)
    const signature = await ns.personalSign(message)
    await updateWallet({ address, network, signature })
    setAddingNetwork(false)
    if (closeOnAdd) {
      onClose()
    }
  }

  const networks = eachNetwork()
    .map(networkService => ({ networkService, walletAddress: user.getWalletAddress(networkService.network) }))
  const countWallets = networks.filter(({ walletAddress }) => walletAddress).length


  let popupContent

  if (addingNetwork) {
    popupContent = (
      <Popup.Content spaceBetween>
        <Popup.Text>Please connect one of recommended Web3 wallets and follow instructions.</Popup.Text>
        <ImgWalletAnimation />
        <Popup.Text>Waiting for web3 connection.</Popup.Text>
        <Button
          fullWidth
          variant=''
          onClick={() => { setAddingNetwork(false) }}
        >
          Cancel
        </Button>
      </Popup.Content>
    )
  } else {
    popupContent = (
      <Popup.Content spaceBetween>
        {
          showConnected
            ?
            <Popup.ContentSection>
              <Popup.Label>Connected networks</Popup.Label>
              {
                !countWallets
                  ?
                  <Popup.Text>
                    There are no connected networks,
                    please select the network you would like to connect.
                  </Popup.Text>
                  :
                  <WalletList networks={networks} allowDelete={allowDelete} offerToAddTokens={true} />
              }
            </Popup.ContentSection>
            :
            null
        }
        <Popup.ContentSection>
          <SelectAddNetwork
            label='Select network'
            select={select}
            networks={networks}
            disabled={selectDisabled}
            onAddNetwork={
              (network) => addNetwork(network).catch((err) => { setAddingNetwork(false); toastError(err) })
            }
          />
        </Popup.ContentSection>
      </Popup.Content>
    )
  }

  return (
    <Popup.Container isOpen={isOpen} onOverlayClick={onClose}>
      {/* <button onClick={onClose}>CLOSE</button> */}
      <Popup.Header onXClick={onClose}>
        <Popup.HeaderTitle>
          Networks
        </Popup.HeaderTitle>
      </Popup.Header>
      {popupContent}
    </Popup.Container>
  )
}
