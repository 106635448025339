import axios from 'axios'
import { specialAddress } from '../libs/constants'
import config from '../config'
import { formatCurrency, shortenAddress } from '../libs/utils'

axios.defaults.baseURL = config.apiUrl
// axios.defaults.headers.common['Authorization'] = `bearer ${AUTH_TOKEN}`;
axios.defaults.withCredentials = true


let globalUnauthorizedState = false

export class UserModel {
  constructor(data) {
    this.data = data || UserModel.defaultData
    // console.log('data=', data)
  }

  static get defaultData() {
    return {
      name: 'Guest',
      email: '',
      cryptoPayments: [],
    }
  }

  get id() {
    return this.data.id
  }

  get name() {
    let name = this?.data?.name || this?.verifiedEmail
    if (!name && this?.data?.wallets?.length) {
      const wallet = this.data.wallets.find(w => w.address && w.addressVerified)
      if (wallet) {
        name = shortenAddress(wallet.address)
      }
    }

    return name || ''
  }

  get email() {
    if (!this?.data?.email) {
      return ''
    }
    if (typeof this.data.email === 'object') {
      return this.data.email.idValue
    }
    return this.data.email
  }

  get verifiedEmail() {
    if (!this?.data?.email) {
      return ''
    }
    if (typeof this.data.email === 'object' && this.data.email.verified) {
      return this.data.email.idValue
    }
    if (typeof this.data.email === 'string') {
      return this.data.email
    }
    return ''
  }

  get withdraw() {
    return this.data.withdraw || {}
  }

  get cryptoPayments() {
    return this.data.cryptoPayments || []
  }

  get wallets() {
    return this.data.wallets || []
  }

  get freeWithdrawsLeft() {
    return this.data?.withdraw?.freeWithdrawsLeft || 0
  }

  getTokenAmount(currency, { withdrawable, format, network: byNetwork }) {
    let total = 0
    this.data.wallets?.forEach(({ symbol, address, amount, network }) => {
      if (symbol === currency) {
        if (withdrawable) {
          if (address === specialAddress.HORAHUB) {
            total += amount
          }
        }
        if (byNetwork && network === byNetwork) {
          total += amount
        }
      }
    })

    if (format) {
      return formatCurrency(currency, total)
    }
    return total
  }

  getWalletAddress(byNetwork, currency) {
    if (this.data.wallets?.length) {
      for (let { address, symbol, network, addressVerified } of this.data.wallets) {
        if (addressVerified && byNetwork === network && (currency === symbol || !currency)) return address
      }
    }
  }

  getLastCryptoPaymentRecord() {
    return this.cryptoPayments[this.cryptoPayments.length - 1]
  }
}

function translateError(err) {
  if (err.name === 'AxiosError' && err.response.status >= 400) {
    err.message = err.response.data.error
    err.type = 'error'
    if (err.response.status === 403) {
      globalUnauthorizedState = true
    }
  }
  throw err
}

export function getPassCode(email) {
  return axios.post('/api/users/passCode', { email }).then(
    (result) => result.data
  ).catch(translateError)
}

export function webLogin(email, code) {
  // console.log('weblogin,', email, code)
  return axios.post('/api/users/webLogin', { email, code }).then(
    (result) => { clearUnauthorizedState(); return result.data }
  ).catch(translateError)
}

function checkUnauthorizedState() {
  // console.log('checkUnauthorizedState', globalUnauthorizedState)
  if (globalUnauthorizedState) return true
}

function clearUnauthorizedState() {
  globalUnauthorizedState = null
}

export function webRead() {
  if (checkUnauthorizedState()) return null

  return axios.get(`/api/users/webRead${config.avQs}`).then(
    (result) => { clearUnauthorizedState(); return result.data }
  ).catch(translateError)
}

export function webLogout() {
  return axios.get('/api/users/webLogout').then(
    (result) => result.data
  ).catch(translateError)
}

export function getMessageForAddress(address) {
  return axios.get('/api/users/getMessageForAddress', { params: { address } }).then(
    (result) => result.data.message
  ).catch(translateError)
}

export function walletLogin(data) {
  return axios.post('/api/users/walletLogin', data).then(
    (result) => { clearUnauthorizedState(); return result.data }
  ).catch(translateError)
}

export function updateWalletAddress(data) {
  return axios.post('/api/users/updateWalletAddress', data).then(
    (result) => result.data
  ).catch(translateError)
}

export function removeWalletAddress(data) {
  return axios.post('/api/users/removeWalletAddress', data).then(
    (result) => result.data
  ).catch(translateError)
}

export function beginWithdraw(method, address, amount) {
  return axios.post('/api/users/withdraw', { method, address, amount }).then(
    (result) => result.data
  ).catch(translateError)
}

export function webSignPermit(method, address, amount) {
  return axios.post('/api/users/webSignPermit', { method, address, amount }).then(
    (result) => result.data
  ).catch(translateError)
}

export function updateCryptoPaymentRecord(data) {
  return axios.post('/api/users/updateCryptoPaymentRecord', data).then(
    (result) => result.data
  ).catch(translateError)
}

export function generateDepositAddress(data) {
  return axios.post('/api/users/generateDepositAddress', data).then(
    (result) => result.data
  ).catch(translateError)
}

export function updateWalletBalances(data) {
  return axios.post('/api/users/updateWalletBalances', data).then(
    (result) => result.data
  ).catch(translateError)
}

export function getEarnings(interval) {
  if (checkUnauthorizedState()) return null

  return axios.get('/api/users/getEarnings', { params: { interval } }).then(
    (result) => result.data
  ).catch(translateError)
}

export function getPortfolio(addresses, networks, tokens, fiatCurrency) {
  if (checkUnauthorizedState()) return null

  return axios.post('/api/users/getPortfolio', { addresses, networks, tokens, fiatCurrency }).then(
    (result) => result.data
  ).catch(translateError)
}

export async function getUserPrefs() {
  if (checkUnauthorizedState()) return null
  try {
    const result = await axios.get('/api/users/prefs', { params: { namespace: config.horaHubPrefsNamespace } })
    // console.log('got user preferences', result.data)
    return result.data
  } catch (err) {
    return null
  }
}

export async function setUserPrefs(data) {
  if (checkUnauthorizedState()) return null
  try {
    const result = await axios.post('/api/users/prefs', { namespace: config.horaHubPrefsNamespace, data })
    return result.data
  } catch (err) {
    return null
  }
}

export function verifyEmail(email) {
  return axios.post('/api/users/verifyEmail', { email }).then(
    (result) => result.data
  ).catch(translateError)
}

export function getLaunchpadItemState(itemId) {
  if (!itemId) return null

  return axios.post('/api/launchpad/state', { id: itemId }).then(
    (result) => result.data
  ).catch(translateError)
}

export function launchpadMint(itemId, amount) {
  if (!itemId) return null

  return axios.post('/api/launchpad/mint', { id: itemId, amount }).then(
    (result) => result.data
  ).catch(translateError)
}

export function getQuestsItemState(itemId) {
  if (!itemId) return null

  return axios.post('/api/quests/state', { id: itemId }).then(
    (result) => result.data
  ).catch(translateError)
}

export function questsClaim(itemId) {
  if (!itemId) return null

  return axios.post('/api/quests/claim', { id: itemId }).then(
    (result) => result.data
  ).catch(translateError)
}


export default {
  getPassCode,
  webLogin,
  webRead,
}
