import React from 'react'
import { useLocation } from 'react-router-dom'
import { ContainerScrollable, Content } from '../layouts/Layout'
import QuestsItems, { QuestsItemPage } from '../components/QuestsItems'

export function Quests() {
  const location = useLocation()
  let id = location.pathname.replace('/quests', '')
  if (id) {
    id = id.substring(1)
    return (
      <Content>
        <QuestsItemPage id={id} />
      </Content>
    )
  }

  return (
    <ContainerScrollable>
      <Content>
        <QuestsItems />
      </Content>
    </ContainerScrollable>
  )
}
