import React from 'react'
import tw, { styled } from 'twin.macro'
import Launchpad from './Launchpad'
import { IconArrowRight } from './Icons'

export const ItemsList = styled.div`
  ${props =>
    props?.children?.length >= 3 ?
      tw`grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-14` :
      tw`grid grid-cols-1 gap-14`}
`

export const Item = {
  Amount: styled.div`
    ${tw`text-sm absolute`}
    bottom: 3em;
  `,
  Currency: styled.div`
    ${tw`text-sm absolute bottom-6`}
  `,
  ...Launchpad.Item,
}


const IconArrowWrapper = ({ className }) => (
  <button className={className}>
    <IconArrowRight />
  </button>
)

export const ItemSingleTask = {
  Container: styled.div`
    border: 2px solid rgba(28, 56, 89, 0.7);
    ${tw`rounded-2xl flex items-stretch cursor-pointer`}
    + div {
      ${tw`mt-5`}
    }
  `,
  Icon: styled.img`
    ${tw`rounded-2xl flex items-center m-4`}
    height: 60px;
    width: 60px;
    object-fit: cover;
  `,
  TextContent1: styled.div`
    ${tw`flex flex-col min-w-[4.7rem] justify-center m-4`}
    color: var(--main-sections-title);
  `,
  Name: styled.div`
    ${tw`text-xl font-bold`}
  `,
  By: styled.div`
    ${tw`text-sm`}
  `,
  TextContent2: styled.div`
    ${tw`flex flex-col flex-1 justify-center m-4 items-end`}
    color: var(--main-sections-title);
  `,
  Amount: styled.div`
    ${tw`text-xl font-bold`}
  `,
  StatusText: styled.div`
    ${tw`text-sm`}
  `,
  IconEnter: styled(IconArrowWrapper)`
    ${tw`flex flex-col justify-center p-4`}
  `,
}

export const Page = Launchpad.Page

export const Task = Launchpad.Task

export const ItemsListCard = Launchpad.ItemsListCard

export default {
  ItemsList,
  Item,
  Page,
  Task,
  ItemsListCard,
  ItemSingleTask,
}
