import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UiContextProvider } from './contexts/UiContext'
import { ConfigReloaderProvider } from './contexts/ConfigReloader'
import { ThemeProvider } from './contexts/ThemeContext'

import { setQueryClient, initWeb3Networks } from './services/Web3Service'

import AppRoutes from './AppRoutes'
import { AppModals } from './modals/AppModals'
import GlobalStyles from './styles/GlobalStyles'
import { LoaderFullScreen } from './ui/Loader'
import { Toast } from './ui/Toast'
import { StyledNotificationsContainer, NotificationsContainer } from './ui/Notifications'
import { loadConfig } from './config'
import { setConfigAppVersionQueryString } from './libs/utils'

const queryClient = new QueryClient()
setQueryClient(queryClient)
let appInitializing = false

const App = () => {
  const [appInit, setAppInit] = useState(false)
  const initializeApp = async () => {
    if (appInitializing) {
      return
    }
    appInitializing = true
    try {
      // console.log(appInit)
      Pace.stop()  // eslint-disable-line
      setConfigAppVersionQueryString()
      await loadConfig('website', true)
      initWeb3Networks()
      setAppInit(true)
    } catch (e) {
      console.error(e)
      Pace.stop()  // eslint-disable-line
      document.getElementById('root')
        .innerHTML = '<p style="color: red;">There was an error loading the application.<br />' +
        `'${e.message || e.code}'.<br /> Check your internet connection, then ` +
        '<a style="color: blue;" href="javascript:window.location.reload()">RELOAD PAGE</a></p>'
    }
  }

  useEffect(() => { initializeApp().catch(() => {}) }, []) // eslint-disable-line no-empty-function

  return (<QueryClientProvider client={queryClient}>
    <UiContextProvider>
      <ThemeProvider>
        <GlobalStyles />
        {
          appInit ?
            <ConfigReloaderProvider>
              <BrowserRouter>
                <Toast />
                <AppModals />
                <AppRoutes />
              </BrowserRouter>
            </ConfigReloaderProvider>
            :
            <LoaderFullScreen />
        }
        <StyledNotificationsContainer>
          <NotificationsContainer />
        </StyledNotificationsContainer>
      </ThemeProvider>
    </UiContextProvider>
  </QueryClientProvider>)
}


export default App
