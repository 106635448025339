import BigNumber from 'bignumber.js'
import { currencies } from './constants'
import config from '../config'


export const isValidEmail = email =>
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const welcomeTypes = ['Good morning', 'Good afternoon', 'Good evening']

export function getGreeting(name) {
  const hour = new Date().getHours()

  let welcomeText = welcomeTypes[2]
  if (hour < 12) {
    welcomeText = welcomeTypes[0]
  } else if (hour < 18) {
    welcomeText = welcomeTypes[1]
  }

  return `${welcomeText}, ${name}`
}

const currencyFormat = new Intl.NumberFormat(Intl.defaultLocale, { maximumFractionDigits: 2 })

export function formatCurrency(currency, value) {
  switch (currency) {
  case currencies.CURRENCY_HORA_COIN:
    return currencyFormat.format(value)
  case currencies.CURRENCY_HORA_TOKEN:
    return currencyFormat.format(value)
  }
  return value.toFixed(2)
}

export const compareAddress = (a, b) => String(a).toLowerCase() === String(b).toLowerCase()

export function shortenAddress(address) {
  if (!address) return ''
  const text = address || ''
  const first = text.slice(0, 5)
  const last = text.slice(text.length - 5, text.length)
  return `${first}...${last}`
}

const dateOptions = { dateStyle: 'medium' }

export function shortDate(d) {
  return new Date(d).toLocaleDateString('en-US', dateOptions) || ''
}

export function round(num, decimals) {
  const d = Math.pow(10, decimals)
  return Math.round((num + Number.EPSILON) * d) / d
}

export function countDecimals(num) {
  const inputNumberBn = new BigNumber(num)
  return inputNumberBn.dp()
}

export function buildAppVersionString() {
  return config.appVersion + (config.appRevision ? '-'.concat(config.appRevision) : '')
}

export function setConfigAppVersionQueryString() {
  const q = new URLSearchParams()
  q.set('av', buildAppVersionString())
  config.avQs = '?'.concat(q.toString())

}

export function isPathWhiteListed(routeObj, baseRoutePath = '') {
  const fullPath = baseRoutePath + routeObj.path.replace('/*', '')
  return config.routesWl && config.routesWl.split(' ').includes(fullPath)
}

export const createUUID = () => {
  const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return pattern.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : ((r & 0x3) | 0x8)
    return v.toString(16)
  })
}

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer')
}

export const delay = ms => new Promise(res => setTimeout(res, ms))
