import { useContext, useEffect } from 'react'
import { useGetUserModel } from '../hooks/useHoraHub'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { UiContext } from '../contexts/UiContext'
import config from '../config'


export default function AutoLogin() {
  const { openModal } = useContext(UiContext)
  const user = useGetUserModel()
  const { getWithdrawMethodConfig } = useWeb3Service()

  useEffect(() => {
    const withdrawAddress = config?.searchParams?.withdrawAddress
    if (user.id && withdrawAddress &&
      user?.withdraw?.address === withdrawAddress &&
      user?.withdraw?.status === 'requested') {
      delete config.searchParams.withdrawAddress
      const { method, amount } = user.withdraw
      const methodConfig = getWithdrawMethodConfig(method)
      const { currency, network } = methodConfig
      openModal('withdraw', {
        step: 2,
        step1State: { currency, network, amount, numAmount: parseFloat(amount), method: methodConfig, withdrawAddress },
      })
    }

    const email = config?.searchParams?.email
    const passCode = config?.searchParams?.passCode
    if (!user.id && email && passCode) {
      delete config.searchParams.email
      delete config.searchParams.passCode
      openModal('login', { email, passCode })
    }
  })

  return null
}
