import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getPassCode,
  webLogin,
  webRead,
  webLogout,
  walletLogin,
  updateWalletAddress,
  removeWalletAddress,
  beginWithdraw,
  webSignPermit,
  updateCryptoPaymentRecord,
  generateDepositAddress,
  updateWalletBalances,
  UserModel, getEarnings,
  getPortfolio,
  verifyEmail,
  getLaunchpadItemState,
  launchpadMint,
  getQuestsItemState,
  questsClaim,
} from '../services/HoraHub'
import { earningsIntervals } from '../libs/constants'
import config from '../config'

const USER_KEY = 'user'

export const useGetUser = (options = {}) => useQuery(USER_KEY, () => webRead(), options)

let pageRefresh = true

export const useGetUserModel = (options) => {
  const { data: user } = useGetUser(options)
  const queryClient = useQueryClient()
  if (pageRefresh && user) {
    pageRefresh = false
    updateWalletBalances({ }).then((u) => {
      queryClient.setQueryData(USER_KEY, u)
    }).catch(console.error)
  }
  return new UserModel(user)
}

function invalidateEarnings(queryClient) {
  Object.keys(earningsIntervals)
    .map(key => earningsIntervals[key])
    .map(interval => `earnings:${interval}`)
    .forEach(queryKey => queryClient.invalidateQueries(queryKey))
}

export const useWebLogin = (email, code, onLoggedIn, onError) => {
  const queryClient = useQueryClient()

  return useQuery([USER_KEY, code],
    () => webLogin(email, code),
    {
      enabled: !!code,
      onSuccess: (user) => {
        invalidateEarnings(queryClient)
        queryClient.invalidateQueries('launchpad')
        queryClient.setQueryData(USER_KEY, user)
        onLoggedIn(new UserModel(user))
        invalidateEarnings(queryClient)
      },
      onError,
      retry: false,
    }
  )
}

export const useRequestPassCode = (onSuccess, onError) => useMutation({
  mutationFn: (email) => getPassCode(email),
  onSuccess,
  onError,
})

export const useWebLogout = () => {
  const queryClient = useQueryClient()
  return async () => {
    await webLogout()
    delete config.searchParams
    queryClient.setQueryData(USER_KEY, undefined)
    invalidateEarnings(queryClient)
    queryClient.invalidateQueries('launchpad')
  }
}

export const useWalletLogin = () => {
  const queryClient = useQueryClient()

  return async (data) => {
    const user = await walletLogin(data)
    queryClient.setQueryData(USER_KEY, user)
    invalidateEarnings(queryClient)
    queryClient.invalidateQueries('launchpad')
    return new UserModel(user)
  }
}

export const useWalletAddress = () => {
  const queryClient = useQueryClient()

  const updateWallet = async (data) => {
    const user = await updateWalletAddress(data)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }

  const removeWallet = async (data) => {
    const user = await removeWalletAddress(data)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }

  return {
    updateWallet,
    removeWallet,
  }
}

export const useBeginWithdraw = () => {
  const queryClient = useQueryClient()
  return async (method, address, amount) => {
    const user = await beginWithdraw(method, address, amount)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useWebSignPermit = () => {
  const queryClient = useQueryClient()
  return async (method, address, amount) => {
    const user = await webSignPermit(method, address, amount)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useUpdateCryptoPaymentRecord = () => {
  const queryClient = useQueryClient()
  return async (fieldsToUpdate) => {
    const user = await updateCryptoPaymentRecord(fieldsToUpdate)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useGenerateDepositAddress = () => {
  const queryClient = useQueryClient()
  return async (data) => {
    const user = await generateDepositAddress(data)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useUpdateWalletBalances = () => {
  const queryClient = useQueryClient()
  return async (data = {}) => {
    const user = await updateWalletBalances(data)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useGetEarnings = (interval) => {
  const queryFn = () => getEarnings(interval)
  return useQuery(`earnings:${interval}`, queryFn)
}

export const useGetPortfolio = ({ addresses, networks, tokens, fiatCurrency }) =>
  useQuery('portfolio', () => getPortfolio(addresses, networks, tokens, fiatCurrency))


export const useVerifyEmail = () => {
  const queryClient = useQueryClient()
  return async (email) => {
    const user = await verifyEmail(email)
    queryClient.setQueryData(USER_KEY, user)
    return user
  }
}

export const useGetLaunchpadItemState = (itemId) => {
  const queryFn = () => getLaunchpadItemState(itemId)
  return useQuery(['launchpad', { itemId }], queryFn)
}

export const useLaunchpadMint = () => {
  const queryClient = useQueryClient()
  return async (itemId, amount) => {
    const state = await launchpadMint(itemId, amount)
    queryClient.setQueryData(['launchpad', { itemId }], state)
    return state
  }
}

export const useGetQuestsItemState = (itemId) => {
  const queryFn = () => getQuestsItemState(itemId)
  return useQuery(['quests', { itemId }], queryFn)
}

export const useQuestsClaim = () => {
  const queryClient = useQueryClient()
  return async (itemId) => {
    const state = await questsClaim(itemId)
    queryClient.setQueryData(['quests', { itemId }], state)
    return state
  }
}

