import React, { useState, useMemo } from 'react'
import Card from '../ui/Card'
import { ChartBars } from '../ui/ChartBars'
import { useGetEarnings } from '../hooks/useHoraHub'
import { earningsIntervals, earningsIntervalsNames } from '../libs/constants'
import useCardCollapse from '../hooks/useCardCollapse'
import Dropdown from './Dropdown'

function getRoundedMax(values, divisions) {
  let max = 0
  for (let value of values) {
    max = Math.max(max, value)
  }
  if (max < 1) {
    return 1
  }
  if (max < 10) {
    return 10
  }
  if (Number.isInteger(max / divisions)) {
    return max
  }
  let r = 10
  let newMax
  do {
    newMax = Math.ceil(max / r) * r
    r *= 10
  } while (!Number.isInteger(newMax / divisions) && r < max)
  return newMax
}

function getYAxis(max, divisions) {
  const step = max / divisions
  return [...Array(divisions + 1)]
    .map((e, i) => String(step < 1 ? (step * i).toFixed(2) : Math.floor(step * i)))
    .reverse()
}

const getBars = (data, max, count) => (
  data.map(({ time, label, value }) => ({ time, label, value, count, countActive: Math.ceil((value / max) * count) }))
)

function transformData(data) {
  if (!data || !data.length) {
    data = []
  }
  const values = data.map(({ value }) => value)
  const divisions = 5
  const barLinesCount = 16
  const max = getRoundedMax(values, divisions)
  const yAxisLabels = values.length ? getYAxis(max, divisions) : []
  const bars = getBars(data, max, barLinesCount)
  return { yAxisLabels, bars }
}

export function CoinsEarnedCard() {
  const { collapsed, collapseToggle } = useCardCollapse('coinsEarned')
  const [ selectedInterval, setSelectedInterval ] = useState(earningsIntervals.weekly)
  const { data } = useGetEarnings(selectedInterval)
  const dropDownItems = useMemo(
    () => Object.keys(earningsIntervals)
      .map(key => earningsIntervals[key])
      .map(value => ({ name: earningsIntervalsNames[value], value })), [])
  const { yAxisLabels, bars } = transformData(data)

  return (
    <Card.Container collapsed={collapsed}>
      <Card.Header onClick={collapseToggle}>
        <Card.HeaderTitle onClick={collapseToggle}s>Coins Earned</Card.HeaderTitle>
        <Card.HeaderOptions>
          <Dropdown
            minWidth='8rem'
            title='Select Period'
            value={selectedInterval}
            items={dropDownItems}
            onChange={setSelectedInterval}
          />
        </Card.HeaderOptions>
      </Card.Header>
      <Card.Content borderColor="var(--balance-bg)">
        <ChartBars
          yAxisLabels={yAxisLabels}
          bars={bars}
        />
      </Card.Content>
    </Card.Container>
  )
}

