import React, { useContext } from 'react'
import tw, { styled } from 'twin.macro'
import { IconClose } from './Icons'
import { CloseButton } from './Button'
import { UiContext } from '../contexts/UiContext'


export const HeaderContainer = styled.div`
  ${tw`relative flex justify-between items-center mb-12`}
`

export const PopupHeader = ({ children, onXClick }) => {
  const { closeModal } = useContext(UiContext)

  return (
    <HeaderContainer>
      {children ? children : <div/>}
      <CloseButton onClick={onXClick || closeModal}>
        <IconClose color="var(--modal-close)"/>
      </CloseButton>
    </HeaderContainer>
  )
}
