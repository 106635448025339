import React from 'react'
import { useLocation } from 'react-router-dom'
import { ContainerScrollable, Content } from '../layouts/Layout'
import LaunchpadItems, { LaunchpadItemPage } from '../components/LaunchpadItems'

export function Launchpad() {
  const location = useLocation()
  let id = location.pathname.replace('/launchpad', '')
  let content
  if (id) {
    id = id.substring(1)
    content = <LaunchpadItemPage id={id} />
  } else {
    content = <LaunchpadItems />
  }

  return (
    <ContainerScrollable>
      <Content>
        {content}
      </Content>
    </ContainerScrollable>
  )
}
