import Web3Modal from 'web3modal'
// import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js'
import config from '../config'

const CoinbaseWalletSDK = null

function buildConfig(netConfig) {
  const modalConfig = Object.assign({
    cacheProvider: false,
    theme: 'dark',
  }, netConfig?.web3Modal || {}, { providerOptions: {} })

  let options
  options = netConfig?.web3Modal?.providerOptions?.walletconnect
  if (options) {
    modalConfig.providerOptions.walletconnect = {
      package: WalletConnectProvider,
      options,
    }
  }

  options = netConfig?.web3Modal?.providerOptions?.coinbasewallet
  if (options && typeof CoinbaseWalletSDK !== 'undefined' && CoinbaseWalletSDK) {
    options.appName = config.appName
    modalConfig.providerOptions.coinbasewallet = {
      package: CoinbaseWalletSDK,
      options,
    }
  }

  options = netConfig?.web3Modal?.providerOptions?.binancechainwallet
  if (options) {
    modalConfig.providerOptions.binancechainwallet = {
      package: true,
      options,
    }
  }

  return modalConfig
}

const decimalsTable = []
decimalsTable[0] = 'wei'
decimalsTable[6] = 'mwei'
decimalsTable[18] = 'ether'

export function decimalsToEthjsUnit(decimals) {
  return decimalsTable[decimals] || 'wei'
}

export const initializeWeb3Modal = (netConfig) => new Web3Modal(buildConfig(netConfig))

