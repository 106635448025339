import React from 'react'
import tw from 'twin.macro'
import { buildAppVersionString } from '../libs/utils'

const AppVersionContainer = tw.div`
  // absolute right-0 bottom-0 h-16 w-64
`

const AppVersion = () => (
  <AppVersionContainer>
    v{buildAppVersionString()}
  </AppVersionContainer>
)

export default AppVersion
