import React from 'react'
import tw, { styled } from 'twin.macro'

import { LoadingContainer } from './Loader'
import { IconArrowLeftLong } from './Icons'

const StyledButton = styled.button`
  ${tw`p-2 w-full border border-solid rounded-md text-white transition-all hover:opacity-80`}
  
  &:disabled {
    background: red;
  }
`

const Tabbable = styled.button`
  ${tw`p-0 m-0 w-auto border-none transition-all hover:opacity-80 bg-transparent`}
`

const StyledButton2 = styled.button`
  ${tw`p-2 border border-solid rounded-md text-black transition-all hover:opacity-80 bg-red-100`}
  background-color: ${props => props.active ? 'yellow' : 'red'};
`

export function Button2({ onClick, children, ...rest }) {
  return (
    <StyledButton2 onClick={onClick} {...rest}>
      {children}
    </StyledButton2>
  )
}

export function Button({ onClick, children, ...rest }) {
  return (
    <StyledButton onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  )
}

export function TabbableButton({ children, onClick, ...rest }) {
  return (
    <Tabbable onClick={onClick} {...rest}>
      {children}
    </Tabbable>
  )
}

export const CloseButton = styled.button`
 ${tw`outline-none`}
`

export const ButtonLink = styled.button(({ inline, textSize, mt }) => [ // eslint-disable-line  no-empty-pattern
  tw`flex self-start items-center text-accent font-medium`,
  `svg {
    margin-left: 10px;
  }
  span {
    
  }
  &:hover {
    text-decoration: underline;
  }
  `,
  inline && [
    tw`inline-block`,
  ],
  textSize === 'sm' && [
    tw` text-sm`,
  ],
  mt === 'lg' && [
    tw`mt-[1.125rem]`,
  ],
  mt === 'xl' && [
    tw`mt-5`,
  ],
  mt === '2xl' && [
    tw`mt-6`,
  ],
])

export function ButtonLoading({ children, isLoading, variant, fullWidth, marginTop, ...rest }) {
  return (
    <ButtonCTA isLoading={isLoading} variant={variant} fullWidth={fullWidth} marginTop={marginTop} {...rest}>
      <LoadingContainer isLoading={isLoading || false}>
        {children}
      </LoadingContainer>
    </ButtonCTA>
  )
}

export const ButtonCTA = styled.button(({ isLoading, variant, fullWidth, marginTop }) => [
  // The common button styles
  tw`px-3 py-1 text-fgSecondary font-semibold text-xl border-2 border-fgSecondary rounded-[2px]`,
  tw`transition-all duration-300`,

  // Use the variant grouping feature to add variants to multiple classes

  // Use props to conditionally style your components
  //   variant === 'primary' && tw`bg-black text-white border-black`,

  // Combine regular css with tailwind classes within backticks
  //   variant === 'secondary' && [
  //     css`
  //       box-shadow: 0 0.1em 0 0 rgba(0, 0, 0, 0.25);
  //     `,
  //     tw`border-2 border-yellow-600`,
  //   ],

  fullWidth && [
    tw`w-full h-[3.5rem] py-3`
  ],

  variant === 'primary' && [
    tw`hover:(text-accent border-accent)`,
  ],

  variant === 'secondary' && [
    tw`text-accent border-accent`,
    tw`hover:(bg-accent text-bgPrimary)`,
    tw`disabled:(text-fgSecondary border-fgSecondary bg-transparent opacity-40)`
  ],

  variant === 'filled' && [
    tw`text-bgPrimary border-accent`,
    tw`bg-[color:var(--color-accent) !important]`,
    tw`hover:(opacity-70)`,
    tw`disabled:(bg-[color:var(--color-fg-tetriary) !important] border-fgTetriary opacity-100)`
  ],

  isLoading && [ // TODO: prikazati loader
    tw`flex justify-center items-center line-through py-2.5`,
    tw`text-accent border-accent cursor-default`,
    tw`hover:(bg-transparent text-accent)`,
  ],
  marginTop === 'lg' && [
    tw`mt-[1.125rem]`,
  ],
  marginTop === 'xl' && [
    tw`mt-5`,
  ],
  marginTop === '2xl' && [
    tw`mt-6`,
  ],
  marginTop === '4xl' && [
    tw`mt-[1.875rem]`,
  ]
])

export const ConnectTwitterButton = styled.button`
  background-color: red;
`

export const TwitterConnectedButton = styled.div`
  background-color: green;
`

export const ConnectNetworkButton = styled.button`
  background-color: red;
`

export const NetworkConnectedButton = styled.div`
  background-color: green;
`

export const BackToLoginButton = ({ onClick }) => (
  <ButtonLink onClick={onClick} mt='xl' textSize="sm">
    <div style={{ transform: 'rotate(-180deg)' }}>
      <IconArrowLeftLong color="var(--color-accent)"/>
    </div>
    Back to login
  </ButtonLink>
)
