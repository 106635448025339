import React from 'react'
import { Link } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

const Item = styled.li`
  ${tw`flex items-center w-full text-center md:text-left`}
  + li {
    ${tw`mt-2`}
  }
`

const EnabledLink = (props) => (
  props.disabled ?
    <Link {...props} to={{}} onClick={null} /> :
    <Link {...props} />
)

const PageLink = styled(EnabledLink)`
  ${tw`mx-auto w-full md:py-1 transition duration-200 rounded-md`}

  ${(props) => (props.$isActive ?
    'color: var(--sidebar-text-active);'
    : 'color: var(--sidebar-text);')}

  :hover {
    color: var(--sidebar-text-active);
  }

  ${(props) => (props.disabled ? tw`opacity-40` : tw`hover:opacity-100`)}
`

const HrefLink = styled.a`
  ${tw`mx-auto w-full md:py-1 transition duration-200 rounded-md hover:opacity-100`}

  ${(props) => (props.$isActive ?
    'color: var(--sidebar-text-active);'
    : 'color: var(--sidebar-text);')}

  :hover {
    color: var(--sidebar-text-active);
  }
`

const ItemXL = styled.li`
  ${tw`w-full flex items-center md:py-2 text-center md:border-none md:text-left`}
`

const PageLinkXL = styled(EnabledLink)`
  ${tw`
    flex flex-col md:flex-row items-center mx-auto w-full 
    md:py-3 md:px-2 transition duration-200 rounded-md hover:opacity-100
  `}
  
  svg {
    min-width: 22px;
    min-height: 28px;
  }

  span {
    ${tw`block mt-1 md:mt-0 md:ml-6 text-lg`};
  }
  
  ${(props) => (props.$isActive ?
    'color: var(--sidebar-text-active);'
    : 'color: var(--sidebar-text);')}

  :hover {
    color: var(--sidebar-text-active);
  }

  ${(props) => (props.$collapsed ? tw`justify-center` : '')}
`

const Nav = {
  Item,
  PageLink,
  HrefLink,
  ItemXL,
  PageLinkXL,
}

export default Nav
