import React from 'react'
import config from '../config'
import { useGetUserModel } from '../hooks/useHoraHub'
import { ConnectTwitterButton, TwitterConnectedButton } from '../ui/Button'
import { useStorage } from '../hooks/useStorage'

const ConnectTwitter = () => {
  const user = useGetUserModel()
  const { setItem } = useStorage('local')

  const doConnect = () => {
    let dst = config.apiUrl
    if (dst === 'http://localhost:3000') {
      dst = 'http://localhost:8000'
    }
    dst = `${dst}/auth/twitter`

    setItem('returnToPage', window.location.href)

    // console.log(window.location.href, dst)
    window.location = dst
  }

  if (!user?.id) {
    return null
  }
  if (user?.data?.twitter?.idValue) {
    const name = user?.data?.twitter?.displayName || user?.data?.twitter?.username
    return <TwitterConnectedButton>Connected as {name}</TwitterConnectedButton>
  }

  return <ConnectTwitterButton onClick={doConnect}>
    Connect Twitter
  </ConnectTwitterButton>
}

export default ConnectTwitter
