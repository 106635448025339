import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { isPathWhiteListed } from './libs/utils'
import { Dashboard } from './layouts/Dashboard'
import { Home } from './pages/Home'
import { Test } from './pages/Test'
import { Marketplace } from './pages/Marketplace'
import { Portfolio } from './pages/Portfolio'
import { Launchpad } from './pages/Launchpad'
import { Quests } from './pages/Quests'
import { Staking } from './pages/Staking'
import { Swap } from './pages/Swap'
import { About } from './pages/About'
import { Settings } from './pages/Settings'
import { NotFound } from './pages/NotFound'
import { IconLaunchpad, IconQuests, IconMarketplace, IconPortfolio, IconSwap } from './ui/Icons'

export const mainAppRoutes = [
  {
    path: '/',
    icon: () => <IconPortfolio/>,
    label: 'Portfolio',
    layout: <Dashboard />,
    element: <Home />,
    subPages: [
      {
        path: 'transactions',
        label: 'Transactions',
        element: <About />,
      },
      {
        path: 'earnings',
        label: 'Earnings',
        element: <About />,
      },
      {
        path: 'staking',
        label: 'Staking',
        element: <About />,
      },
      {
        path: 'governance',
        label: 'Governance',
        element: <About />,
      },
      {
        path: 'portfolio',
        label: 'Portfolio',
        element: <Portfolio />,
      },
      {
        path: 'test',
        label: 'Test',
        element: <Test />,
      },
    ],
  },
  {
    path: '/marketplace',
    icon: () => <IconMarketplace/>,
    label: 'Marketplace',
    layout: <Dashboard />,
    element: <Marketplace />,
    subPages: [],
  },
  {
    path: '/launchpad/*',
    icon: () => <IconLaunchpad/>,
    label: 'Launchpad',
    layout: <Dashboard />,
    element: <Launchpad />,
    subPages: [],
  },
  {
    path: '/quests/*',
    icon: () => <IconQuests/>,
    label: 'Quests',
    layout: <Dashboard />,
    element: <Quests />,
    subPages: [],
  },
  {
    path: '/staking',
    icon: () => <IconLaunchpad/>,
    label: 'Staking',
    layout: <Dashboard />,
    element: <Staking />,
    subPages: [],
  },
  {
    path: '/swap/*',
    icon: () => <IconSwap/>,
    label: 'Swap',
    layout: <Dashboard />,
    element: <Swap />,
    subPages: [],
  },
  {
    path: '/about',
    icon: () => <IconSwap/>,
    label: 'About',
    layout: <Dashboard />,
    element: <About />,
    subPages: [],
  }
]

export const secondaryAppRoutes = [
  {
    label: 'Settings',
    path: '/settings',
    layout: <Dashboard />,
    element: <Settings />,
    subPages: [
      {
        path: 'test',
        label: 'Test',
        element: <Test />,
      },
      {
        path: 'themes',
        label: 'Themes',
        element: <About />,
      },
    ],
  },
  {
    label: 'Support',
    path: '/support',
    layout: <Dashboard />,
    element: <About />,
    subPages: [],
  },
]

const allRoutes = mainAppRoutes.concat(secondaryAppRoutes)

const subPage = ({ path, element }) => <Route key={path} path={path} element={element} />

const AppRoutes = () => (
  <Routes>
    {
      allRoutes
        .filter(r => isPathWhiteListed(r))
        .map(({ path, layout, element, subPages }) => (
          <Route key={path} path={path} element={layout}>
            <Route path={path.includes('/*') ? '*' : ''} element={element} />
            {
              subPages
                .filter(r => isPathWhiteListed(r, path))
                .map(subPage)}
            <Route path="*" element={<NotFound />} />
          </Route>
        ))
    }
  </Routes>
)

export default AppRoutes
