import React, { useContext, useState } from 'react'
import { Loader, LoadingContainer } from '../ui/Loader'
import Modal from '../ui/Modal'
import { useWeb3Service } from '../hooks/useWeb3Service'
import {
  useBeginWithdraw, useGenerateDepositAddress,
  useGetUserModel,
  useUpdateCryptoPaymentRecord,
  useWalletAddress,
  useWebSignPermit
} from '../hooks/useHoraHub'
import { shortenAddress, compareAddress, formatCurrency } from '../libs/utils'
import useToast from '../hooks/useToast'
import { getMessageForAddress } from '../services/HoraHub'
import { SelectNetwork, SelectToken, SelectWithdrawMethod } from './Select'
import { StepProgressBar, StepProgressLabel, StepProgressTx } from '../ui/StepProgress'
import { ButtonCTA as Button } from '../ui/Button'
import { UiContext } from '../contexts/UiContext'
import InputTokenAmount from './InputTokenAmount'
import { DEPOSIT_MODE, WITHDRAW_MODE, paymentReasons } from '../libs/constants'
import { ImgWalletAnimation } from '../ui/Images'
import { countDecimals } from '../libs/utils'
import config from '../config'
// eslint-disable-next-line
import tw, { styled, css } from 'twin.macro'


const Texts = {
  CONNECT_WALLET: 'Please connect Web3 wallet and follow instructions.',
  CONNECT_WALLET_LABEL: 'Waiting for Web3 connection',
  CONNECT_WALLET_BTN: 'Connect wallet',

  DEPOSIT_DATA: 'Please double check your address and the amount you would like to deposit.',
  DEPOSIT_DATA_LABEL: 'Waiting for confirmation',
  DEPOSIT_DATA_BTN: 'Confirm address',
  DEPOSIT_BEGIN_TRANSACTION: 'After you proceed, you will need to confirm the\n' +
    'transaction in your wallet and pay the gas fee.',
  DEPOSIT_BEGIN_TRANSACTION_LABEL: 'Waiting for user to initiate transaction',
  DEPOSIT_BEGIN_TRANSACTION_BTN: 'Begin transaction',
  WITHDRAW_METHOD_LABEL: 'Select withdraw method',
  WITHDRAW_METHOD_FREE_LABEL: 'You can proceed with free withdraw method',
  WITHDRAW_METHOD_FREE_BTN: 'Withdraw',
  WITHDRAW_METHOD_PAID_LABEL: 'Proceed to next step',
  WITHDRAW_BEGIN_TRANSACTION: 'WARNING: After you proceed, the requested funds will be immediately\n' +
    'deducted from your account, so the only way to recover them is to complete all of the following steps.',
  WITHDRAW_BEGIN_TRANSACTION_LABEL: 'Waiting for user to initiate transaction',

  PROCEED: 'Proceed',
  AGREE: 'I Agree, proceed',
  WARNING: 'WARNING: after you proceed, the requested funds will be immediately deducted from your account,\n' +
    '            so the only way to recover them is to complete all of the following steps',
}

const DepositInfoText = ({ numAmount, currency, network }) => {
  const { getNetwork, getTokenConfig } = useWeb3Service()
  const user = useGetUserModel()

  const networkService = getNetwork(network)
  const networkName = networkService.networkName
  const amount = formatCurrency(currency, numAmount)
  const currencyName = `${getTokenConfig(currency, network).name }s`
  const address = user.getWalletAddress(network, currency)

  return (
    <Modal.Text>
      You are about to deposit {amount} {currencyName} from {networkName}, address {shortenAddress(address)}.
    </Modal.Text>
  )
}

const WithdrawInfoText = ({ numAmount, currency, network, withdrawAddress }) => {
  const { getNetwork, getTokenConfig } = useWeb3Service()
  const user = useGetUserModel()

  const networkService = getNetwork(network)
  const networkName = networkService.networkName
  const amount = formatCurrency(currency, numAmount)
  const currencyName = `${getTokenConfig(currency, network).name}s`
  const address = user.getWalletAddress(network, currency) || withdrawAddress

  return (
    <Modal.Text>
      You are about to withdraw {amount} {currencyName} to {networkName}, address {shortenAddress(address)}.
    </Modal.Text>
  )
}

const connectWallet = async ({ connect, address, network, withdrawAddress, updateWallet }) => {
  const networkService = await connect(network)

  const doWalletUpdate = async () => {
    const message = await getMessageForAddress(address)
    const signature = await networkService.personalSign(message)
    await updateWallet({ address, network, signature })
  }

  if (withdrawAddress) {
    if (!compareAddress(networkService.address, withdrawAddress)) {
      throw new Error(`Wallet address should be ${withdrawAddress} but is ${networkService.address}`)
    }

    if (!address) {
      address = withdrawAddress
      await doWalletUpdate()
    } else if (!compareAddress(address, withdrawAddress)) {
      throw new Error(`Requested withdraw to ${withdrawAddress} but linked address is ${address}`)
    }
  } else if (address) {
    if (!compareAddress(address, networkService.address)) {
      throw new Error(`Wallet address should be ${address} but is ${networkService.address}`)
    }
  } else {
    address = networkService.address
    await doWalletUpdate()
  }
}

// eslint-disable-next-line max-statements
export const WithdrawProcedure = ({ step1State, onFinished, paymentId, setPaymentId }) => {
  const { connect, getContract, getNetwork } = useWeb3Service()
  let [stepProgress, setStepProgress] = useState(step1State.method ? 2 : 1)
  const [method, setMethod] = useState(step1State.method || '')
  const user = useGetUserModel()
  const beginWithdraw = useBeginWithdraw()
  const { toastError } = useToast()
  const webSignPermit = useWebSignPermit()
  const updateCryptoPaymentRecord = useUpdateCryptoPaymentRecord()
  const { updateWallet } = useWalletAddress()
  const [buttonLoading, setButtonLoading] = useState(false)

  const { currency, network, numAmount, withdrawAddress } = step1State
  const networkServiceCheck = getNetwork(network)
  let address = user.getWalletAddress(network, currency)

  if (stepProgress === 2 &&
    address &&
    networkServiceCheck.connected &&
    compareAddress(networkServiceCheck.address, address)) {
    console.log('advancing to stepProgress 3')
    stepProgress = config.useTransferWithPermit ? 5 : 3
  }

  let stepContent = null
  let buttonText = Texts.PROCEED
  let progressLabel = ''
  let progressPercent = 0
  let buttonHandler

  const getLastPayment = async () => {
    let lastPayment
    if (!paymentId) {
      const { cryptoPayments } = await webSignPermit(method.method, address, numAmount)
      lastPayment = cryptoPayments[cryptoPayments.length - 1]
      setPaymentId(lastPayment.id)
    } else {
      lastPayment = user.cryptoPayments.find(cp => cp.id === paymentId)
    }
    if (!lastPayment || lastPayment.withdrawMethod !== method.method) throw new Error('permit withdraw failed')
    return lastPayment
  }

  const freeWithdraw = async () => {
    const { cryptoPayments } = await beginWithdraw(method.method, address, numAmount)
    const lastPayment = cryptoPayments[cryptoPayments.length - 1]
    if (!lastPayment || lastPayment.withdrawMethod !== method.method) {
      throw new Error('withdraw failed')
    }
    return lastPayment.id
  }

  const signApproval = async () => {
    const lastPayment = await getLastPayment()
    // eslint-disable-next-line no-shadow
    const { network, currency, state, id } = lastPayment
    const contract = getContract(network, currency)
    // console.log('begin', contract)

    /* let txHash = */await contract.executePermit(state, async (receipt) => {
      console.log(receipt)
      await updateCryptoPaymentRecord({
        id,
        permitTxHash: receipt.transactionHash,
        permitTxStatus: receipt.status,
        withdrawStatus: 'approval',
        error: '',
      })
      setButtonLoading(false)
      setStepProgress(4)
    })
  }

  const doTransfer = async () => {
    const lastPayment = user.cryptoPayments.find(cp => cp.id === paymentId)
    // eslint-disable-next-line no-shadow
    const { network, currency, state, id } = lastPayment
    const contract = getContract(network, currency)
    console.log('do transfer', contract)

    /* let txHash = */await contract.transferFrom(state, async (receipt) => {
      console.log(receipt)
      await updateCryptoPaymentRecord({
        id,
        open: false,
        txHash: receipt.transactionHash,
        txResult: '1',
        withdrawStatus: 'completed',
        error: '',
      })

      setButtonLoading(false)
      onFinished(paymentId)
    })
  }

  const transferFromWithPermit = async () => {
    const lastPayment = await getLastPayment()
    // eslint-disable-next-line no-shadow
    const { network, currency, state, id } = lastPayment
    const contract = getContract(network, currency)

    let txHash = await contract.transferFromWithPermit(state, async (receipt) => {
      console.log(receipt)
      if (receipt.status) {
        await updateCryptoPaymentRecord({
          id,
          permitTxHash: receipt.transactionHash,
          permitTxStatus: receipt.status,
          open: false,
          txHash: receipt.transactionHash,
          txResult: '1',
          withdrawStatus: 'completed',
          error: '',
        })
      } else {
        await updateCryptoPaymentRecord({
          id,
          permitTxHash: receipt.transactionHash,
          permitTxStatus: receipt.status,
          txHash: receipt.transactionHash,
          error: 'failed',
        })
      }
    })

    return {
      txHash,
      lastPayment,
    }
  }

  switch (stepProgress) {
  case 1:
    stepContent = (
      <>
        <WithdrawInfoText {...step1State} />
        <SelectWithdrawMethod
          label="Please select withdraw method"
          currency={currency}
          network={network}
          value={method}
          onChange={setMethod}
        />
        <div />
      </>
    )
    progressLabel = Texts.WITHDRAW_METHOD_LABEL
    progressPercent = 10
    if (method) {
      if (method.free) {
        buttonText = Texts.WITHDRAW_METHOD_FREE_BTN
        progressLabel = Texts.WITHDRAW_METHOD_FREE_LABEL
        progressPercent = 90
        buttonHandler = () => {
          setButtonLoading(true)
          freeWithdraw().then((pId) => {
            setButtonLoading(false)
            onFinished(pId)
          }).catch((err) => {
            toastError(err)
            setButtonLoading(false)
          })
        }
      } else {
        buttonHandler = () => setStepProgress(2)
        progressLabel = Texts.WITHDRAW_METHOD_PAID_LABEL
        progressPercent = 30
      }
    }
    break
  case 2:
    stepContent = (
      <>
        <WithdrawInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>{Texts.CONNECT_WALLET}</Modal.Text>
      </>
    )
    progressLabel = Texts.CONNECT_WALLET_LABEL
    buttonText = Texts.CONNECT_WALLET_BTN
    progressPercent = 50
    buttonHandler = () => {
      setButtonLoading(true)
      connectWallet(
        { connect, address, network, withdrawAddress, updateWallet }
      ).then(() => {
        setButtonLoading(false)
        setStepProgress(config.useTransferWithPermit ? 5 : 3)
      }).catch((err) => {
        toastError(err)
        setButtonLoading(false)
      })
    }
    break
  // START - NOT IN USE
  case 3:
    stepContent = (
      <>
        <WithdrawInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>
            Click proceed, then sign the APPROVAL transaction in your wallet.
          {Texts.WARNING}
        </Modal.Text>
      </>
    )
    progressLabel = 'Waiting for user to confirm approval'
    buttonText = Texts.AGREE
    progressPercent = 40
    buttonHandler = () => {
      setButtonLoading(true)
      signApproval()
        .then(() => {}) // eslint-disable-line no-empty-function
        .catch((err) => {
          toastError(err)
          setButtonLoading(false)
        })
    }
    break
  case 4:
    stepContent = (
      <>
        <WithdrawInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>Click proceed, then sign the TRANSFER transaction in your wallet</Modal.Text>
      </>
    )
    progressLabel = 'Waiting for user to confirm the transfer transaction'
    buttonText = Texts.PROCEED
    progressPercent = 50
    buttonHandler = () => {
      setButtonLoading(true)
      doTransfer()
        .then(() => {}) // eslint-disable-line no-empty-function
        .catch((err) => {
          toastError(err)
          setButtonLoading(false)
        })
    }
    break
  // END - NOT IN USE
  case 5:
    stepContent = (
      <>
        <WithdrawInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>{Texts.WITHDRAW_BEGIN_TRANSACTION}</Modal.Text>
      </>
    )
    progressLabel = Texts.WITHDRAW_BEGIN_TRANSACTION_LABEL
    buttonText = Texts.AGREE
    progressPercent = 75
    buttonHandler = () => {
      setButtonLoading(true)
      transferFromWithPermit()
        // .then(({ txHash, lastPayment }) => {
        .then(({ lastPayment }) => {
          setButtonLoading(false)
          onFinished(lastPayment.id)
        })
        .catch((err) => {
          console.error(err)
          toastError(err)
          setButtonLoading(false)
        })
    }
    break
  }

  return (
    <Step2Page
      progressLabel={progressLabel}
      progressPercent={progressPercent}
      buttonHandler={buttonHandler}
      buttonText={buttonText}
      buttonLoading={buttonLoading}
    >
      {stepContent}
    </Step2Page>
  )
}
// eslint-disable-next-line max-statements
export const DepositProcedure = ({ step1State, onFinished, paymentId, setPaymentId }) => {
  const { connect, getContract, getNetwork } = useWeb3Service()
  let [stepProgress, setStepProgress] = useState(1)
  const user = useGetUserModel()
  const { toastError } = useToast()
  const { updateWallet } = useWalletAddress()
  const generateDepositAddress = useGenerateDepositAddress()
  const updateCryptoPaymentRecord = useUpdateCryptoPaymentRecord()
  const [buttonLoading, setButtonLoading] = useState(false)


  const { currency, network, numAmount } = step1State
  const networkServiceCheck = getNetwork(network)
  let address = user.getWalletAddress(network, currency)

  if (stepProgress === 1 &&
    address &&
    networkServiceCheck.connected &&
    compareAddress(networkServiceCheck.address, address)) {
    console.log('advancing to stepProgress 2')
    stepProgress = 2
  }

  let stepContent = null
  let buttonText = Texts.PROCEED
  let progressLabel = ''
  let progressPercent = 0
  let buttonHandler

  const beginDeposit = async () => {
    let lastPayment
    if (!paymentId) {
      const { cryptoPayments } = await generateDepositAddress({
        fromAddress: address,
        network,
        currency,
        amount: numAmount,
      })
      lastPayment = cryptoPayments[cryptoPayments.length - 1]
      setPaymentId(lastPayment.id)
    } else {
      lastPayment = user.cryptoPayments.find(cp => cp.id === paymentId)
    }
    if (
      !lastPayment ||
      lastPayment.reason !== paymentReasons.DEPOSIT // ...
    ) throw new Error('failed to create payment record')
  }

  const doTransfer = async () => {
    const lastPayment = user.cryptoPayments.find(cp => cp.id === paymentId)
    const { toAddress, amount } = lastPayment
    const contract = getContract(network, currency)
    const txHash = await contract.transferTokens(toAddress, amount)
    if (txHash) {
      await updateCryptoPaymentRecord({
        id: lastPayment.id,
        txHash,
      })
    }
  }

  switch (stepProgress) {
  case 1:
    stepContent = (
      <>
        <DepositInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>{Texts.CONNECT_WALLET}</Modal.Text>
      </>
    )
    progressLabel = Texts.CONNECT_WALLET_LABEL
    buttonText = Texts.CONNECT_WALLET_BTN
    progressPercent = 10
    buttonHandler = () => {
      setButtonLoading(true)
      connectWallet(
        { connect, address, network, updateWallet }
      ).then(() => {
        setButtonLoading(false)
        setStepProgress(2)
      }).catch((err) => {
        toastError(err)
        setButtonLoading(false)
      })
    }
    break
  case 2:
    stepContent = (
      <>
        <DepositInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>{Texts.DEPOSIT_DATA}</Modal.Text>
      </>
    )
    progressLabel = Texts.DEPOSIT_DATA_LABEL
    buttonText = Texts.DEPOSIT_DATA_BTN
    progressPercent = 40
    buttonHandler = () => {
      setButtonLoading(true)
      beginDeposit().then(() => {
        setButtonLoading(false)
        setStepProgress(3)
      }).catch((err) => {
        toastError(err)
        setButtonLoading(false)
      })
    }
    break
  case 3:
    stepContent = (
      <>
        <DepositInfoText {...step1State} />
        <ImgWalletAnimation />
        <Modal.Text>{Texts.DEPOSIT_BEGIN_TRANSACTION}</Modal.Text>
      </>
    )
    progressLabel = Texts.DEPOSIT_BEGIN_TRANSACTION_LABEL
    buttonText = Texts.DEPOSIT_BEGIN_TRANSACTION_BTN
    progressPercent = 70
    buttonHandler = () => {
      setButtonLoading(true)
      doTransfer().then(() => {
        setButtonLoading(false)
        onFinished(paymentId)
      }).catch((err) => {
        toastError(err)
        setButtonLoading(false)
      })
    }
    break
  }

  return (
    <Step2Page
      progressLabel={progressLabel}
      progressPercent={progressPercent}
      buttonHandler={buttonHandler}
      buttonText={buttonText}
      buttonLoading={buttonLoading}
    >
      {stepContent}
    </Step2Page>
  )
}


export const FinishPage = ({ paymentId }) => {
  const { closeModal } = useContext(UiContext)
  const user = useGetUserModel({ refetchInterval: 30000 })
  const { getNetwork } = useWeb3Service()
  const payment = user.cryptoPayments.find(cp => cp.id === paymentId)
  const { amount, currency, txHash, open, network } = payment

  const networkService = getNetwork(network)
  const amountReal = networkService.AllContracts.getContractBySymbol(currency).fromWei(amount)
  const tokenName = networkService.AllContracts.getContractBySymbol(currency).config.name
  const amountFormatted = `${formatCurrency(currency, amountReal)} ${tokenName}s`
  const label = open ? 'Waiting for transaction' : 'Transaction confirmed'
  const link = networkService.generateBlockExplorerLink({ txHash })


  return (
    <Modal.Content>
      <Modal.ContentSection center>
        <Modal.Text>Congrats!</Modal.Text>
        <Modal.Text tw='max-w-[15rem]'>Your {amountFormatted} is on the way.</Modal.Text>
        <img src='/animation-fireworks.gif' width={240} tw='mx-auto my-6' />
        <Modal.Text tw='max-w-[17rem]'>You can now close the popup, or wait for transaction to finish.</Modal.Text>
      </Modal.ContentSection>

      <Modal.ContentSection>
        <StepProgressLabel>{label}</StepProgressLabel>
        <StepProgressTx tx={txHash} link={link} />
        <Button fullWidth variant='secondary' onClick={closeModal}>Finish</Button>
      </Modal.ContentSection>
    </Modal.Content>
  )
}

export const Step1Page = ({ step1State, setStep1State, setStep, mode }) => {
  const user = useGetUserModel()
  const { getContract } = useWeb3Service()

  const { currency, network, amount, numAmount } = step1State
  let lowBalanceMessage = '', selectTokenLabel = '', showSelectNetwork = true
  switch (mode) {
  case WITHDRAW_MODE:
    selectTokenLabel = 'Select token'
    if (currency && user.getTokenAmount(currency, { withdrawable: true }) <= 0) {
      lowBalanceMessage = `Insufficient balance to withdraw ${currency}`
      showSelectNetwork = false
    }
    break
  case DEPOSIT_MODE:
    selectTokenLabel = 'Select token'
    if (network && user.getTokenAmount(currency, { network }) <= 0) {
      lowBalanceMessage = `Insufficient wallet balance to deposit ${currency}`
    }
    break
  }

  const verifyStep1 = () => {
    if (!(currency && network && amount && numAmount)) {
      return false
    }
    const contract = getContract(network, currency)
    const { decimals } = contract.config
    return countDecimals(amount) <= decimals
  }

  return (
    <Modal.Content>
      <Modal.ContentSection>
        <SelectToken
          label={selectTokenLabel}
          value={currency}
          onChange={
            // eslint-disable-next-line no-shadow
            currency =>
              setStep1State({ ...step1State, currency, network: null, amount: '0', numAmount: 0 })
          }
        />
        {
          showSelectNetwork &&
            <SelectNetwork
              label='Select network'
              value={network}
              onChange={n => setStep1State({ ...step1State, currency, network: n, amount: '0', numAmount: 0 })}
              currency={currency}
            />
        }
      </Modal.ContentSection>

      <Modal.ContentSection>
        {
          lowBalanceMessage && <Modal.Text mode='error'>{lowBalanceMessage}</Modal.Text>
        }
        {
          network && !lowBalanceMessage ?
            <InputTokenAmount
              mode={mode}
              value={amount}
              onChange={
                // eslint-disable-next-line no-shadow
                ([amount, numAmount]) => setStep1State({ ...step1State, currency, network, amount, numAmount })
              }
              currency={currency}
              network={network}
            /> : null
        }
        <Button
          disabled={!verifyStep1()}
          fullWidth
          variant='secondary'
          onClick={() => setStep(2)}
        >
          Next step
        </Button>
      </Modal.ContentSection>
    </Modal.Content>
  )
}

const Step2Page = ({ progressLabel, progressPercent, buttonHandler, buttonText, children, buttonLoading }) => (
  <Modal.Content>
    {children}
    <Modal.ContentSection>
      <StepProgressLabel>{progressLabel}</StepProgressLabel>
      <StepProgressBar progress={progressPercent} blocks={36} />
      <Button
        disabled={!buttonHandler && !buttonLoading}
        fullWidth
        variant='secondary'
        onClick={buttonLoading ? null : buttonHandler}
        isLoading={buttonLoading}
      >
        {buttonLoading ? <Loader /> : buttonText}
      </Button>
    </Modal.ContentSection>
  </Modal.Content>
)

export const ModalLayout = ({ isLoading, step, mode, children }) => (
  <Modal.Container>
    <LoadingContainer isLoading={isLoading}>
      <Modal.Header>
        <Modal.HeaderTitle bold>{mode === WITHDRAW_MODE ? 'Withdraw' : 'Deposit'}</Modal.HeaderTitle>
        <Modal.HeaderSteps count={3} active={step} />
      </Modal.Header>
      {children}
    </LoadingContainer>
  </Modal.Container>
)
