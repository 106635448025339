import { useContext } from 'react'
import { UiContext } from '../contexts/UiContext'
import { TOAST_TYPES } from '../ui/Toast'

export default function useToast() {
  const { setToast } = useContext(UiContext)

  function toastError(err) {
    setToast({
      type: TOAST_TYPES.ALERT,
      message: err.message || err || 'Undefined error',
      preserve: false,
    })
  }

  function toast(message) {
    setToast({
      type: TOAST_TYPES.SUCCESS,
      message,
      preserve: false,
    })
  }

  return {
    toastError,
    toast,
  }
}
