import React from 'react'
import { PageMasking } from '../layouts/PageMasking'
import { ContainerScrollable } from '../layouts/Layout'

export function Marketplace() {
  return (
    <PageMasking masking="Marketplace will be available in 2024.">
      <ContainerScrollable className="masked-children">

      </ContainerScrollable>
    </PageMasking>
  )
}
