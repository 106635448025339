import { useQuery } from 'react-query'
import Web3Service from '../services/Web3Service'
import { useStorage } from './useStorage'

export function useWeb3Service() {
  const { setItem } = useStorage('local')


  return {
    connect: async (network) => {
      const networkService = await Web3Service.networks[network].connect()
      if (networkService && networkService.connected) {
        setItem(`${network}-autoconnect`, '1')
      }
      return networkService
    },
    useNetwork: (network) =>
      useQuery(
        [network || 'no network'],
        ({ queryKey }) => Web3Service.networks[queryKey]
      ),
    eachNetwork: (callback) =>
      Object.keys(Web3Service.networks)
        .map(key => callback ? callback(Web3Service.networks[key]) : Web3Service.networks[key]),
    networkFromWithdrawMethod: (method) =>
      Object.values(Web3Service.networks)
        .find(network => Object.keys(network.config.withdraw || {}).includes(method)),
    getNetwork: (network) =>
      Object.values(Web3Service.networks)
        .find(networkService => network === networkService.network),
    getContract: (network, currencyOrKeyOrAddress) => {
      const net = Object.values(Web3Service.networks).find(networkService => network === networkService.network)
      let contract = net.AllContracts.getContractBySymbol(currencyOrKeyOrAddress)
      if (!contract) {
        contract = net.AllContracts.getContractByKey(currencyOrKeyOrAddress)
      }
      if (!contract) {
        contract = net.AllContracts.getContractByAddress(currencyOrKeyOrAddress)
      }
      return contract
    },
    getTokenConfig: function(currency, network) {
      let result
      Object.keys(Web3Service.networks).forEach(key => {
        const conf = Web3Service.networks[key]?.config?.contracts || {}
        const fnd = Object.values(conf).find(tc => tc.symbol === currency)
        if (fnd && (!network || network === key)) {
          result = fnd
        }
      })
      return result
    },
    getWithdrawMethods: (network, currency) => {
      const networkService = Object.values(Web3Service.networks)
        .find(ns => network === ns.network)
      const { withdraw } = networkService.config
      return Object.keys(withdraw)
        .map(method => Object.assign({ method }, withdraw[method]))
        .filter(elem => elem.currency === currency)
    },
    getWithdrawMethodConfig: (method) => {
      const networkService = Object.values(Web3Service.networks)
        .find(network => Object.keys(network.config.withdraw || {}).includes(method))
      const { withdraw } = networkService.config
      const methods = Object.keys(withdraw).map(m => Object.assign({ method: m }, withdraw[m]))
      return methods.find(m => m.method === method)
    },
  }
}

