import React from 'react'
import tw, { styled } from 'twin.macro'

/* eslint-disable max-len */

const XMLNS = 'http://www.w3.org/2000/svg'

export const IconHome = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 19} viewBox="0 0 22 19" fill={color} xmlns={XMLNS}>
    <rect y="5" width="19" height="3"/>
    <rect width="17" height="3"/>
    <rect y="10" width="22" height="9"/>
  </svg>
)

export const IconPortfolio = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 20} viewBox="0 0 22 20" fill={color} xmlns={XMLNS}>
    <rect y="2" width="20" height="3"/>
    <rect width="8" height="2"/>
    <rect y="7" width="22" height="13"/>
  </svg>
)

export const IconMarketplace = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 20} viewBox="0 0 22 20" fill={color} xmlns={XMLNS}>
    <rect x="12" y="11" width="10" height="9"/>
    <rect y="11" width="10" height="9"/>
    <rect x="12" width="10" height="9"/>
    <rect width="10" height="9"/>
  </svg>
)

export const IconLaunchpad = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 18} viewBox="0 0 22 18" fill={color} xmlns={XMLNS}>
    <rect x="2" y="5" width="20" height="3"/>
    <rect x="2" y="15" width="20" height="3"/>
    <rect width="19" height="3"/>
    <rect y="10" width="19" height="3"/>
  </svg>
)

export const IconQuests = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 18} viewBox="0 0 22 18" fill={color} xmlns={XMLNS}>
    <rect x="2" y="5" width="20" height="3"/>
    <rect x="2" y="15" width="20" height="3"/>
    <rect width="19" height="3"/>
    <rect y="10" width="19" height="3"/>
  </svg>
)

export const IconSwap = ({ width, height, color }) => (
  <svg width={width ? width : 22} height={height ? height : 20} viewBox="0 0 22 20" fill={color} xmlns={XMLNS}>
    <path d="M5.76383 3.61398V0L0 5.15549L5.76383 10.31V6.77807H22V3.61398H5.76383Z"/>
    <path d="M16.2362 16.3859V19.9999L22 14.8454L16.2362 9.68994V13.2219H0V16.3859H16.2362Z"/>
  </svg>
)

export const IconArrow = ({ width, height, color }) => (
  <svg width={width ? width : 16} height={height ? height : 16} viewBox="0 0 16 16" fill={color} xmlns={XMLNS}>
    <path d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM16 7L1 7V9L16 9V7Z"/>
  </svg>
)

export const IconArrowLeftLong = ({ width, height, color }) => (
  <svg width={width ? width : 18} height={height ? height : 15} viewBox="0 0 18 15" fill="none" xmlns={XMLNS}>
    <path d="M10.4971 13.9784L16.3171 8.15841L10.4971 2.33838" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M16.3181 8.15892H0.000255283" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
)

export const IconArrowRightLong = ({ width, height, color }) => (
  <svg width={width ? width : 18} height={height ? height : 15} viewBox="0 0 18 15" fill="none" xmlns={XMLNS}>
    <path d="M10.4971 13.9784L16.3171 8.15841L10.4971 2.33838" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M16.3181 8.15892H0.000255283" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
)

export const IconClose = ({ width, height, color }) => (
  <svg width={width ? width : 16} height={height ? height : 16} viewBox="0 0 16 16" fill={color} xmlns={XMLNS}>
    <path d="M15 1L1 15" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M0.999999 1L15 15" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
)

export const IconPlus = ({ width, height, color }) => (
  <svg width={width ? width : 46} height={height ? height : 46} viewBox="0 0 46 46" fill={color} xmlns={XMLNS}>
    <line y1="22" x2="46" y2="22" stroke={color} strokeWidth="2"/>
    <line x1="22" y1="46" x2="22" y2="4.37114e-08" stroke={color} strokeWidth="2"/>
  </svg>
)

export const IconArrowDropdown = ({ width, height, color, style, className }) => (
  <svg
    className={className}
    style={style} width={width ? width : 12} height={height ? height : 6} viewBox="0 0 12 6" fill={color} xmlns={XMLNS}
  >
    <path d="M6 6L0.803847 -2.51245e-08L11.1962 8.834e-07L6 6Z"/>
  </svg>
)

export const IconArrowRight = styled(IconArrowDropdown)`
  ${tw`-rotate-90`}
  fill: var(--main-sections-title);
`


export const IconAccount = ({ width, height, color, dotColor }) => (
  <svg width={width ? width : 29} height={height ? height : 22} viewBox="0 0 29 22" fill={color} xmlns={XMLNS}>
    <path d="M0 2.41789V6.88941L4 9.12517L8 6.88941V2.41789L4 0.182129L0 2.41789Z" fill={dotColor}/>
    <path d="M20.2438 12.4122L24.3251 10.0338V3.34459L18.4629 0L12.6749 3.34459V10.0338L16.7562 12.4122L8 17.3919V22H29V17.3919L20.2438 12.4122Z"/>
  </svg>
)

export const IconAccountBody = ({ width, height, color, style }) => (
  <svg style={style} width={width ? width : 21} height={height ? height : 22} viewBox="0 0 21 22" fill={color} xmlns={XMLNS}>
    <path d="M12.2438 12.4122L16.3251 10.0338V3.34459L10.4629 0L4.67491 3.34459V10.0338L8.75618 12.4122L0 17.3919V22H21V17.3919L12.2438 12.4122Z"/>
  </svg>
)

export const IconAccountDot = ({ width, height, color, style }) => (
  <svg style={style} width={width ? width : 8} height={height ? height : 10} viewBox="0 0 8 10" fill={color} xmlns={XMLNS}>
    <path d="M0 2.41789V6.88941L4 9.12517L8 6.88941V2.41789L4 0.182129L0 2.41789Z"/>
  </svg>
)

export const IconConnected = ({ width, height, color }) => (
  <svg width={width ? width : 8} height={height ? height : 9} viewBox="0 0 8 9" fill={color} xmlns={XMLNS}>
    <path d="M0 2.41789V6.88941L4 9.12517L8 6.88941V2.41789L4 0.182129L0 2.41789Z" fill={color}/>
  </svg>
)

export const IconOpenLink = ({ width, height, color }) => (
  <svg width={width ? width : 21} height={height ? height : 21} viewBox="0 0 21 21" xmlns={XMLNS}>
    <path d="M10.4615 4.07617H3C1.89543 4.07617 1 4.9716 1 6.07617V17.4608C1 18.5654 1.89543 19.4608 3 19.4608H14.3846C15.4892 19.4608 16.3846 18.5654 16.3846 17.4608V9.99925" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M19.4612 9.23077V1H11.2305" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M19.4623 1L7.92383 12.5385" stroke={color} strokeWidth="2" strokeMiterlimit="10"/>
  </svg>
)

export const Sun = () => (
  <svg xmlns={XMLNS} viewBox="0 0 24 24">
    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93v-.001zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121v-.001zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
  </svg>
)

export const Moon = () => (
  <svg xmlns={XMLNS} viewBox="0 0 24 24">
    <path d="M10 7a7 7 0 0 0 12 4.9v.1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2h.1A6.977 6.977 0 0 0 10 7zm-6 5a8 8 0 0 0 15.062 3.762A9 9 0 0 1 8.238 4.938 7.999 7.999 0 0 0 4 12z" />
  </svg>
)

export const IconLogoBinance = ({ width, height, color }) => (
  <svg width={width ? width : 64} height={height ? height : 64} viewBox="0 0 64 64" fill={color} xmlns={XMLNS}>
    <g clipPath="url(#clip0_350_538)">
      <path
        d="M19.8057 26.5758L32.2238 14.363L44.5008 26.5758L51.6976 19.4166L32.2238 0.0444336L12.75 19.4166L19.8057 26.5758Z"
        fill={color} />
      <path d="M7.7058 24.4384L0.521484 31.5852L7.7058 38.732L14.8901 31.5852L7.7058 24.4384Z" fill={color} />
      <path
        d="M19.8057 36.5427L32.2238 48.896L44.5008 36.5427L51.6976 43.702L32.2238 63.0741L12.75 43.702L19.8057 36.5427Z"
        fill={color} />
      <path d="M56.6941 24.4997L49.5098 31.6465L56.6941 38.7933L63.8784 31.6465L56.6941 24.4997Z" fill={color} />
      <path
        d="M39.4215 31.6295L32.2247 24.3298L26.7212 29.6642L26.1568 30.2257L24.8867 31.4891V31.6295L32.2247 38.7887L39.4215 31.6295Z"
        fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_350_538">
        <rect x="0.474609" y="0.0444336" width="63.3605" height="63.0296" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const IconLogoTron = ({ width, height, color }) => (
  <svg width={width ? width : 55} height={height ? height : 56} viewBox="0 0 55 56" fill={color} xmlns={XMLNS}>
    <path d="M53.828 16.8634C51.1648 14.4396 47.4806 10.7384 44.4799 8.11335L44.3024 7.99085C44.007 7.75703 43.6739 7.57366 43.317 7.44835V7.44835C36.0817 6.11834 2.40904 -0.0854383 1.7521 -0.00668792C1.56802 0.0187222 1.39207 0.0845038 1.2372 0.185813L1.06852 0.317064C0.860826 0.524965 0.703078 0.776129 0.606888 1.05207L0.5625 1.16582V1.78707V1.88332C4.35323 12.2871 19.3208 46.3685 22.2682 54.3661C22.4458 54.9086 22.7831 55.9411 23.4134 55.9936H23.5555C23.8928 55.9936 25.331 54.1211 25.331 54.1211C25.331 54.1211 51.0405 23.3909 53.6416 20.1184C53.9783 19.7153 54.2755 19.2817 54.5294 18.8234C54.5941 18.4648 54.5636 18.0958 54.4407 17.7523C54.3178 17.4088 54.1067 17.1026 53.828 16.8634V16.8634ZM31.927 20.4422L42.8997 11.4734L49.336 17.3184L31.927 20.4422ZM27.6658 19.8559L8.77427 4.59583L39.3398 10.1521L27.6658 19.8559ZM29.3703 23.8547L48.7057 20.7834L26.6005 47.0335L29.3703 23.8547ZM6.20865 6.11834L26.0856 22.7434L23.2092 47.051L6.20865 6.11834Z" fill={color}/>
  </svg>
)

export const IconLogoEthereum = ({ width, height, color }) => (
  <svg width={width ? width : 47} height={height ? height : 74} viewBox="0 0 47 74" fill={color} xmlns={XMLNS}>
    <path d="M23.4925 0L22.9971 1.6841V50.5527L23.4925 51.0473L46.1768 37.6387L23.4925 0Z" fill={color}/>
    <path d="M23.4929 0L0.808594 37.6387L23.4929 51.0475V27.3281V0Z" fill={color}/>
    <path d="M23.4931 55.3423L23.2139 55.6827V73.0908L23.4931 73.9064L46.1908 41.9404L23.4931 55.3423Z" fill={color}/>
    <path d="M23.4929 73.9062V55.3421L0.808594 41.9402L23.4929 73.9062Z" fill={color}/>
    <path d="M23.4932 51.0471L46.1771 37.6387L23.4932 27.3281V51.0471Z" fill={color}/>
    <path d="M0.808594 37.639L23.4925 51.0474V27.3284L0.808594 37.639Z" fill={color}/>
  </svg>
)

export const IconLogoPolygon = ({ width, height, color }) => (
  <svg width={width ? width : 63} height={height ? height : 53} viewBox="0 0 63 53" fill={color} xmlns={XMLNS}>
    <path d="M47.7024 16.1988C46.5509 15.5697 45.0705 15.5697 43.7546 16.1988L34.5431 21.3887L28.2924 24.6914L19.2454 29.8813C18.094 30.5104 16.6136 30.5104 15.2977 29.8813L8.22454 25.7923C7.07311 25.1632 6.25065 23.905 6.25065 22.4896V14.6261C6.25065 13.368 6.90862 12.1098 8.22454 11.3234L15.2977 7.39169C16.4491 6.76261 17.9295 6.76261 19.2454 7.39169L26.3185 11.4807C27.47 12.1098 28.2924 13.368 28.2924 14.7834V19.9733L34.5431 16.5134V11.1662C34.5431 9.90801 33.8851 8.64985 32.5692 7.8635L19.4099 0.47181C18.2585 -0.15727 16.7781 -0.15727 15.4621 0.47181L1.97389 8.02077C0.657964 8.64985 0 9.90801 0 11.1662V25.9496C0 27.2077 0.657964 28.4659 1.97389 29.2522L15.2977 36.6439C16.4491 37.273 17.9295 37.273 19.2454 36.6439L28.2924 31.6113L34.5431 28.1513L43.5901 23.1187C44.7415 22.4896 46.2219 22.4896 47.5379 23.1187L54.611 27.0504C55.7624 27.6795 56.5849 28.9377 56.5849 30.3531V38.2166C56.5849 39.4748 55.9269 40.7329 54.611 41.5193L47.7024 45.451C46.5509 46.0801 45.0705 46.0801 43.7546 45.451L36.6815 41.5193C35.53 40.8902 34.7076 39.632 34.7076 38.2166V33.184L28.4569 36.6439V41.8338C28.4569 43.092 29.1149 44.3501 30.4308 45.1365L43.7546 52.5282C44.906 53.1573 46.3864 53.1573 47.7024 52.5282L61.0261 45.1365C62.1775 44.5074 63 43.2493 63 41.8338V26.8932C63 25.635 62.342 24.3769 61.0261 23.5905L47.7024 16.1988Z" fill={color}/>
  </svg>
)

export const IconTxDeposit = ({ width, height, bgColor, fgColor }) => (
  <svg width={width ? width : 32} height={height ? height : 37} viewBox="0 0 32 37" fill={bgColor} xmlns={XMLNS}>
    <g clipPath="url(#clip0_623_1670)">
      <path d="M0 9.25V27.75L16.1019 37L32.2038 27.75V9.25L16.1019 0L0 9.25Z" fill={bgColor}/>
      <path d="M8.28881 21.9863C8.14587 22.5198 8.46245 23.0681 8.99592 23.211L17.6893 25.5404C18.2227 25.6834 18.7711 25.3668 18.914 24.8333C19.0569 24.2998 18.7404 23.7515 18.2069 23.6086L10.4795 21.538L12.55 13.8106C12.693 13.2771 12.3764 12.7288 11.8429 12.5859C11.3095 12.4429 10.7611 12.7595 10.6182 13.293L8.28881 21.9863ZM21.7451 13.8791L8.75474 21.3791L9.75474 23.1111L22.7451 15.6111L21.7451 13.8791Z" fill={fgColor}/>
    </g>
    <defs>
      <clipPath id="clip0_623_1670">
        <rect width={width ? width : 32} height={height ? height : 37} fill={fgColor}/>
      </clipPath>
    </defs>
  </svg>
)

export const IconTxWithdraw = ({ width, height, bgColor, fgColor }) => (
  <svg width={width ? width : 32} height={height ? height : 37} viewBox="0 0 32 37" fill={bgColor} xmlns={XMLNS}>
    <g clipPath="url(#clip0_623_1675)">
      <path d="M0 9.25V27.75L16.1019 37L32.2038 27.75V9.25L16.1019 0L0 9.25Z" fill={bgColor}/>
      <path d="M23.2112 15.0039C23.3541 14.4705 23.0375 13.9221 22.5041 13.7792L13.8107 11.4498C13.2773 11.3069 12.7289 11.6235 12.586 12.1569C12.4431 12.6904 12.7596 13.2387 13.2931 13.3817L21.0205 15.4522L18.95 23.1796C18.807 23.7131 19.1236 24.2614 19.6571 24.4044C20.1905 24.5473 20.7389 24.2307 20.8818 23.6973L23.2112 15.0039ZM9.75488 23.1111L22.7453 15.6111L21.7453 13.8791L8.75488 21.3791L9.75488 23.1111Z" fill={fgColor}/>
    </g>
    <defs>
      <clipPath id="clip0_623_1675">
        <rect width={width ? width : 32} height={height ? height : 37} fill={fgColor}/>
      </clipPath>
    </defs>
  </svg>
)

export const IconTxTransfer = ({ width, height, bgColor, fgColor }) => (
  <svg width={width ? width : 32} height={height ? height : 37} viewBox="0 0 32 37" fill={bgColor} xmlns={XMLNS}>
    <g clipPath="url(#clip0_623_1680)">
      <path d="M0 9.25V27.75L16.1019 37L32.2038 27.75V9.25L16.1019 0L0 9.25Z" fill={bgColor}/>
      <path d="M26.7071 19.7071C27.0976 19.3166 27.0976 18.6834 26.7071 18.2929L20.3431 11.9289C19.9526 11.5384 19.3195 11.5384 18.9289 11.9289C18.5384 12.3195 18.5384 12.9526 18.9289 13.3431L24.5858 19L18.9289 24.6569C18.5384 25.0474 18.5384 25.6805 18.9289 26.0711C19.3195 26.4616 19.9526 26.4616 20.3431 26.0711L26.7071 19.7071ZM11 20H26V18H11V20Z" fill={fgColor}/>
      <path d="M5.29289 18.2929C4.90237 18.6834 4.90237 19.3166 5.29289 19.7071L11.6569 26.0711C12.0474 26.4616 12.6805 26.4616 13.0711 26.0711C13.4616 25.6805 13.4616 25.0474 13.0711 24.6569L7.41421 19L13.0711 13.3431C13.4616 12.9526 13.4616 12.3195 13.0711 11.9289C12.6805 11.5384 12.0474 11.5384 11.6569 11.9289L5.29289 18.2929ZM21 18L6 18L6 20L21 20L21 18Z" fill={fgColor}/>
    </g>
    <defs>
      <clipPath id="clip0_623_1680">
        <rect width={width ? width : 32} height={height ? height : 37} fill={fgColor}/>
      </clipPath>
    </defs>
  </svg>
)

export const NetworkLogo = ({ networkId, size }) => {
  let color = `var(--networks-${networkId}-text)`
  let width, height
  if (size === 'xs') {
    width = height = 16
  }

  switch (networkId) {
  case 'bsc':
    return <IconLogoBinance color={color} width={width} height={height} />
  case 'tron':
    return <IconLogoTron color={color} width={width} height={height} />
  case 'polygon':
    return <IconLogoPolygon color={color} width={width} height={height} />
  case 'ethereum':
    return <IconLogoEthereum color={color} width={width} height={height} />
  default:
    return <IconLogoBinance color="red" width={width} height={height }/>
  }
}
