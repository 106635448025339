import React, { createContext, useEffect, useState } from 'react'
import { themes } from '../styles/Themes'
// import { useStorageWithUserPrefs, useStorage } from '../hooks/useStorage'
import { useStorage } from '../hooks/useStorage'

const browserDarkMode = () => {
  try {
    const userMedia = window.matchMedia('(prefers-color-scheme: dark)')
    return userMedia.matches
  } catch { } // eslint-disable-line
  return false
}

const getDefaultTheme = (themeMode) => {
  const onlyValidThemes = Object.values(themes).filter(t => t.mode)
  return onlyValidThemes.find(theme => theme.mode === themeMode && theme.default) ||
    Object.values(themes).find(theme => theme.mode === themeMode) ||
    Object.values(themes).find(theme => theme.default)
}

const getSavedTheme = (themeMode) => {
  return themes['dark']

  // eslint-disable-next-line no-unreachable
  if (typeof window === 'undefined' || !window.localStorage) {return getDefaultTheme(themeMode)}

  if (themeMode === 'auto') {
    themeMode = browserDarkMode() ? 'dark' : 'light'
  }

  const savedThemeKey = window.localStorage.getItem(`color-theme-${themeMode}`)
  if (!themes[savedThemeKey]) return getDefaultTheme(themeMode)
  return themes[savedThemeKey]
}

const getDefaultThemeMode = () => {
  if (themes?.auto?.default) return 'auto'
  return getDefaultTheme().mode
}

export const ThemeContext = createContext(undefined)

function buildAllClassList() {
  const all = []
  Object.values(themes)
    .filter(theme => theme?.classList?.length)
    .forEach(theme => all.push(...theme.classList.split(' ')))
  return [...new Set(all)]
}

const getThemeKey = (theme) => (Object.entries(themes).find(([, t]) => t === theme && !t.default) || [''])[0]

export const ThemeProvider = ({ children }) => {
  // const { setItem, getItem, removeItem } = useStorageWithUserPrefs()
  const { setItem, getItem, removeItem } = useStorage('local')
  const getSavedThemeMode = () => {
    const savedThemeMode = getItem('color-theme-mode')
    if (Object.values(themes).find(t => t.mode && t.mode === savedThemeMode)) {
      return savedThemeMode
    }
    return getDefaultThemeMode()
  }

  const [themeMode, setThemeMode ] = useState(getSavedThemeMode())
  const [theme, setTheme] = useState(getSavedTheme(themeMode))
  const allClassList = buildAllClassList()

  const themeLight = getSavedTheme('light')
  const themeDark = getSavedTheme('dark')

  function rawSetTheme(th) {
    if (typeof window === 'undefined') return
    const root = window.document.documentElement
    const classList = th.classList.split(' ')

    root.classList.remove(...allClassList)
    root.classList.add(...classList)
  }

  const setThemeAndMode = (newTheme, newThemeMode) => {
    // console.log('setThemeAndMode', newTheme, newThemeMode)
    newThemeMode = newThemeMode || getSavedThemeMode()
    newTheme = newTheme || getSavedTheme(newThemeMode)
    // console.log(newTheme, newThemeMode)
    setTheme(newTheme)
    setThemeMode(newThemeMode)

    const themeKey = getThemeKey(newTheme)
    // console.log('saveTheme', theme, themeMode, `"${themeKey} ${themeMode}"`)
    setItem(`color-theme-${newTheme.mode}`, themeKey)
    setItem('color-theme-mode', newThemeMode)

    rawSetTheme(newTheme)

    if (newThemeMode === 'auto' && getSavedTheme('auto') !== newTheme) {
      const themeToRevert = getSavedTheme('auto')
      setTimeout(() => {
        rawSetTheme(themeToRevert)
        setTheme(themeToRevert)
      }, 1500)
    }
  }

  const reset = () => {
    removeItem('color-theme-light')
    removeItem('color-theme-dark')
    removeItem('color-theme-mode')
    window.location.reload()
  }

  useEffect(() => {
    rawSetTheme(theme)
  })

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeLight,
        themeDark,
        setTheme: setThemeAndMode,
        themes,
        themeMode,
        reset,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
