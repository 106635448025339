import React from 'react'
import { useLocation } from 'react-router-dom'
import { isPathWhiteListed } from '../libs/utils'
import Logo, { LogoContainer } from '../ui/Logo'
import * as Sidebar from '../ui/Sidebar'
import { IconArrow } from '../ui/Icons'
import Nav from '../ui/Nav'
import AppVersion from '../ui/AppVersion'
import useCardCollapse from '../hooks/useCardCollapse'
import { mainAppRoutes, secondaryAppRoutes } from '../AppRoutes'


const sidebarLinks = [
  {
    label: 'Twitter',
    href: 'https://twitter.com/TheHoraHub',
  },
  {
    label: 'Discord',
    href: 'https://discord.gg/horahub',
  },
  {
    label: 'Whitepaper',
    href: 'https://whitepaper.horaverse.xyz',
  },
]

export default function SidebarComponent() {
  const { collapsed, collapseToggle } = useCardCollapse('sidebar')
  const { pathname } = useLocation()

  const isRouteActive = (route) => (
    pathname === route.path ||
    pathname === route.path.replace('/*', '') ||
    (route.path.includes('/*') && pathname.startsWith(route.path.replace('/*', '')))
  )

  return (
    <Sidebar.Container $collapsed={collapsed}>
      <Sidebar.NavContainer>
        <div>
          <LogoContainer>
            <Logo tw="block" />
          </LogoContainer>

          <Sidebar.MainNav>
            {mainAppRoutes
              .filter(r => isPathWhiteListed(r))
              .map((route) => (
                <Nav.ItemXL key={route.path}>
                  <Nav.PageLinkXL
                    to={route.path.replace('/*', '')}
                    $isActive={isRouteActive(route)}
                    $collapsed={collapsed}
                  >
                    {route.icon && route.icon()}
                    <span style={collapsed ? { display: 'none' } : {}}>{route.label}</span>
                  </Nav.PageLinkXL>
                </Nav.ItemXL>
              ))}
          </Sidebar.MainNav>
        </div>

        <Sidebar.Settings style={collapsed ? { display: 'none' } : {}}>
          <Sidebar.SecondaryNav>
            {secondaryAppRoutes
              .filter(r => isPathWhiteListed(r))
              .map((route) => (
                <Nav.Item key={route.path}>
                  <Nav.PageLink
                    to={route.path}
                    $isActive={pathname.includes(route.path)}
                    $collapsed={collapsed}
                  >
                    {route.label}
                  </Nav.PageLink>
                </Nav.Item>
              ))}
            {sidebarLinks.map((link) => (
              <Nav.Item key={link.href}>
                <Nav.HrefLink href={link.href} target="__blank">
                  {link.label}
                </Nav.HrefLink>
              </Nav.Item>
            ))}
          </Sidebar.SecondaryNav>
        </Sidebar.Settings>

        <Sidebar.Collapse onClick={collapseToggle} $collapsed={collapsed}>
          <div>
            <IconArrow />
          </div>
          <span>Collapse</span>
        </Sidebar.Collapse>

      </Sidebar.NavContainer>

      <Sidebar.Copyright style={collapsed ? { display: 'none' } : {}}>
        <AppVersion />
        <br/>
        Hora Hub ©{new Date().getFullYear()}
        <br/>
        All rights reserved
      </Sidebar.Copyright>
    </Sidebar.Container>
  )
}
