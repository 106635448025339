import React from 'react'
import tw, { css, styled } from 'twin.macro'

export const ChartContainer = styled.div`
  ${tw`relative flex justify-between`}
`

export const BarContainer = styled.div`
  ${tw`flex flex-col items-center flex-1 max-w-[3rem]`}
  + div {
    ${tw`ml-1.5`}
  }
  &:hover {
    div[data-active='active'] {
      background-color: var(--chart-x-labels-active);
    }
    span {
      color: var(--chart-x-labels-active);
    }
  }
  /* 
    TODO: Ovo bi valjalo prebaciti u javscript, da renderuje sirinu
          jednog bara samo u rasponu recimo od 20-30px, da ih automatski
          ukida i dodaje u zavisnosti od sirine bas tog elementa.
   */
  @media only screen and (max-width: 460px) {
    :nth-child(-n+4) {
      display: none;
    }
  }
  @media only screen and (min-width: 460px) and (max-width: 760px)  {
    :nth-child(-n+2) {
      display: none;
    }
  }
  @media only screen and (min-width: 760px) and (max-width: 880px)  {
    :nth-child(-n+4) {
      display: none;
    }
  }
  @media only screen and (min-width: 880px) and (max-width: 1024px)  {
    :nth-child(-n+2) {
      display: none;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1240px)  {
    :nth-child(-n+6) {
      display: none;
    }
  }
  @media only screen and (min-width: 1240px) and (max-width: 1460px)  {
    :nth-child(-n+2) {
      display: none;
    }
  }
`

export const BarItem = styled.div(() => [
  tw`w-full h-[0.4375rem] rounded-full`,
  css`
    background-color: var(--chart-field);
    + div {
      ${tw`mt-1`}
    }
    &[data-active='active'] {
      background-color: var(--chart-field-filled);
    }
  `,
])

export const BarValue = styled.span`
  ${tw`absolute font-medium text-xxs -top-5`}
  color: transparent;
`

export const BarLabel = styled.span`
  ${tw`font-medium text-xxs pt-1`}
  color: var(--chart-x-labels);
`

export const YAxisContainer = styled.div`
  /* ${tw`lg:absolute h-full flex flex-col justify-between pt-1 pb-4 mr-5 lg:-ml-[4.5rem]`} */
  display: none;
`

export const YAxisLabel = styled.span`
  ${tw`font-medium text-xxs text-right`}
  color: var(--chart-y-labels);
`

export const YAxis = ({ labels }) => (
  <YAxisContainer>
    {labels.map((l, i) => <YAxisLabel key={i}>{l}</YAxisLabel>)}
  </YAxisContainer>
)

export const Bar = ({ label, value, count, countActive }) => (
  <BarContainer>
    <BarValue>
      {value}
    </BarValue>
    {[...Array(count)].map((e, i) => <BarItem key={i} data-active={count - i <= countActive ? 'active' : null}/>)}
    <BarLabel>
      {label}
    </BarLabel>
  </BarContainer>
)

export const ChartBars = ({ yAxisLabels, bars }) => (
  <ChartContainer>
    <YAxis labels={yAxisLabels} />
    {bars.map((bar, i) => <Bar key={i} {...bar}/>)}
  </ChartContainer>
)
