import React from 'react'
import { NetworkLogo } from '../ui/Icons'
import { TokenLogoImage } from '../ui/Images'
import Table, { AssetsTableStyles } from '../ui/Table'
import ProgressBar from '../ui/ProgressBar'
import { useGetPortfolio } from '../hooks/useHoraHub'


const PriceAction = ({ percentage }) => {
  if (percentage >= 0) {
    return <div style={{ color: 'green' }}>+{percentage.toFixed(2)}%</div>
  }
  return <div style={{ color: 'red' }}>-{percentage.toFixed(2)}%</div>
}

function AssetsTable({ fiatCurrency, addresses, networks, tokens }) {

  const priceFormatter = React.useMemo(() => (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: fiatCurrency,

      // minimumFractionDigits: 0,
      // maximumFractionDigits: 0,
    })
  ), [fiatCurrency])

  const generateColumns = () => {
    const AssetRender = ({ row }) => {
      const { logoUrl, network, symbol, name } = row.original
      return (
        <>
          <NetworkLogo networkId={network} size="xs" />
          <TokenLogoImage src={logoUrl} />
          <div>{symbol}</div>
          <div>{name}</div>
        </>
      )
    }

    const PriceRender = ({ row }) => {
      const { price, change24 } = row.original
      return (
        <>
          <div>{priceFormatter.format(price)}</div>
          <PriceAction percentage={change24} />
        </>
      )
    }

    const AllocRender = ({ row }) => {
      const { alloc } = row.original
      return <ProgressBar bgcolor="green" completed={alloc} />
    }

    const BalanceRender = ({ row }) => {
      const { balance, value, symbol } = row.original
      return (
        <>
          <div>{priceFormatter.format(balance)}</div>
          <div>{value} {symbol}</div>
        </>
      )
    }

    return [
      {
        Header: 'Asset',
        accessor: 'asset',
        id: 'asset',
        Cell: AssetRender,
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: PriceRender,
      },
      {
        Header: 'Allocation',
        accessor: 'alloc',
        Cell: AllocRender,
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        Cell: BalanceRender,
      },
      {
        Header: '',
        id: 'actions',
      },
    ]
  }


  const columns = React.useMemo(
    generateColumns,
    [priceFormatter]
  )

  // const data = React.useMemo(() => [
  //   {
  //     network: 'ethereum',
  //     symbol: 'ETH',
  //     name: 'Ether',
  //     logoUrl: 'https://token.metaswap.codefi.network/assets/nativeCurrencyLogos/ethereum.svg',
  //     price: 16000,
  //     alloc: 75,
  //     decimals: 18,
  //     balance: '0.5',
  //     value: 10000,
  //     change24: 1.35,
  //   },
  // ], [])

  const { data } = useGetPortfolio({ addresses, networks, tokens, fiatCurrency })

  if (!data || !data.length) {
    return <div>no data?</div>
  }

  return (
    <AssetsTableStyles>
      <Table columns={columns} data={data} />
    </AssetsTableStyles>
  )
}

export default AssetsTable
