import React from 'react'
import styled, { keyframes } from 'styled-components'
import tw from 'twin.macro'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Overlay = styled.div`
  ${tw`absolute w-full h-full top-0 left-0 flex justify-center items-center`};
`

const Spinner = styled.div`
  ${tw`w-8 h-8 border-4 border-solid border-accent border-b-transparent rounded-full`}
  animation: ${rotate} 1000ms linear infinite;
`
const InnerContainer = styled.div`
  ${tw`w-full h-full relative flex flex-col`}
  ${(props) => (props.justifyBetween && tw`justify-between`)};
`

const Container = styled.div`
  ${tw`w-full h-full relative`}

  ${Spinner} {
    /* ${tw`absolute inset-x-1/2 inset-y-1/2 -ml-4 -mt-4`}; */
  }

  ${InnerContainer} {
    ${(props) => (props.isLoading ? tw`opacity-20` : tw`opacity-100`)};
  }
`

export function LoadingContainer({ isLoading, children, justifyBetween }) {
  return (
    <Container isLoading={isLoading}>
      <InnerContainer justifyBetween={justifyBetween}>{children}</InnerContainer>
      {isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
    </Container>
  )
}

export function LoaderFullScreen() {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  )
}

export function Loader() {
  return <Spinner />
}
