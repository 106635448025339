import React from 'react'
import { CenteredContent } from '../layouts/Layout'

export function Staking() {
  return (
    <CenteredContent>
      Staking
    </CenteredContent>
  )
}
