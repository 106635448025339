import React from 'react'
import { CenteredContent } from '../layouts/Layout'
import ThemeSelector from '../components/ThemeSelector'

export function Settings() {
  return (
    <CenteredContent>
      <ThemeSelector />
    </CenteredContent>
  )
}

