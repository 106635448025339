import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { IconArrowDropdown } from './Icons'

const ListBox = styled.div`
  ${tw`relative border-b-[2px] pb-2 mb-6`}
  border-color: var(--modal-form-line);
  opacity: ${props => props.disabled && '0.4'};
`

const Label = styled.span`
  ${tw`block font-medium mb-1`}
  color: var(--modal-form-label);
`

const ItemContainer = styled.div`
  ${tw`flex items-center py-1`}
  cursor: ${props => props.onClick && !props.disabled && 'pointer'};
  span {
    color: ${props => props.isPlaceholder && !props.disabled && 'var(--modal-form-placeholder)'};
  }
  svg {
    transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`

const ValuesContainer = styled.div`
  ${tw`w-full flex justify-between text-2xl`}
`

const Value = styled.span`
  ${tw`font-medium`}
  color: var(--modal-form-value);
  opacity: ${props => props.disabled ? '0.4;' : '1;'} 
`

const Value2 = styled.span`
  ${tw``}
  color: var(--modal-form-value2);
`

const ConnectBtn = styled.button`
  color: var(--modal-form-value2);
`

const Description = styled.div`
  ${tw`font-medium text-xs`}
  color: var(--modal-form-value);
`

const IconContainer = styled.div`
  ${tw`w-10 py-2 flex justify-center`}
`

const MenuContainer = styled.ul`
  ${tw`absolute block w-full z-50 border-b-2 pb-2`}
  /* ${tw`min-h-[6.4rem]`} */
  background-color: var(--modal-bg);
  border-color: var(--modal-form-line);
  box-shadow: 0px 60px 0 50px var(--color-bg-primary);
`

const OptionsContainer = styled.div`
  ${tw`flex-1 flex flex-col`}
`

const Item = ({ onClick, disabled, open, children, isPlaceholder }) => (
  <ItemContainer
    isPlaceholder={isPlaceholder}
    disabled={disabled}
    open={open}
    onClick={disabled ? undefined : onClick}
  >
    {children}
    <IconContainer>
      <IconArrowDropdown color={css`var(--modal-form-arrow)`}/>
    </IconContainer>
  </ItemContainer>
)

const Menu = ({ children, bgColor, borderColor }) => (
  <MenuContainer style={{ backgroundColor: bgColor, borderColor: borderColor }}>
    {children}
  </MenuContainer>
)

const MenuItem = ({ onClick, children }) => (
  <ItemContainer onClick={onClick}>
    {children}
    <IconContainer>

    </IconContainer>
  </ItemContainer>
)

const Options = ({ option, placeHolder }) => (
  <OptionsContainer>
    <ValuesContainer>
      <Value disabled={option?.disabled}>{option?.value || placeHolder}</Value>
      {option?.value2 ? <Value2>{option?.value2}</Value2> : null}
      {option?.buttonText ? <ConnectBtn onClick={option.onButtonClick}>{option.buttonText}</ConnectBtn> : null}
    </ValuesContainer>
    {option?.description ? <Description>{option.description}</Description> : null}
  </OptionsContainer>
)

export const ItemButton = ({ button }) => (
  <div onClick={button.onClick}>{button.title}</div>
)

ListBox.Label = Label
ListBox.Item = Item
ListBox.Menu = Menu
ListBox.MenuItem = MenuItem
ListBox.Options = Options
ListBox.Value = Value
ListBox.Value2 = Value2
ListBox.Description = Description
ListBox.ItemButton = ItemButton

export default ListBox
