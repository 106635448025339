import React from 'react'
import tw, { styled } from 'twin.macro'
// import { P } from './Typography'

const Container = styled.form`
  ${tw`shadow-lg rounded-md w-full`};
`

const Input = styled.input`
  ${tw`block w-full h-14 px-4 border-2 font-bold rounded-[2px] ease-in-out duration-300`};
  /* background-color: var(--modal-form-input); */
  background-color: transparent;
  border-color: var(--modal-form-input-border);
  color: var(--modal-form-input-text);
  /* color: var(--modal-form-input-border-active); */
  &::placeholder {
    color: var(--modal-form-input-text-ph);
  }
  &:focus {
    outline: none;
    border-color: var(--modal-form-input-border-active);
  }
`

const NumInputContainer = styled.div`
  ${tw`flex justify-between`}
`

const NumInput = styled.input`
  /* ${tw`inline-block w-12 h-12 p-2 border border-solid border-gray-500 rounded-md mx-2`}; */
  ${tw`inline-block w-11 h-11 border-2 text-xl text-center font-bold rounded-[2px] ease-in-out duration-300`};
  
  background-color: transparent;
  border-color: var(--modal-form-input-border);
  color: var(--modal-form-input-text-accent);
  /* color: var(--modal-form-input-border-active); */
  &::placeholder {
    color: var(--modal-form-input-text-ph);
  }
  &:focus {
    outline: none;
    border-color: var(--modal-form-input-border-active);
  }
`

const Label = styled.label`
  ${tw`block text-lg`}
  color: var(--modal-label-accent);
  ${(props) => {
    switch (props.mb) {
    case 'lg':
      return tw`mb-[1.125rem]`
    case 'xl':
      return tw`mb-5`
    case '2xl':
      return tw`mb-6`
    default:
      return tw`mb-1`
    }
  }}
`

const StyledMessage = styled.p`
  ${tw`block py-2 font-medium`}
  ${(props) => {
    switch (props.type) {
    case 'success':
      return tw`text-green-700`
    case 'warning':
      return tw`text-yellow-700`
    case 'error':
      return tw`text-red-700`
    default:
      return tw`text-black`
    }
  }}
`

const Message = ({ type, error = null }) => {
  if (!error) return null

  return <StyledMessage type={type}>Test message!</StyledMessage>
}

const Select = styled.select`
  ${tw`block w-full p-2 border border-solid border-gray-500 rounded-md`};
`

const Form = {
  Container,
  Input,
  NumInputContainer,
  NumInput,
  StyledMessage,
  Label,
  Message,
  Select,
}

export default Form
