import React, { useState } from 'react'
import { ModalLayout, FinishPage, DepositProcedure, Step1Page } from '../components/DepositAndWithdrawForms'
import { DEPOSIT_MODE } from '../libs/constants'


export function Deposit({ params }) {
  let defaults = {
    step: params.step || 1,
    step1State: params.step1State || { currency: null, network: null, amount: '0', numAmount: 0 },
    paymentId: params.paymentId,
  }

  const [step, setStep] = useState(defaults.step)
  const [step1State, setStep1State] = useState(defaults.step1State)
  const [loading, setLoading] = useState(false)
  const [paymentId, setPaymentId] = useState(defaults.paymentId)

  let stepContent
  switch (step) {
  case 2:
    stepContent = <DepositProcedure
      step1State={step1State}
      setLoading={setLoading}
      onFinished={(pId) => {setPaymentId(pId); setStep(3)}}
      paymentId={paymentId}
      setPaymentId={setPaymentId}
    />
    break
  case 3:
    stepContent = <FinishPage mode={DEPOSIT_MODE} paymentId={paymentId} />
    break
  default:
    stepContent = <Step1Page
      mode={DEPOSIT_MODE}
      step1State={step1State}
      setStep1State={setStep1State}
      setStep={setStep}
    />
    break
  }

  return (
    <ModalLayout isLoading={loading} mode={DEPOSIT_MODE} step={step}>
      {stepContent}
    </ModalLayout>
  )
}

