import React from 'react'
import { useGetUserModel } from '../hooks/useHoraHub'
import { Content } from '../layouts/Layout'
import { PageMasking, PageMaskingTypes } from '../layouts/PageMasking'
import Select from '../components/Select'
import tw, { styled } from 'twin.macro'
import Card from '../ui/Card'
import AssetsTable from '../components/AssetsTable'

const PageTitle = styled.span`
  color: var(--main-text-welcome);
  ${tw`
    block
    text-xl font-medium
    mt-6 mb-16
    // border border-green-500
  `}
`

const HeadingRow = styled.div`
  
`
const noop = () => {} // eslint-disable-line

const NetWorth = styled.div`
`
export function Portfolio() {
  const user = useGetUserModel()

  const netWorth = '$1234'

  const importedTokens = [] // ...

  return (
    <PageMasking masking={user.id ? '' : PageMaskingTypes.NO_LOGIN}>
      <Content>
        <HeadingRow>
          <PageTitle>Portfolio</PageTitle>
          <Select label="My Accounts" placeHolder="A" value="" onChange={noop} options={[]} />
          <Select label="Networks" placeHolder="A" value="" onChange={noop} options={[]} />
          <Select label="Currency" placeHolder="A" value="" onChange={noop} options={[]} />
        </HeadingRow>
        <NetWorth>Net Worth {netWorth}</NetWorth>
        <Card.Container>
          <Card.Header>
            <Card.HeaderSection>
              <Card.HeaderTitle>Assets</Card.HeaderTitle>
            </Card.HeaderSection>
            <Card.HeaderSection>
              <Card.HeaderButton onClick={noop}>Import token</Card.HeaderButton>
              <Card.HeaderButton onClick={noop}>Download balances</Card.HeaderButton>
              <Card.HeaderButton onClick={noop}>Tokens</Card.HeaderButton>
              <Card.HeaderButton onClick={noop}>NFTs</Card.HeaderButton>
              <Card.HeaderButton onClick={noop}>Transactions</Card.HeaderButton>
            </Card.HeaderSection>
          </Card.Header>
          <Card.Content justifyContent="space-between" bgColor="var(--balance-bg)" borderColor="var(--balance-bg)">
            <AssetsTable
              fiatCurrency="USD"
              addresses={['']}
              networks={['bsc', 'ethereum', 'tron', 'polygon']}
              tokens={importedTokens}
            />
          </Card.Content>
        </Card.Container>
      </Content>
    </PageMasking>
  )
}
