import tw, { styled } from 'twin.macro'

export const Container = styled.div`
  ${tw`h-full hidden md:flex flex-col justify-between`}
  ${(props) => (props.$collapsed ? '' : tw`px-10`)}
`

export const NavContainer = styled.div`
  color: var(--sidebar-text);
  ${tw`min-h-full hsm:min-h-[85%] flex flex-col justify-between font-semibold`}

  svg {
    ${tw`fill-current`}
  }
`

export const MainNav = styled.ul`
  ${tw`flex items-center justify-around mt-[2.8rem] md:block md:items-start`}
  @media only screen and (min-height: 800px) {
    margin-top: 9.8rem;
  }
`

export const Settings = styled.div`
  ${tw`hidden md:block text-sm`};
`

export const SecondaryNav = styled.ul`
  ${tw`px-2 hidden`}
  @media only screen and (min-height: 640px) {
    display: block;
  }
`

export const Collapse = styled.div`
  ${tw`flex items-center cursor-pointer ml-0.5 select-none`};
  ${(props) => (props.$collapsed ? 'justify-content: center; margin: 0; margin-bottom: 0.2rem;' : '')}
  div {
    ${(props) => (props.$collapsed ? 'transform: rotate(180deg) translateY(2px);' : '')}
    transition: all 0.2s ease-in-out;
  }
  span {
    ${tw`inline-block ml-3`};
    ${(props) => (props.$collapsed ? 'display: none;' : '')}
    transition: all 0.2s ease-in-out;
  }
  :hover {
    color: var(--sidebar-text-active);
  }
`


export const Copyright = styled.span`
  color: var(--sidebar-text);
  ${tw`hidden hsm:md:block text-xxs font-medium py-5`};
`
