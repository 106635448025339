import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { TabbableButton } from './Button'
import { UiContext } from '../contexts/UiContext'

export const TOAST_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ALERT: 'alert',
}

const ToastContainer = styled.div`
  ${tw`w-full px-4 py-2 fixed top-0 left-0 transition-transform duration-200 text-center`};
  ${(props) => {
    switch (props.type) {
    case TOAST_TYPES.SUCCESS:
      return tw`bg-success`
    case TOAST_TYPES.WARNING:
      return tw`bg-warning`
    case TOAST_TYPES.ALERT:
      return tw`bg-alert`
    }
  }};

  transform: translateY(${(props) => (props.visible ? '0' : '-300%')});
  z-index: 1200;
`

const CloseButton = styled(TabbableButton)`
  ${tw`absolute top-2 right-4`};
`

export function Toast() {
  const { toast, setToast } = useContext(UiContext)

  useEffect(() => {
    if (!toast) {
      return
    }

    let timer = null

    if (!toast.preserve) {
      timer = setTimeout(() => {
        setToast(null)
      }, 3000)
    }

    return () => clearTimeout(timer)
  // eslint-disable-next-line
  }, [toast])

  return (
    <>
      <ToastContainer visible={toast?.type} type={toast?.type}>
        {toast?.message}
        <CloseButton onClick={() => setToast(null)}>
          <FontAwesomeIcon icon={faXmark} size="xl" />
        </CloseButton>
      </ToastContainer>
    </>
  )
}
