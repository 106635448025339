import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  .stack-layout--index-1.stack-layout--count-2 {
    right: 0;
  }

  .stack-layout--index-1.stack-layout--count-3 {
    left: 15%;
  }

  .stack-layout--index-2.stack-layout--count-3 {
    left: 30%;
  }

  .stack-layout--index-1.stack-layout--count-4 {
    left: 10%;
  }

  .stack-layout--index-2.stack-layout--count-4 {
    left: 20%;
  }

  .stack-layout--index-3.stack-layout--count-4 {
    left: 30%;
  }
  
  .slideLayout {
    transition: all 0.2s ease-in-out;
  }
  
  .stack-layout--non-selected {
    &:hover {
      transform: translateY(-14px);
    }
  }

  .slideLayout.stack-layout--selected {
    z-index: 10;
  }

  .slideLayout.stack-layout--index-0.stack-layout--count-3 {
    z-index: 3;
  }

  .slideLayout.stack-layout--index-1.stack-layout--count-3 {
    z-index: 2;
  }

  .slideLayout.stack-layout--index-1.stack-layout--selected-index-2.stack-layout--count-3 {
    z-index: 4;
  }

  .slideLayout.stack-layout--index-1.stack-layout--selected.stack-layout--count-3 {
    z-index: 4;
  }

  .slideLayout.stack-layout--index-2.stack-layout--count-3 {
    z-index: 1;
  }

  .slideLayout.stack-layout--index-2.stack-layout--selected.stack-layout--count-3 {
    z-index: 4;
  }



  .slideLayout.stack-layout--index-0.stack-layout--count-4 {
    z-index: 4;
  }

  .slideLayout.stack-layout--index-1.stack-layout--count-4 {
    z-index: 3;
  }

  .slideLayout.stack-layout--index-1.stack-layout--selected.stack-layout--count-4 {
    z-index: 5;
  }

  .slideLayout.stack-layout--index-1.stack-layout--selected-index-2.stack-layout--count-4,
  .slideLayout.stack-layout--index-1.stack-layout--selected-index-3.stack-layout--count-4 {
    z-index: 4;
  }
  
  .slideLayout.stack-layout--index-2.stack-layout--count-4 {
    z-index: 2;
  }

  .slideLayout.stack-layout--index-2.stack-layout--selected.stack-layout--count-4,
  .slideLayout.stack-layout--index-2.stack-layout--selected-index-3.stack-layout--count-4 {
    z-index: 5;
  }

  .slideLayout.stack-layout--index-3.stack-layout--count-4 {
    z-index: 1;
  }

  .slideLayout.stack-layout--index-3.stack-layout--selected.stack-layout--count-4 {
    z-index: 6;
  }
  
  .slideFirstLayout {
    
  }
  
  .slideFirstLayout.stack-layout--index-0 {
    z-index: 9;
  }

  .slideFirstLayout.stack-layout--index-1 {
    z-index: 8;
  }

  .slideFirstLayout.stack-layout--index-2 {
    z-index: 7;
  }

  .slideFirstLayout.stack-layout--index-3 {
    z-index: 6;
  }

  .slideFirstLayout.stack-layout--index-4 {
    z-index: 5;
  }
`

