import React, { useRef, useState, useEffect } from 'react'
import ListBox from '../ui/ListBox'
import ListScrollable from '../ui/ListScrollable'
import { useOutsideClick } from '../hooks/useDomEvents'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { currencies } from '../libs/constants'
import { useGetUserModel, useUpdateWalletBalances } from '../hooks/useHoraHub'
import { AddNetworksPopup } from './Popups'
import { shortenAddress } from '../libs/utils'

function ListScrollableSelect({ label, value, onChange, options, listButtons, disabled }) {
  const selectedOption = value && options?.length && options.find(({ id }) => id === value)
  if (!options?.length && !listButtons?.length) {
    disabled = true
  }

  return (
    <ListScrollable.Container className="list-box-select" disabled={disabled}>
      <ListScrollable.Label>{label}</ListScrollable.Label>
      <ListScrollable.List>
        {options.map((option) => (
          <ListScrollable.ListItem
            key={option.id}
            onClick={option.disabled ? null : () => { onChange(option.id)}}
            option={option}
            selected={selectedOption === option}
          />
        ))}
      </ListScrollable.List>
    </ListScrollable.Container>
  )
}

function StandardSelect({ label, value, onChange, options, placeHolder, listButtons, disabled }) {
  const [ listOpen, setListOpen ] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setListOpen(false))

  const selectedOption = value && options?.length && options.find(({ id }) => id === value)
  if (!options?.length && !listButtons?.length) {
    disabled = true
  }

  return (
    <ListBox className="standard-select" disabled={disabled} ref={wrapperRef}>
      <ListBox.Label>{label}</ListBox.Label>
      { listOpen ?
        <>
          <ListBox.Item open isPlaceholder={!selectedOption?.value} onClick={() => setListOpen(false)}>
            <ListBox.Options option={selectedOption} placeHolder={placeHolder} />
          </ListBox.Item>

          <ListBox.Menu>
            {options.filter(option => selectedOption !== option).map((option) => (
              <ListBox.MenuItem
                key={option.id}
                onClick={option.disabled ? null : () => { setListOpen(false); onChange(option.id)}}
              >
                <ListBox.Options option={option} />
              </ListBox.MenuItem>
            ))}
            {listButtons ? listButtons.map((btn, index) => <ListBox.ItemButton key={index} button={btn} />) : null}
          </ListBox.Menu>
        </>
        :
        <ListBox.Item isPlaceholder={!selectedOption?.value} disabled={disabled} onClick={() => setListOpen(true)}>
          <ListBox.Options option={selectedOption} placeHolder={placeHolder} />
        </ListBox.Item>
      }
    </ListBox>
  )
}

export default function Select(props) {
  if (!props.listScrollable) {
    return StandardSelect(props)
  }
  return ListScrollableSelect(props)
}

export function SelectToken({ label, value, onChange }) {
  const { getTokenConfig } = useWeb3Service()
  const user = useGetUserModel()

  const tokens = Object.values(currencies)
    .map(symbol => getTokenConfig(symbol))
    .filter(tokenConfig => tokenConfig)
    .map(({ name, symbol }) => (
      {
        id: symbol,
        value: name,
        value2: user.getTokenAmount(symbol, { withdrawable: true, format: true }),
      })
    )

  return (
    <Select label={label} placeHolder="Token" value={value} onChange={onChange} options={tokens} />
  )
}


export function SelectNetwork({ label, value, onChange, currency, listBox }) {
  const [addNetworksPopupActive, setAddNetworksPopupActive] = useState('')
  const { eachNetwork, getTokenConfig } = useWeb3Service()
  const user = useGetUserModel()
  const updateWalletBalances = useUpdateWalletBalances()

  useEffect(() => {
    updateWalletBalances().catch(console.error)
  }, [currency, addNetworksPopupActive]) // eslint-disable-line

  let networks = []
  if (currency) {
    const mapNetworkService = (networkService) => {
      const networkId = networkService.network
      const networkName = networkService.networkName
      const walletAddress = user.getWalletAddress(networkService.network, currency)
      const tokenAmount = user.getTokenAmount(currency, { network: networkService.network, format: true })

      return {
        id: networkId,
        value: networkName,
        value2: walletAddress ? tokenAmount : '',
        description: walletAddress ? shortenAddress(walletAddress) : 'not connected',
        buttonText: walletAddress ? '' : '+add',
        onButtonClick: walletAddress ? null : () => setAddNetworksPopupActive(networkId),
      }
    }

    const sortFn = (a, b) => {
      const a1 = !!a.buttonText
      const b1 = !!b.buttonText
      if (a1 && !b1) return 1
      if (!a1 && b1) return -1
      return 0
    }

    networks = eachNetwork()
      .filter(networkService => getTokenConfig(currency, networkService.network))
      .map(mapNetworkService)
      .sort(sortFn)
  }

  const checkNetworkChange = (network) => {
    if (currency && user.getWalletAddress(network, currency)) return onChange(network)
    onChange('')
  }

  return (
    <>
      <Select
        label={label}
        placeHolder='Network'
        value={value}
        onChange={checkNetworkChange}
        options={networks}
        disabled={!currency}
        listBox={listBox}
      />
      {
        addNetworksPopupActive ?
          <AddNetworksPopup
            isOpen={true}
            select={addNetworksPopupActive}
            onClose={() => setAddNetworksPopupActive('')}
            showConnected={false}
            allowDelete={false}
            closeOnAdd={true}
          />
          : null
      }
    </>
  )
}

export function SelectWithdrawMethod({ label, value, onChange, currency, network, listBox }) {
  const user = useGetUserModel()
  const { getWithdrawMethods } = useWeb3Service()

  const methods = getWithdrawMethods(network, currency)

  let free = methods.find(method => method.free && method.max !== -1)
  let paid = methods.find(method => !method.free)

  const options = []
  let methodId
  if (free) {
    options.push({
      id: free.method,
      value: `Free withdraw (x${user.freeWithdrawsLeft})`,
      disabled: user.freeWithdrawsLeft <= 0,
    })
    if (value?.method === free.method) methodId = free.method
  }
  if (paid) {
    options.push({ id: paid.method, value: 'Pay for transaction', disabled: false })
    if (value?.method === paid.method) methodId = paid.method
  }

  const handleOnChange = (newValue) => {
    onChange(methods.find(m => m.method === newValue))
  }

  return (
    <Select
      label={label}
      placeHolder="Withdraw method"
      value={methodId}
      onChange={handleOnChange}
      options={options}
      listBox={listBox}
    />
  )
}
