import React, { useContext } from 'react'
import { formatCurrency } from '../libs/utils'
import useToast from '../hooks/useToast'
import { TaskItem, EnterButton, StatusText } from './Tasks'
import { ItemsList, ItemsListCard, Item, ItemSingleTask, Page } from '../ui/Quests'
import { useGetQuestsItemState, useQuestsClaim } from '../hooks/useHoraHub'
import { LoadingContainer } from '../ui/Loader'
import { Button2 } from '../ui/Button'
import { ConfigContext } from '../contexts/ConfigReloader'
import { LaunchpadItemStates } from '../libs/constants'
import { useNavigate } from 'react-router-dom'
import { PageMasking } from '../layouts/PageMasking'
import tw from 'twin.macro' // eslint-disable-line

const QuestItem = ({ id, name, by, image, state, reward, task }) => {
  const navigate = useNavigate()
  const path = `/quests/${id}`

  const enter = () => {
    navigate(path)
  }

  if (task) {
    return (
      <ItemSingleTask.Container onClick={enter}>
        <ItemSingleTask.Icon src={image}/>
        <ItemSingleTask.TextContent1>
          <ItemSingleTask.Name>{name}</ItemSingleTask.Name>
          <ItemSingleTask.By>{by}</ItemSingleTask.By>
        </ItemSingleTask.TextContent1>
        <ItemSingleTask.TextContent2>
          <ItemSingleTask.Amount>
            {formatCurrency(reward.currency, reward.amount, 1)} {reward.currency}
          </ItemSingleTask.Amount>
          <ItemSingleTask.StatusText>
            {reward.raffle ? `${reward.raffle} winners raffle - ` : ''}{'not started'}
          </ItemSingleTask.StatusText>
        </ItemSingleTask.TextContent2>
        <ItemSingleTask.IconEnter />
      </ItemSingleTask.Container>
    )
  }
  return (
    <Item.Container>
      <Item.Image src={image} />
      <Item.TextLayer>
        <Item.Name>{name}</Item.Name>
        <Item.By>{by}</Item.By>
        <Item.Amount>{formatCurrency(reward.currency, reward.amount, 1)}</Item.Amount>
        <Item.Currency>{reward.currency} {reward.raffle ? `${reward.raffle} winners raffle` : ''}</Item.Currency>
        <EnterButton id={id} state={state} path={path} />
      </Item.TextLayer>
    </Item.Container>
  )
}

const QuestsItems = () => {
  const config = useContext(ConfigContext)

  if (!config?.quests?.items?.length) {
    // Handle this: There are no quest items
    return (<PageMasking.Message tw="relative py-[8.45rem]">
      <PageMasking.Text>
        Quests will be available soon.
      </PageMasking.Text>
    </PageMasking.Message>)
  }

  const all = config.quests.items

  const campaigns = all
    .filter(data => data.state === LaunchpadItemStates.SELECTION && !data.task)
    .map(data => <QuestItem key={data.id} {...data}/>)

  const tasks = all
    .filter(data => data.state === LaunchpadItemStates.SELECTION && data.task)
    .map(data => <QuestItem key={data.id} {...data}/>)

  return <>
    <ItemsListCard title="Campaigns" cardKey="questsCampaigns">
      <ItemsList>
        {campaigns}
      </ItemsList>
    </ItemsListCard>
    <ItemsListCard title="Tasks" cardKey="questsTasks">
      {tasks}
    </ItemsListCard>
  </>
}

export default QuestsItems


export const QuestsItemPage = ({ id }) => {
  const config = useContext(ConfigContext)
  const { toastError } = useToast()
  let item = config?.quests?.items?.length && config.quests.items.find(i => i.id === id)
  const { data, isLoading, refetch, isFetching } = useGetQuestsItemState(item && item.id)
  const claimQuest = useQuestsClaim()

  if (!item) {
    return <div>Handle: item not found a href = back to quests...</div>
  }
  if (data && data.item) {
    item = data.item
  }

  const claim = () => {
    console.log('claim btn', data)
    claimQuest(data.item.id)
      .then((result) => {
        console.log('state (claim)', result)
      })
      .catch(toastError)
  }

  const { name, description, image, state } = item

  return <Page.Container>
    <div>quest item page id: {id}</div>
    <Item.Image src={image}/>
    <StatusText state={state} />
    <Item.Name>{name}</Item.Name>
    <Item.Description>{description}</Item.Description>
    <Page.TaskList>
      <LoadingContainer isLoading={!data || isLoading || isFetching}>
        {item.tasks.map(task => <TaskItem key={task.id} item={item} task={task} itemState={data} />)}
        <Button2 onClick={refetch}>Refresh button</Button2>
        {(data && data.canClaim && <Button2 onClick={claim}>Claim button</Button2>) || null}
      </LoadingContainer>
    </Page.TaskList>
  </Page.Container>
}
