import React from 'react'
import { Outlet } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

import { device } from '../libs/constants'


export const LayoutDashboard = styled.div`
  ${tw`flex flex-col-reverse w-full`};
  height: 100vh;
  height: 100cqh;

  @media ${device.tablet} {
    ${tw`flex flex-row`};
  }
`

export const LayoutSidebar = styled.aside`
  background-color: var(--sidebar-bg);
  ${tw`md:overflow-y-scroll`};
  
  /* @media ${device.tablet} {
    ${tw`h-full w-[16.5rem]`};
  } */
`

export const LayoutMain = styled.div`
  background-color: var(--main-bg);
  ${tw`h-[calc(100% - 68px)] md:h-full flex flex-col flex-1`};
`

export const ContainerScrollable = styled.div`
  ${tw`
    h-full
    max-h-full
    pt-2 md:pt-4
    pb-10 md:pb-24
    overflow-y-scroll
  `};
`

export const Content = styled.div(({ isFullWidth }) => [
  tw`
    max-w-[100rem]
    px-4 md:pl-8 md:pr-8 xl:pl-16 xl:pr-16 2xl:pl-28 2xl:pr-28
    ease-in-out duration-300
    // border-2 border-green-500
  `,
  isFullWidth && tw`max-w-full 3xl:pr-6`
])


export const Container = styled.div`
  ${tw`p-2 md:p-4 mx-auto`};
  ${tw`border border-blue-400`}
`

export const CenteredContent = styled.div`
  ${tw`w-full h-screen flex items-center justify-center`};
`

export const LoginLayout = styled(CenteredContent)`
  height: calc(100vh - 79px);
`

export function LoginPageLayout({ children }) {
  return <LoginLayout>{children}</LoginLayout>
}

export function Spacer({ h }) {
  return <div style={{ height: h ? `${h}rem` : '1rem' }} />
}

export function PageLayout() {
  return (
    <LoginLayout>
      <Outlet />
    </LoginLayout>
  )
}

export const Layout = {
  Container,
  LoginPageLayout,
  Spacer,
}
