import React, { useContext } from 'react'
import { ERCS, LaunchpadItemStates } from '../libs/constants'
import { delay } from '../libs/utils'
import useToast from '../hooks/useToast'
import { ConfigContext } from '../contexts/ConfigReloader'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { useGetLaunchpadItemState, useLaunchpadMint } from '../hooks/useHoraHub'
import { TaskItem, EnterButton, StatusText } from './Tasks'
import { ItemsList, ItemsListCard, Item, Page } from '../ui/Launchpad'
import { LoadingContainer } from '../ui/Loader'
import { Button2 } from '../ui/Button'
import { PageMasking } from '../layouts/PageMasking'
import tw from 'twin.macro' // eslint-disable-line


const isHoraPay = tasks => tasks.find(task => task.id === 'hora-pay')

const ContractData = ({ data }) => {
  if (!data || !data.contractData) {
    return <div>Price is no available at the moment...</div>
  }
  // console.log(data.contractData)
  let { priceValue, symbol } = data.contractData

  const horaPay = isHoraPay(data.tasks)
  if (horaPay) {
    priceValue = horaPay.price
    symbol = horaPay.currency
  }
  return <div><b>Price {priceValue} {symbol}</b></div>
}

const Counters = ({ data }) => {
  if (!data) {
    return null
  }
  console.log(data)

  let totalSupply = ''
  if (data.item.totalSupply > 0) {
    totalSupply = `of ${data.item.totalSupply}`
  }
  return <div><b>Total Minted {data.totalMintedAll} {totalSupply}</b></div>
}

const LaunchpadItem = ({ id, name, by, when, what, description, image, state }) => (
  <Item.Container>
    <Item.Image src={image}/>
    <Item.TextLayer>
      <Item.Name>{name}</Item.Name>
      <Item.By>{by}</Item.By>
      <Item.When>{when}</Item.When>
      <Item.What>{what}</Item.What>

      <StatusText state={state} />
      <Item.Description>{description}</Item.Description>
      <EnterButton id={id} state={state} path={`/launchpad/${id}`} />
    </Item.TextLayer>
  </Item.Container>
)

const LaunchpadItems = () => {
  const config = useContext(ConfigContext)

  if (!config?.launchpad?.items?.length) {
    // return <div>Handle this: There are no launchpad items</div>
    return (<PageMasking.Message tw="relative py-[8.45rem]">
      <PageMasking.Text>
        Launchpad will be available soon.
      </PageMasking.Text>
    </PageMasking.Message>)
  }

  const all = config.launchpad.items

  const listActiveAndUpcoming = all
    .filter(data => data.state === LaunchpadItemStates.SELECTION || data.state === LaunchpadItemStates.REGISTRATION)
    .map(data => <LaunchpadItem key={data.id} {...data}/>)

  const listPast = all
    .filter(data => data.state === LaunchpadItemStates.ENDED)
    .map(data => <LaunchpadItem key={data.id} {...data}/>)

  return <>
    <ItemsList>
      {listActiveAndUpcoming}
    </ItemsList>
    <ItemsListCard title="Past" cardKey="launchpadCardPast">
      <ItemsList>
        {listPast}
      </ItemsList>
    </ItemsListCard>
  </>
}

export default LaunchpadItems

export const LaunchpadItemPage = ({ id }) => {
  const config = useContext(ConfigContext)
  const { toastError } = useToast()
  let item = config?.launchpad?.items?.length && config.launchpad.items.find(i => i.id === id)
  const { data, isLoading, refetch, isFetching } = useGetLaunchpadItemState(item && item.id)
  const launchpadMint = useLaunchpadMint()
  const { connect, getContract } = useWeb3Service()

  if (!item) {
    return <div>Handle: item not found a href = back to launchpad...</div>
  }
  if (data && data.item) {
    item = data.item
  }

  const mintProcedure = async (state) => {
    const networkService = await connect(state.item.network)
    const minterContract = getContract(state.item.network, 'CollectionMinter')

    const approval = async (tokenContract, address, amount) => {
      console.log('approval begin', networkService.address, '=>', address, amount)
      let allowance, txHash

      const checkAllowance = async () => {
        allowance = await tokenContract.allowance(networkService.address, address)
      }

      await checkAllowance()
      while (tokenContract.fromWei(allowance) < tokenContract.fromWei(amount)) {
        console.log('allowance low', allowance, amount)
        if (!txHash) {
          txHash = await tokenContract.approve(address, amount)
        }
        await checkAllowance()
        await delay(5 * 1000)
      }
      console.log('allowance ok', allowance)
    }

    let value = 0
    if (state.contractData.price > 0) {
      if (state.contractData.tokenContractKey) {
        const tokenContract = getContract(state.item.network, state.contractData.tokenContractKey)
        await approval(tokenContract, minterContract.config.address, state.total)
      } else {
        value = state.total
      }
    }

    // console.log(minterContract)

    const onReceipt = (receipt) => {
      console.log('onReceipt', receipt)
    }

    switch (state.contractData.erc) {
    case ERCS.ERC_1155:
      await minterContract.mint1155(
        state.item.collectionIndex, state.ids, state.amounts, state.signature, value, onReceipt
      )
      break
    case ERCS.ERC_721A:
      await minterContract.mint721A(state.item.collectionIndex, state.amount, state.signature, value, onReceipt)
    }

  }

  const mint = () => {
    // console.log('mint btn', data)
    launchpadMint(data.item.id, 2)
      .then((result) => {
        console.log('state (mint)', result)
        if (result.signature) {
          console.log('got signature', result.signature, result.totalMinted)
          return mintProcedure(result)
        }
      })
      .catch(toastError)
  }

  const { name, description, image, state } = item

  return <Page.Container>
    <div>item page id: {id}</div>
    <Item.Image src={image}/>
    <StatusText state={state} />
    <Item.Name>{name}</Item.Name>
    <Item.Description>{description}</Item.Description>
    <ContractData data={data} />
    <Counters data={data} />
    <Page.TaskList>
      <LoadingContainer isLoading={!data || isLoading || isFetching}>
        {item.tasks.map(task => <TaskItem key={task.id} item={item} task={task} itemState={data} />)}
        <Button2 onClick={refetch}>Refresh button</Button2>
        {(data && data.canMint && <Button2 onClick={mint}>Mint button</Button2>) || null}
      </LoadingContainer>
    </Page.TaskList>
  </Page.Container>
}
