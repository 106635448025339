import React, { useContext, useRef, useState } from 'react'
import { useGetUserModel, useWebLogout } from '../hooks/useHoraHub'
import tw, { styled, css } from 'twin.macro'
import { IconAccountBody, IconAccountDot, IconArrowDropdown } from '../ui/Icons'
import Nav from '../ui/Nav'
import { PassCard } from '../ui/PassCard'
import { TokenBalance } from '../ui/TokenBalance'
import { useOutsideClick } from '../hooks/useDomEvents'
import { currencies } from '../libs/constants'
import { UiContext } from '../contexts/UiContext'
import { ModalTypes } from '../modals/AppModals'
import config from '../config'

const Container = styled.div`
  ${tw`relative z-20 px-6 -mr-6 transition-all duration-200 z-40`}
  .account-menu-namecontainer {
    > svg {
      ${tw`transition-all duration-200`}
    }
  }
  &.account-menu-open {
    ${tw`w-[29rem]`}
    > .account-menu-dropdown {
      transform: translateY(0);
      opacity: 1;
      display: block;
    }
    .account-menu-namecontainer {
      > svg {
        ${tw`rotate-180`}
      }
    }
  }
`

const AvatarContainer = styled.div`
  ${tw`flex`}
`

const IconAvatar = styled.img`
  ${tw`rounded-md ml-2`}
`

const Content = styled.div`
  ${tw`relative flex justify-between z-30 px-2`}
`

const NameContainer = styled.div`
  ${tw`flex cursor-pointer select-none`}
`

const Name = styled.span`
  color: var(--header-text-account);
  ${tw`text-xl leading-none ml-6 mr-2 mt-1`}
  /* ${(props) => (props.$noUser ? tw`hover:text-accent transition-all duration-200` : '')} */
`

const Dropdown = styled.div`
  ${tw`absolute w-full -top-10 left-0 rounded-b-2xl transition-all duration-200`}
  background-color: var(--account-menu-bg);
  /* transform: translateY(-600px); */
  opacity: 0;
  display: none;
`

const DropdownContent = styled.div`
  ${tw`p-8`}
`

const LinksContainer = styled.div`
  ${tw`mt-16 flex justify-between`}
`

const LinksContainerItem = styled.ul`
  ${tw`flex flex-col justify-between text-sm`}
`

const Balances = styled.div`
  ${tw`text-left text-sm font-medium`}
  > div {
    + div {
      ${tw`mt-2`}
    }
  }
`

const AccountMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useGetUserModel()
  const loggedIn = !!(user && user.id)
  const logout = useWebLogout()
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsOpen(false))
  const { openModal } = useContext(UiContext)
  const htokens = user.getTokenAmount(currencies.CURRENCY_HORA_TOKEN, { withdrawable: true, format: true })
  const hcoins = user.getTokenAmount(currencies.CURRENCY_HORA_COIN, { withdrawable: true, format: true })
  const photoSrc = user?.data?.photoSrc &&
    user.data.photoSrc.startsWith('https://cdn.discordapp.com/avatars/') &&
    user.data.photoSrc

  let emailText = user.verifiedEmail && `Email: ${user.verifiedEmail}`
  if (!emailText) {
    emailText = user.email && `Email (not verified): ${user.email}`
  }
  if (!emailText) {
    emailText = 'Email: unknown'
  }
  if (config.hideAccountEmail) {
    emailText = ''
  }

  const onEmailClick = () => {
    if (!user.verifiedEmail) {
      openModal(ModalTypes.LOGIN, { verifyEmail: true })
    }
  }

  return (
    <Container className={ isOpen ? 'account-menu-open' : '' } ref={wrapperRef}>
      <Content onClick={() => setIsOpen(!isOpen)}>
        <AvatarContainer>
          <IconAccountDot
            style={{ zIndex: 100 }}
            color={loggedIn ? css`var(--color-accent)` : css`var(--header-text-account)`}
          />
          {!photoSrc ?
            <IconAccountBody style={{ marginLeft: '0.1rem' }} color={css`var(--header-text-account)`} /> :
            <IconAvatar width={24} height={24} src={photoSrc} />
          }
        </AvatarContainer>
        <NameContainer className="account-menu-namecontainer">
          <Name>{user.name}</Name>
          <IconArrowDropdown
            style={{ alignSelf: 'center', marginTop: '0.2rem' }}
            color={css`var(--header-text-account)`}
          />
        </NameContainer>
      </Content>
      <Dropdown className="account-menu-dropdown">
        <DropdownContent>
          <LinksContainer>
            <LinksContainerItem>
              <Balances>
                <TokenBalance
                  flexCol
                  size='sm'
                  colorLabel={'var(--account-menu-token-label)'}
                  colorValue={'var(--account-menu-token-value)'}
                  label="Hora Coin"
                  value={hcoins}
                />
                <TokenBalance
                  flexCol
                  size='sm'
                  colorLabel={'var(--account-menu-token-label)'}
                  colorValue={'var(--account-menu-token-value)'}
                  label="Hora Token"
                  value={htokens}
                />
              </Balances>
            </LinksContainerItem>
            <LinksContainerItem>
              <Nav.Item>
                <Nav.PageLink
                  disabled={!loggedIn}
                  to={{}}
                  onClick={
                    () => openModal(ModalTypes.NETWORKS, { showConnected: true, allowDelete: true, closeOnAdd: false })
                  }
                >
                  Networks
                </Nav.PageLink>
              </Nav.Item>
              <Nav.Item>
                <Nav.PageLink to={'/support'}>
                  Support
                </Nav.PageLink>
              </Nav.Item>
              {emailText &&
                <Nav.Item>
                  <Nav.PageLink onClick={onEmailClick} to={{}}>
                    {emailText}
                  </Nav.PageLink>
                </Nav.Item>
              }
              <Nav.Item>
                {loggedIn ?
                  <Nav.PageLink onClick={logout} to={{}}>
                    Logout
                  </Nav.PageLink>
                  :
                  <Nav.PageLink to={{}} onClick={() => openModal(ModalTypes.LOGIN)}>
                    Login
                  </Nav.PageLink>
                }
              </Nav.Item>
            </LinksContainerItem>
          </LinksContainer>
          <PassCard/>
        </DropdownContent>
      </Dropdown>
    </Container>
  )
}

export default AccountMenu
