import React from 'react'

const ProgressBar = ({ bgcolor, completed }) => {

  const containerStyles = {
    height: 25,
    width: 150,
    backgroundColor: '#e0e0de',
    borderRadius: 30,
    margin: 30,
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div style={containerStyles}>
      <span style={labelStyles}>{`${completed}%`}</span>
      <div style={fillerStyles}></div>
    </div>
  )
}

export default ProgressBar
