import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { IconArrowDropdown } from './Icons'

const CARD_CONTENT_CLASS = 'card-content'

const Container = styled.div.attrs(props => ({
  className: props.collapsed ? 'card-collapsed' : '',
}))`
  ${tw`flex flex-col`}
  transition: all 0.2s ease-in-out;
  &.card-collapsed {
    .card-header-options {
      display: none;
    }
  }
`

const HeaderContainer = styled.div`
  ${tw`flex items-center py-5 select-none`}
`

const HeaderContent = styled.div`
 ${tw`flex flex-1 ml-3 justify-between`}
 ${(props) => (props.isWallet ? tw`w-[calc(100% - 4rem)] max-w-[22.5rem]` : '')}
`

const HeaderSection = styled.div`
  
`

const HeaderTitle = styled.span`
  color: var(--main-sections-title);
  cursor: pointer;
  ${tw`font-semibold text-xl`}
`

const HeaderButton = styled.button`
  color: var(--main-sections-title);
  ${tw`ml-5 font-semibold text-xl`}
  ${tw`transition duration-200 hocus:text-accent`}
`

const Stack = styled.div.attrs(
  (props) => ({ className: props.className ? `${props.className} ${CARD_CONTENT_CLASS}` : CARD_CONTENT_CLASS }))`
  ${tw`relative h-64`}
`
function HeaderOptions({ children }) {
  return (
    <div className='card-header-options'>
      {children}
    </div>
  )
}

const NetworkAddress = styled.span(({ color }) => [
  tw`font-bold`,
  color && css`color: ${color};`
])

const Section = styled.span(({ flex, flexDirection, justifyContent, alignItems }) => [
  tw`flex`,
  flex && css`flex: ${flex};`,
  flexDirection && css`flex-direction: ${flexDirection};`,
  justifyContent && css`justify-content: ${justifyContent};`,
  alignItems && css`align-items: ${alignItems};`
])

const Status = styled.div`
  ${tw`ml-2`}
`

function Header({ children, onClick, isWallet }) {
  return (
    <HeaderContainer>
      <div onClick={onClick} className="card-collapse-icon">
        <IconArrowDropdown
          style={{ cursor: 'pointer' }}
          color={'var(--main-sections-title)'}
        />
      </div>
      <HeaderContent isWallet={isWallet}>
        {children}
      </HeaderContent>
    </HeaderContainer>
  )
}

const ContentContainer = styled.div((
  {
    isNetworkCard,
    right,
    zIndex,
    transform,
    bgColor,
    borderColor,
    justifyContent,
  }) => [
  tw`w-full h-64 flex flex-col p-8 rounded-2xl border-2`,
  isNetworkCard && tw`absolute flex-1 border-2 px-7 pt-6 pb-5 w-[calc(100% - 4rem)] max-w-sm cursor-pointer`,
  isNetworkCard && css`box-shadow: 0 0 0 10px var(--main-bg);`,
  css`background-color: var(--color-bg-secondary);`,
  right && css`right: ${right};`,
  zIndex && css`z-index: ${zIndex};`,
  transform && css`transform: ${transform};`,
  bgColor && css`background-color: ${bgColor};`,
  borderColor && css`border-color: ${borderColor};`,
  justifyContent && css`justify-content: ${justifyContent};`,
])

const Content = (props) => (
  <ContentContainer
    {...props}
    className={ props.className ? `${props.className} ${CARD_CONTENT_CLASS}` : CARD_CONTENT_CLASS }
  >
    {props.children}
  </ContentContainer>
)

const SimpleContentContainer = styled.div(`
  ${tw`w-full`}
`)

const SimpleContent = (props) => (
  <SimpleContentContainer
    {...props}
    className={ props.className ? `${props.className} ${CARD_CONTENT_CLASS}` : CARD_CONTENT_CLASS }
  >
    {props.children}
  </SimpleContentContainer>
)

const Card = {
  Container,
  Header,
  HeaderContent,
  HeaderSection,
  HeaderOptions,
  HeaderTitle,
  HeaderButton,
  Stack,
  Content,
  SimpleContent,
  Section,
  Status,
  NetworkAddress,
}

export default Card

export const TokensContainer = styled.div`

`

export const ButtonsContainer = styled.div`
  button {
    + button {
      ${tw`ml-4`}
    }
  }
`
