import React from 'react'
import { ContainerScrollable, Content } from '../layouts/Layout'
import { PageMasking, PageMaskingTypes } from '../layouts/PageMasking'
import tw, { styled } from 'twin.macro'
import { useGetUserModel } from '../hooks/useHoraHub'
import { BalanceCard } from '../components/BalanceCard'
import { NetworksCard } from '../components/NetworksCard'
import { CoinsEarnedCard } from '../components/CoinsEarnedCard'
import { TransactionsCard } from '../components/TransactionsCard'
import { getGreeting } from '../libs/utils'

const WelcomeText = styled.span`
  color: var(--main-text-welcome);
  ${tw`
    block
    text-xl font-medium
    mt-6 mb-16
    // border border-green-500
  `}
`

const GridContainer = styled.div`
  ${tw`
    grid grid-cols-1 lg:grid-cols-2 gap-14
  `}
`

export const GridItem = styled.div`
  ${tw`
    flex justify-center items-center
    border border-red-500
    h-64
  `}
`

export function Home() {
  const user = useGetUserModel()

  return (
    <PageMasking masking={user.id ? '' : PageMaskingTypes.NO_LOGIN}>
      <ContainerScrollable className="masked-children">
        <Content>
          <WelcomeText>{user.id && getGreeting(user.name)}.</WelcomeText>

          <GridContainer>
            <BalanceCard />
            <NetworksCard />
            <CoinsEarnedCard />
            <TransactionsCard />
          </GridContainer>

        </Content>
      </ContainerScrollable>
    </PageMasking>
  )
}
