import React, { useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { IconArrowDropdown } from '../ui/Icons'
import { useOutsideClick } from '../hooks/useDomEvents'

const Container = styled.div`
  ${tw`relative flex justify-end items-center cursor-pointer`}
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
  color: var(--main-sections-title);
  fill: var(--main-sections-title);
  &.dropdown-open {
    .dropdown-arrow {
      ${tw`rotate-180`}
    }

    .dropdown-menu {
      display: block;
    }
  }
`

const ButtonArrow = styled.button`
  ${tw`cursor-pointer p-2`}
`

const Text = styled.span`
  ${tw`mx-1 text-lg font-semibold min-w-[2.6rem] text-center`}
`

const Menu = styled.ul`
  ${tw`absolute hidden top-7 right-0 px-4 py-4 z-10 rounded-b-lg cursor-default`}
  background-color: rgba(var(--main-dropdown-bg), 0.85);
  min-width: ${(props) => (props.minWidth ? props.minWidth : '')};
`

const Title = styled.span`
  ${tw`block text-sm font-medium mb-4`}
`

const Item = styled.li`
  ${tw`flex items-center font-semibold cursor-pointer`}
  + li {
    ${tw`mt-4`}
  }
  &:hover {
    span {
      opacity: 0.8;
    }
  }
`

const ItemCheckbox = styled.div`
  ${tw`w-4 h-4 mr-2 border-4 rounded-full`}
  border-color: var(--main-dropdown-check-border);
  background-color: ${(props) => (props.checked ? 'var(--main-dropdown-checked)' : 'var(--main-dropdown-bg)')};
`

const Dropdown = ({ minWidth, title, items, value, onChange }) => {
  const [ listOpen, setListOpen ] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setListOpen(false))

  const selectedItem = items.find(item => item.value === value)

  return (
    <Container minWidth={minWidth} ref={wrapperRef} className={ listOpen ? 'dropdown-open' : '' }>
      <Text onClick={() => setListOpen(!listOpen)}>
        {selectedItem.name}
      </Text>
      <ButtonArrow className='dropdown-arrow' onClick={() => setListOpen(!listOpen)}>
        <IconArrowDropdown />
      </ButtonArrow>
      <Menu className='dropdown-menu' minWidth={minWidth}>
        <Title>
          {title}
        </Title>
        {items.map(
          (item) =>
            <Item key={item.value} onClick={() => { setListOpen(false); onChange(item.value) }}>
              <ItemCheckbox checked={item === selectedItem} />
              <span>{item.name}</span>
            </Item>
        )}
      </Menu>
    </Container>
  )
}

export default Dropdown
