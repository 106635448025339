import React, { useContext, useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import {
  useBeginWithdraw,
  useGetUser,
  useWalletAddress,
  useWebSignPermit,
  useUpdateCryptoPaymentRecord,
  useGenerateDepositAddress,
} from '../hooks/useHoraHub'
import { useWeb3Service } from '../hooks/useWeb3Service'
import useToast from '../hooks/useToast'
import { getMessageForAddress } from '../services/HoraHub'
import config from '../config'
import { Button2 } from '../ui/Button'
import { ContainerScrollable } from '../layouts/Layout'
import { PageMasking, PageMaskingTypes } from '../layouts/PageMasking'
import { UiContext } from '../contexts/UiContext'
import { NetworksCard } from '../components/NetworksCard'

// const Container = styled.div`
//   ${tw`flex flex-col container mx-auto px-4`}
//   ${tw`border border-blue-400`}
// `

const Column = styled.div`
  ${tw`flex flex-row flex-wrap`}
`

// const Transactions = styled.div`
//   ${tw`flex flex-row`}
// `

const LeftColumn = styled.div`
  ${tw`border border-red-400`}
`

const RightColumn = styled.div`
  ${tw`flex-auto`}
  ${tw`border border-red-400`}
`

// eslint-disable-next-line max-statements
export function Test() {
  const { data: user } = useGetUser()
  const { connect, networkFromWithdrawMethod, getContract } = useWeb3Service()
  const { toastError } = useToast()
  const { updateWallet } = useWalletAddress()
  const beginWithdraw = useBeginWithdraw()
  const webSignPermit = useWebSignPermit()
  const updateCryptoPaymentRecord = useUpdateCryptoPaymentRecord()
  const generateDepositAddress = useGenerateDepositAddress()
  const [lastPayment, setLastPayment] = useState()
  const { openModal } = useContext(UiContext)

  // console.log(user)

  const connectTest = async (network, checkAddress) => {
    const networkService = await connect(network)
    const address = networkService.address
    if (checkAddress && checkAddress !== address) {
      throw new Error(`Address should be ${checkAddress}, but wallet address is ${address}`)
    }
    const message = await getMessageForAddress(address)
    const signature = await networkService.personalSign(message)
    await updateWallet({ address, network, signature })
    return networkService
  }

  const permitTest = async () => {
    const networkService = await connect('bsc')
    const { method, address } = networkService.getWithdrawParams({ free: false })
    if (!method) return
    await beginWithdraw(method, address, 1)
    alert('email sent!')
  }

  const executeWithdraw = async () => {
    const { withdrawAddress } = config.searchParams
    delete config.searchParams
    const { method, address, status, amount } = user?.withdraw || {}
    if (!method || status !== 'requested' || withdrawAddress !== address) {
      throw new Error('invalid withdraw request')
    }
    const { network } = networkFromWithdrawMethod(method)
    /* const networkService = */await connectTest(network, withdrawAddress)
    const { cryptoPayments } = await webSignPermit(method, withdrawAddress, amount)
    const lp = cryptoPayments[cryptoPayments.length - 1]
    setLastPayment(lp)
    // alert('done')
  }

  const permitProcedure = async () => {
    const { network, currency, state, spender, id } = lastPayment
    /* const networkService = */await connectTest(network, spender)
    const contract = getContract(network, currency)


    const doTransfer = async () => {
      /* let txHash = */await contract.transferFrom(state, async (receipt) => {
        console.log(receipt)
        await updateCryptoPaymentRecord({
          id,
          open: false,
          txHash: receipt.transactionHash,
          txResult: '1',
          withdrawStatus: 'completed',
          error: '',
        })
      })
    }

    console.log('begin', contract)

    /* let txHash = */await contract.executePermit(state, async (receipt) => {
      console.log(receipt)
      await updateCryptoPaymentRecord({
        id,
        permitTxHash: receipt.transactionHash,
        permitTxStatus: receipt.status,
        withdrawStatus: 'approval',
        error: '',
      })
      await doTransfer()
    })
  }

  const depositProcedure = async () => {
    const { network, currency, toAddress, amount } = lastPayment
    await connect(network)
    const contract = getContract(network, currency)

    await contract.transferTokens(toAddress, amount, async (receipt) => {
      console.log(receipt)
      // await updateCryptoPaymentRecord({
      //   id,
      //   open: false,
      //   txHash: receipt.transactionHash,
      //   txResult: '1',
      //   withdrawStatus: 'completed',
      //   error: '',
    })
  }

  const withdrawTest = async (method, amount) => {
    const { network } = networkFromWithdrawMethod(method)
    const networkService = await connectTest(network)
    const { cryptoPayments } = await webSignPermit(method, networkService.address, amount)
    const lp = cryptoPayments[cryptoPayments.length - 1]
    setLastPayment(lp)
  }

  const freeWithdraw = async () => {
    const networkService = await connect('bsc')
    const { method, address } = networkService.getWithdrawParams({ free: true })
    if (!method) return
    const { cryptoPayments } = await beginWithdraw(method, address, 1)
    const lp = cryptoPayments[cryptoPayments.length - 1]
    setLastPayment(lp)
  }

  const deposit = async (network, amount) => {
    const networkService = await connectTest(network)

    const { cryptoPayments } = await generateDepositAddress(
      { fromAddress: networkService.address, network, currency: 'HORA', amount }
    )
    const lp = cryptoPayments[cryptoPayments.length - 1]
    setLastPayment(lp)
  }

  useEffect(() => {
    if (user && config?.searchParams?.withdrawAddress) {
      executeWithdraw().catch(toastError)
    }
  })

  if (lastPayment) {
    return (
      <>
        <pre>
          {JSON.stringify(lastPayment, null, 4)}
        </pre>
        <Button2 onClick={permitProcedure}>proceed with permit procedure</Button2>
        <Button2 onClick={depositProcedure}>proceed with deposit</Button2>
      </>
    )
  }

  return (
    <PageMasking masking={user ? '' : PageMaskingTypes.NO_LOGIN}>
      <ContainerScrollable className="masked-children">
        <LeftColumn>
          <h1>Wallets</h1>
          <Button2 onClick={() => {openModal('withdraw')}}>WITHDRAW MODAL</Button2>
          <Button2 onClick={() => {openModal('deposit')}}>DEPOSIT MODAL</Button2>

          <Button2 onClick={() => {connectTest('tron').catch(toastError)}}>TEST TRON</Button2>
          <Button2 onClick={() => {connectTest('bsc').catch(toastError)}}>TEST BSC</Button2>
          <Button2 onClick={() => {connectTest('ethereum').catch(toastError)}}>TEST ETH</Button2>
          <Button2 onClick={() => {connectTest('polygon').catch(toastError)}}>TEST MATIC</Button2>
          <Column>
            <NetworksCard address={'hub'}/>
            <NetworksCard address={'TH3xcCoh2K7gojJRn54zj88s8YFcgBoPtc'} network={'tron'}/>
            <NetworksCard address={'0xB06a92c36599cB8De67e9D665BFeD5D27995988C'} network={'bsc'}/>
            <NetworksCard address={'0xB06a92c36599cB8De67e9D665BFeD5D27995988C'} network={'polygon'}/>
            <NetworksCard address={'0xB06a92c36599cB8De67e9D665BFeD5D27995988C'} network={'ethereum'}/>
          </Column>
          <Button2 onClick={permitTest}>web3-bsc-permit</Button2>
          <Button2 onClick={() => withdrawTest('web3-bsc-permit', 1)}>web3-bsc-permit on site</Button2>
          <Button2 onClick={freeWithdraw}>free withdraw</Button2>
          <Button2 onClick={() => deposit('tron', 1)}>deposit tron</Button2>
          <Button2 onClick={() => deposit('bsc', 1)}>deposit bsc</Button2>
        </LeftColumn>

        <RightColumn>
          <Column>
            <h1>Recent Transactions</h1>
            {user ? JSON.stringify(user.cryptoPayments) : ''}
          </Column>
          <Column>
            <h1>Tokens Earned</h1>
          </Column>
        </RightColumn>

      </ContainerScrollable>
    </PageMasking>
  )
}
