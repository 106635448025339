import React, { createContext, useState } from 'react'
import useInterval from '../hooks/useInterval'
import initialConfig, { loadConfig } from '../config'

export const ConfigContext = createContext(null)

export const ConfigReloaderProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig)
  useInterval(async () => {
    const newConfig = await loadConfig('website', true)
    if (newConfig) {
      setConfig({ ...newConfig })
    }
  }, config.configRefreshInterval)

  return (
    <ConfigContext.Provider
      value={config}
    >
      {children}
    </ConfigContext.Provider>
  )
}
