import React from 'react'
import { CenteredContent } from '../layouts/Layout'

export function About() {
  return (
    <CenteredContent>
      About
    </CenteredContent>
  )
}
