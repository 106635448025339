import React, { useContext } from 'react'
import Card from '../ui/Card'
import TxItem from '../ui/TxItem'
import { useGetUserModel, useUpdateCryptoPaymentRecord, useUpdateWalletBalances } from '../hooks/useHoraHub'
import { paymentReasons } from '../libs/constants'
import { useWeb3Service } from '../hooks/useWeb3Service'
import { formatCurrency, shortDate } from '../libs/utils'
import { usePagination } from '../hooks/usePagination'
import Pagination from './Pagination'
import { UiContext } from '../contexts/UiContext'
import useCardCollapse from '../hooks/useCardCollapse'
import useInterval from '../hooks/useInterval'

export function TransactionsCard() {
  const { collapsed, collapseToggle } = useCardCollapse('transactions')
  const { getContract } = useWeb3Service()
  const user = useGetUserModel()
  const data = [...user.cryptoPayments].reverse()
  const paginationData = usePagination({ data, pageSize: 3 })
  const { openModal } = useContext(UiContext)
  const updateCryptoPaymentRecord = useUpdateCryptoPaymentRecord()
  const updateWalletBalances = useUpdateWalletBalances()

  useInterval(checkAndUpdatePendingTransactions, 10 * 1000)

  function calcElapsedSecSinceLatestTransaction() {
    let latest = 0
    data && data.length && data.filter(cp => cp.reason === paymentReasons.WITHDRAW ||
      cp.reason === paymentReasons.DEPOSIT)
      .forEach(cp => {
        const t1 = new Date(cp.createdAt)
        const t2 = new Date(cp.updatedAt)
        latest = Math.max(t1.getTime(), t2.getTime(), latest)
      })
    return Math.floor((Date.now() - latest) / 1000)
  }

  let ticks = 0

  function checkAndUpdatePendingTransactions() {
    ++ticks
    const elapsedSec = calcElapsedSecSinceLatestTransaction()
    let shouldRefresh = false
    if (elapsedSec < 60) {
      shouldRefresh = true
    } else if (elapsedSec < 360 && ticks % 3 === 0) {
      shouldRefresh = true
    }

    if (shouldRefresh) {
      updateWalletBalances().catch(console.error)
    }
  }


  function canResumeWithdraw(cp, contract) {
    try {
      if (cp.open && contract && cp.withdrawMethod) {
        const method = { ...contract.networkService.config.withdraw[cp.withdrawMethod] }

        if (!method.free) {
          method.method = cp.withdrawMethod
          const numAmount = contract.fromWei(cp.amount)

          if (!cp.txHash && !cp.error) {
            return () => {
              openModal('withdraw', {
                paymentId: cp.id,
                step: 2,
                step1State: {
                  currency: cp.currency,
                  network: cp.network,
                  amount: cp.amount,
                  numAmount,
                  withdrawAddress: cp.toAddress,
                  method,
                },
              })
            }
          }
        }
      }
    } catch(e) {} // eslint-disable-line
  }

  function canRetryWithdraw(cp, contract) {
    try {
      if (cp.open && contract && cp.withdrawMethod) {
        const method = { ...contract.networkService.config.withdraw[cp.withdrawMethod] }

        if (!method.free) {
          method.method = cp.withdrawMethod
          const numAmount = contract.fromWei(cp.amount)

          if (cp.txHash && cp.error === 'failed') {
            return () => {
              openModal('withdraw', {
                paymentId: cp.id,
                step: 2,
                step1State: {
                  currency: cp.currency,
                  network: cp.network,
                  amount: cp.amount,
                  numAmount,
                  withdrawAddress: cp.toAddress,
                  method,
                },
              })
            }
          }
        }
      }
    } catch(e) {} // eslint-disable-line
  }

  function canResumeDeposit(cp, contract) {
    // try {
    if (cp.open && contract && !cp.txHash && !cp.error) {
      const numAmount = contract.fromWei(cp.amount)

      return () => {
        openModal('deposit', {
          paymentId: cp.id,
          step: 2,
          step1State: {
            currency: cp.currency,
            network: cp.network,
            amount: cp.amount,
            numAmount,
          },
        })
      }
    }
    // } catch(e) {} // eslint-disable-line
  }

  const canCancelDeposit = (cp) => {
    if (cp.open && !cp.txHash && !cp.error) {
      return (() => {
        updateCryptoPaymentRecord({ id: cp.id, error: 'cancelled' }).catch(console.error)
      })
    }
  }

  // eslint-disable-next-line max-statements
  function mapPaymentData(cp) {
    const date = shortDate(cp.updatedAt || cp.createdAt)
    const key = cp.id
    const { network, currency } = cp

    let type, status = 'pending', amount = '', txhash = '', onResume, onRetry, onCancel
    if (cp.txHash) {
      txhash = cp.txHash
    }
    let contract
    if ((cp.amount || cp?.status?.value) && network && currency) {
      try {
        contract = getContract(network, currency)
      } catch (e) {
        console.error(e)
      }
      if (contract) {
        let value = contract.fromWei(cp.amount || cp?.status?.value || '0')
        value = formatCurrency(cp.currency, value)
        amount = `${value} ${cp.currency || ''}`
      }
    }
    if (!cp.open) {
      if (cp.error) {
        status = 'failed'
      } else {
        status = 'completed'
      }
    }
    if (cp.open && cp.error === 'cancelled') {
      status = cp.error
    }
    switch (cp.reason) {
    case paymentReasons.WITHDRAW:
      type = 'Withdraw'
      onResume = canResumeWithdraw(cp, contract)
      onRetry = canRetryWithdraw(cp, contract)
      break
    case paymentReasons.DEPOSIT:
      type = 'Deposit'
      onResume = canResumeDeposit(cp, contract)
      onCancel = canCancelDeposit(cp)
      break
    }

    return { type, date, status, txhash, amount, key, network, currency, onResume, onRetry, onCancel, error: cp.error }
  }

  return (
    <Card.Container collapsed={collapsed}>
      <Card.Header onClick={collapseToggle}>
        <Card.HeaderTitle onClick={collapseToggle}>Recent Transactions</Card.HeaderTitle>
        <Card.HeaderOptions>
          <Pagination {...paginationData} />
        </Card.HeaderOptions>
      </Card.Header>
      <Card.Content borderColor='var(--balance-bg)'>
        {
          paginationData.list
            .map(cp => mapPaymentData(cp, getContract))
            .map((props) => <TxItem key={props.key} {...props} />)
        }
      </Card.Content>
    </Card.Container>
  )
}
