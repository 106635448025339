import React from 'react'
import tw, { styled, css } from 'twin.macro'

const Container = styled.div`
  ${tw`flex justify-between`}
  ${(props) => (props.$flexCol ? tw`flex-col` : '')}
  ${(props) => (props.$size === 'sm' ? '' : tw`text-2xl`)}
`

const Label = styled.span(({ color }) => [
  tw`font-medium`,
  css`color: ${color ? color : 'var(--balance-label)'};`
])

const Value = styled.span(({ color }) => [
  css`color: ${color ? color : 'var(--balance-value)'};`
])

export function TokenBalance({ colorLabel, colorValue, label, value, flexCol, size }) {
  return (
    <Container $flexCol={flexCol} $size={size}>
      <Label color={colorLabel}>{label}</Label>
      <Value color={colorValue}>{value}</Value>
    </Container>
  )
}
