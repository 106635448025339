import Web3 from 'web3/dist/web3.min.js'
import { decimalsToEthjsUnit } from './web3Utils'

class Web3Contract {
  constructor(networkService, key) {
    this.networkService = networkService
    this.config = networkService.config.contracts[key]
    this.contract = null
    this.key = key
    this.ready = false
  }

  log() {
    this.networkService.log(this.key, ...arguments)
  }

  logError() {
    this.networkService.log(this.key, 'Error', ...arguments)
  }

  async initToken() {
    const { address } = this.config
    this.log('initToken', address)
    this.contract = new this.networkService.web3.eth.Contract(this.config.abi, address)
    this.ready = true
    return 0
  }
}

// eslint-disable-next-line camelcase
class Web3Contract_ERC20 extends Web3Contract {
  constructor(networkService, key) {
    super(networkService, key)
    this.balance = 0
  }

  async initToken() {
    await super.initToken()
    this.ready = false
    const balance = await this.updateBalance()
    this.ready = true
    return balance
  }

  fromWei(n) {
    if (!n) return 0
    return parseFloat(Web3.utils.fromWei(n.toString(), decimalsToEthjsUnit(this.config.decimals)))
  }

  toWei(n) {
    if (!n) return 0
    const unit = decimalsToEthjsUnit(this.config.decimals)
    const str = n.toString()
    return Web3.utils.toWei(str, unit).toString()
  }

  get balanceFormatted() {
    return this.fromWei(this.balance, this.config.decimals)
  }

  updateBalance() {
    if (!this.networkService.connected || !this.networkService.address) {
      this.setBalance(0)
      return 0
    }
    this.log('updateBalance')
    if (!this.contract) return
    return this.contract.methods.balanceOf(this.networkService.address).call().then((result) => {
      this.setBalance(result)
      return this.balance
    })
  }

  setBalance(val) {
    this.balance = (val || 0).toString()
    this.networkService.modified()
  }

  addToWallet() {
    const data = {
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: this.config.address,
          symbol: this.config.symbol,
          decimals: this.config.decimals,
          image: this.config.image || 'https://assets.coingecko.com/coins/images/9344/small/' +
            '49051952_598867947211971_3910912166500237312_n.png?1566455428',
        },
      },
    }
    return this.networkService.provider.request(data)
  }

  transferTokens(
    recipient,
    amount, // in wei
    onReceipt
  ) {
    let tx = this.contract.methods.transfer(recipient, amount)
      .send({ from: this.networkService.address, gas: this.networkService.config.gasLimit })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  executePermit(withdrawState, onReceipt) {
    this.log(`executePermit${JSON.stringify(withdrawState)}`)
    const tx = this.contract.methods.permit(
      withdrawState.owner,
      withdrawState.spender,
      withdrawState.value,
      withdrawState.deadline,
      withdrawState.v,
      withdrawState.r,
      withdrawState.s
    ).send({
      from: this.networkService.address,
    })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  transferFromWithPermit(withdrawState, onReceipt) {
    this.log(`transferFromWithPermit${JSON.stringify(withdrawState)}`)
    const tx = this.contract.methods.transferFromWithPermit(
      withdrawState.owner,
      withdrawState.spender,
      withdrawState.value,
      withdrawState.deadline,
      withdrawState.v,
      withdrawState.r,
      withdrawState.s
    ).send({
      from: this.networkService.address,
    })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  transferFrom(withdrawState, onReceipt) {
    this.log(`transferFrom${JSON.stringify(withdrawState)}`)
    const tx = this.contract.methods.transferFrom(withdrawState.owner, withdrawState.spender, withdrawState.value)
      .send({
        from: this.networkService.address,
        // gas: 400000
      })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  approve(address, amount, onReceipt) {
    this.log('approve', address, amount)
    const tx = this.contract.methods.approve(address, amount)
      .send({
        from: this.networkService.address,
        // gas: 400000
      })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  allowance(owner, spender) {
    return this.contract.methods.allowance(owner, spender).call()
  }

}

// eslint-disable-next-line camelcase
class Web3Contract_TRC20 extends Web3Contract_ERC20 {

  async initToken() {
    const { address } = this.config
    this.log('initToken TRC20', address)

    try {
      this.contract = await this.networkService.provider.contract().at(address)
    } catch (e) {
      throw new Error('Error while initializing TRC20 token, check that tronLink is NOT connected to TESTNET')
    }
    const balance = await this.updateBalance()
    this.ready = true
    return balance
  }

  updateBalance() {
    this.log('updateBalance')
    if (!this.contract) return
    return this.contract.balanceOf(this.networkService.address).call().then((result) => {
      this.setBalance(result)
      return this.balance
    })
  }

  transferTokens(
    recipient,
    amount // in sun
    // onReceipt,
  ) {
    return this.contract.transfer(recipient, amount).send({
      // feeLimit: this.config.feeLimit,
      callValue:0,
      shouldPollResponse: false,
    })
  }
}

// eslint-disable-next-line camelcase
class Web3Contract_NFT1155 extends Web3Contract {

}

// eslint-disable-next-line camelcase
class Web3Contract_NFT721A extends Web3Contract {

}


// eslint-disable-next-line camelcase
class Web3Contract_CollectionMinter extends Web3Contract {

  async mint1155(index, ids, amounts, signature, value, onReceipt) {
    this.log(`mint1155 ${index} [${ids.join(',')}] [${amounts.join(',')}] ${signature}`)
    const tx = this.contract.methods.mint1155(index, ids, amounts, signature)
      .send({
        from: this.networkService.address,
        // gas: 400000
        value,
      })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

  async mint721A(index, amount, signature, value, onReceipt) {
    this.log(`mint721A ${index} ${amount} ${signature}`)
    const tx = this.contract.methods.mint721A(index, amount, signature)
      .send({
        from: this.networkService.address,
        // gas: 400000
        value,
      })

    return this.networkService.getTransactionHash(tx, onReceipt)
  }

}


function initializeContract(networkService, key) {
  let contract
  if (networkService.network === 'tron' && key === 'HoraToken') {
    contract = new Web3Contract_TRC20(networkService, key)
  } else {
    switch (key) {
    case 'HoraToken':
    case 'HoraCoin':
      contract = new Web3Contract_ERC20(networkService, key)
      break
    case 'HoraHubTokens':
      contract = new Web3Contract_NFT1155(networkService, key)
      break
    case 'HoraHubTokens721A':
      contract = new Web3Contract_NFT721A(networkService, key)
      break
    case 'CollectionMinter':
      contract = new Web3Contract_CollectionMinter(networkService, key)
      break
    }
  }
  return contract
}

export class AllContracts {
  constructor(networkService) {
    this.networkService = networkService
    this.log = networkService.log
    this.logError = networkService.logError
    this._contracts = []
    for (let key of Object.keys(this.networkService.config.contracts || {})) {
      const contract = initializeContract(this.networkService, key)
      this.networkService[key] = contract
      this._contracts.push(contract)
    }
  }

  getAddress() {
    return this._contracts.map(contract => contract.config.address)
  }

  getSymbol() {
    return this._contracts.filter(contract => contract.symbol).map(contract => contract.symbol)
  }

  getContractByAddress(contractAddress) {
    return this._contracts.find(contract => contract?.config?.address === contractAddress)
  }

  getContractBySymbol(symbol) {
    return this._contracts.find(contract => contract?.config?.symbol?.toUpperCase() === symbol.toUpperCase())
  }

  getContractByKey(key) {
    return this._contracts.find(contract => contract.key.toUpperCase() === key.toUpperCase())
  }

  checkAllReady() {
    let allReady = true
    this._contracts.forEach(contract => { if (!contract.ready) allReady = false})
    return allReady
  }

  waitForReady() {
    return new Promise((resolve) => {
      if (this.checkAllReady()) {
        return resolve()
      }
      let interval = setInterval(() => {
        if (this.checkAllReady()) {
          clearInterval(interval)
          return resolve()
        }
      }, 3000)
    })
  }

  async initializeTokens() {
    for (let contract of this._contracts) {
      await contract.initToken()
    }
  }

  static initialize(networkService) {
    if (networkService.AllContracts) return
    networkService.AllContracts = new this(networkService)
  }
}
