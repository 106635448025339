import React, { useContext, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { UiContext } from '../contexts/UiContext'
import { ModalTypes } from '../modals/AppModals'
import config from '../config'

import { Content } from './Layout'

export const PageMaskingTypes = {
  NO_LOGIN: 'nologin',
}

const messages = {
  // nologin: 'Not logged in!',
}

const MaskingContainer = styled.div`
  ${tw`absolute top-0 left-0 h-full w-full py-[9.45rem] z-30`}
  background-color: var(--main-bg);
`

const maskedCss = (props) => (
  props.isActive ?
    (
      `${tw`opacity-20`};` +
      ` background: rgba(0,0,0,0.5);
        pointer-events: none;
      `
    ) : tw`opacity-100`
)

const WrapperMasked = styled.div`
  ${tw`flex-1 relative overflow-hidden hidden md:block`}

  .masked-children {
    ${maskedCss}
    ${tw``}
  }
`

const MobileUnavailable = styled.div`
  ${tw`max-w-[20rem] mt-[30cqh] mx-auto text-center md:hidden`}
`

const Message = styled.div`
  ${tw`text-xl`}
`

const LoginButton = styled.button`
  ${tw`text-accent font-medium`}
`

const Text = styled.p`
  ${tw`font-medium`}
  color: var(--main-text);
`

export function PageMasking({ masking, children }) {
  const { openModal } = useContext(UiContext)

  useEffect(() => {
    if (masking === PageMaskingTypes.NO_LOGIN && config?.searchParams?.email) {
      openModal(ModalTypes.LOGIN)
    }
  }, [masking]) // eslint-disable-line

  return (
    <>
      <MobileUnavailable>
        <Content>
          <Message>
            <Text>Hora Hub is not available on mobile devices right now.</Text>
          </Message>
        </Content>
      </MobileUnavailable>
      <WrapperMasked isActive={!!masking}>
        {children}
        {masking && (
          <MaskingContainer>
            <Content>
              <Message>
                {messages[masking]}
                {masking === PageMaskingTypes.NO_LOGIN ? (
                  <LoginButton onClick={() => openModal(ModalTypes.LOGIN)}>Please login to continue.</LoginButton>
                ) : <Text>{masking}</Text>}
              </Message>
            </Content>
          </MaskingContainer>
        )}
      </WrapperMasked>
    </>
  )
}

Object.assign(PageMasking, {
  Message,
  Text,
  LoginButton,
  MobileUnavailable,
  WrapperMasked,
  MaskingContainer,
})
