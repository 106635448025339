import React, { useContext } from 'react'
import { useGetUserModel } from '../hooks/useHoraHub'
import { ConnectNetworkButton, NetworkConnectedButton } from '../ui/Button'
import { ModalTypes } from '../modals/AppModals'
import { UiContext } from '../contexts/UiContext'

const ConnectNetwork = ({ network }) => {
  const user = useGetUserModel()
  const { openModal } = useContext(UiContext)

  const doLogin = () => {
    openModal(ModalTypes.LOGIN, { connectNetwork: network })
  }

  const doNetwork = () => {
    openModal(ModalTypes.NETWORKS, { closeOnAdd: true, select: network, selectDisabled: true })
  }

  if (!user?.id) {
    return <ConnectNetworkButton onClick={doLogin}>Connect Wallet Or Login</ConnectNetworkButton>
  }

  const address = user.getWalletAddress(network)

  if (!address) {
    return <ConnectNetworkButton onClick={doNetwork}>Connect Wallet</ConnectNetworkButton>
  }

  return <NetworkConnectedButton>{user.name} Connected as {address}</NetworkConnectedButton>
}

export default ConnectNetwork
