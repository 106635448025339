const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export const ADDRESS_0 = '0x0000000000000000000000000000000000000000'

export const specialAddress = {
  HORAHUB: 'hub',
  IDLEMINER: 'cim',
}

export const currencies = {
  CURRENCY_HORA_COIN: 'HCOIN',
  CURRENCY_HORA_TOKEN: 'HORA',
}

export const updateTotals = {
  TOTAL_WITHDRAW: 'withdraw',
  TOTAL_DEPOSIT: 'deposit',
  TOTAL_INCOME: 'income',
}

export const paymentReasons = {
  UNKNOWN: 0,
  DEPOSIT: 1,
  WITHDRAW: 2,
}

export const WITHDRAW_MODE = 'withdraw'
export const DEPOSIT_MODE = 'deposit'

export const earningsIntervals = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
}

export const earningsIntervalsNames = {
  monthly: 'Monthly',
  weekly: 'Weekly',
  daily: 'Daily',
}

export const AlertTypes = {
  CHANGE: 'change',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export const ERCS = {
  ERC_20: '20',
  ERC_1155: '1155',
  ERC_721A: '721A',
}

export const LaunchpadItemStates = {
  ENDED: 'ended', // "Ended", "Learn More"
  REGISTRATION: 'registration', // "Registration Open", "Register"
  SELECTION: 'selection', // "Selection Status", "Claim"
}

export const QuestsItemStates = {
  ENDED: 'ended', // "Ended", "Learn More"
  REGISTRATION: 'registration', // "Registration Open", "Register"
  SELECTION: 'selection', // "Selection Status", "Claim"
}
