import React from 'react'
import { Container, Input, Slider } from '../ui/Input.jsx'
import { useGetUserModel } from '../hooks/useHoraHub'
import { DEPOSIT_MODE, WITHDRAW_MODE } from '../libs/constants'

const labels = {
  withdraw: 'Enter the amount you want to withdraw',
  deposit: 'Enter the amount you want to deposit',
}

export default function InputTokenAmount({ mode, value, currency, network, onChange }) {
  const user = useGetUserModel()
  let min = 0.000001
  let max = 1
  switch (mode) {
  case WITHDRAW_MODE:
    max = user.getTokenAmount(currency, { withdrawable: true }) || 1
    break
  case DEPOSIT_MODE:
    max = user.getTokenAmount(currency, { network }) || 1
    break
  }

  max = Math.floor(max)
  min = Math.max(min, 1)

  const getNumAmount = (amount) => {
    if (!amount) return 0
    const numAmount = parseFloat(amount)
    if (isNaN(numAmount) || numAmount < min || numAmount > max) return 0
    return numAmount
  }

  return (
    <Container>
      <Input
        label={labels[mode]}
        disabled={!currency}
        value={value}
        min={min}
        max={max}
        value2={currency}
        onChange={(e) => onChange([e.target.value, getNumAmount(e.target.value)])}
      />
      <Slider
        value={value}
        min={min}
        max={max}
        minClick={() => onChange([min, Math.floor(getNumAmount(min))])}
        maxClick={() => onChange([max, Math.floor(getNumAmount(max))])}
        onChange={(e) => onChange([parseInt(e.target.value, 10), Math.floor(getNumAmount(e.target.value))])}
      />
    </Container>
  )

}
