import React, { useState } from 'react' // eslint-disable-line
import { useStorage } from './useStorage'

export default function useCardCollapse(cardKey) {
  const storageKey = `${cardKey}Collapse`
  const { setItem, getItem, removeItem } = useStorage('local')
  const [collapsed, setCollapsed] = useState(!!getItem(storageKey))


  const collapseToggle = () => {
    const newState = !collapsed
    setCollapsed(newState)
    if (newState) {
      setItem(storageKey, '1')
    } else {
      removeItem(storageKey)
    }
  }

  return {
    collapsed,
    collapseToggle,
  }
}

