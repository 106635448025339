import React from 'react'
import { IconTxDeposit, IconTxWithdraw, IconTxTransfer } from './Icons'
import tw, { styled, css } from 'twin.macro'
import { useWeb3Service } from '../hooks/useWeb3Service'

const ItemContainer = styled.div`
  ${tw`flex items-stretch`}
  + div {
    ${tw`mt-8`}
  }
`

const IconContainer = styled.div`
  ${tw`flex items-center`}
`

const Info1 = styled.div`
  ${tw`flex flex-col min-w-[4.7rem] justify-between ml-3`}
`

const Info2 = styled.div`
  ${tw`flex flex-col flex-1 justify-between ml-3 min-w-0`}
`

const InfoRow = styled.div`
  ${tw`flex justify-between items-end`}
`

const InfoRowGroup = styled.div`
  ${tw`flex justify-between`}
`

const InfoType = styled.span`
  ${tw`font-medium text-sm`}
  color: var(--txn-label);
`

const InfoDate = styled.span`
  ${tw`font-medium text-xs`}
  color: var(--txn-date);
`

const InfoHash = styled.span(({ color }) => [
  tw`rounded-sm px-2 py-0.5 text-xs truncate mb-1 cursor-pointer`,
  // css`font-size: 0.625rem;`,
  css`color: rgba(0,0,0,0.4);`,
  color && css`background-color: ${color};`
])

const InfoStatus = styled.span(({ color }) => [
  tw`text-xs`,
  color && css`color: ${color};`
])

const InfoAmount = styled.span`
 ${tw`text-xs`}
 color: var(--txn-value);
`

const ResumeButton = styled.span`
 ${tw`text-xs ml-3`}
 color: var(--txn-cta);
 cursor: pointer;
`

const CancelButton = styled.span`
 ${tw`text-xs ml-3`}
 color: var(--txn-status-failed);
 cursor: pointer;
`

function TxItem({ type, date, txhash, status, amount, network, onResume, onRetry, onCancel, error }) {
  const { getNetwork } = useWeb3Service()
  const openBlockExplorer = () => {
    if (!txhash || !network) return
    const networkService = getNetwork(network)
    if (!networkService) return
    const link = networkService.generateBlockExplorerLink({ txHash: txhash })
    if (link) window.open(link)
  }

  let colorType
  let iconType
  switch (type.toLowerCase()) {
  case 'deposit':
    colorType = '--txn-deposit'
    iconType = <IconTxDeposit bgColor={`var(${colorType})`} fgColor={'var(--txn-icon-fg)'}/>
    break
  case 'withdraw':
    colorType = '--txn-withdraw'
    iconType = <IconTxWithdraw bgColor={`var(${colorType})`} fgColor={'var(--txn-icon-fg)'}/>
    break
  case 'transfer':
    colorType = '--txn-transfer'
    iconType = <IconTxTransfer bgColor={`var(${colorType})`} fgColor={'var(--txn-icon-fg)'}/>
    break
  }

  let statusText
  let statusColor
  let txHashStatus = txhash ? `TXN HASH: ${txhash}` : 'Waiting for TXN Hash...'
  switch (status.toLowerCase()) {
  case 'pending':
    statusText = '...Pending'
    statusColor = '--txn-status-pending'
    break
  case 'completed':
    statusText = 'Completed'
    statusColor = '--txn-status-completed'
    break
  case 'failed':
    statusText = error ? `Failed: ${error}` : 'Failed'
    statusColor = '--txn-status-failed'
    if (!txhash) {
      txHashStatus = '...'
    }
    break
  case 'cancelled':
    statusText = 'Cancelled'
    statusColor = '--txn-status-failed'
    if (!txhash) {
      txHashStatus = '...'
    }
    break
  }

  return (
    <ItemContainer>
      <IconContainer>
        {iconType}
      </IconContainer>
      <Info1>
        <InfoType>{type || 'Type'}</InfoType>
        <InfoDate>{date || '-- / --'}</InfoDate>
      </Info1>
      <Info2>
        <InfoHash color={`var(${colorType})`} onClick={openBlockExplorer}>
          {txHashStatus}
        </InfoHash>
        <InfoRow>
          <InfoRowGroup>
            <InfoStatus color={`var(${statusColor})`}>{statusText || '...'}</InfoStatus>
            {onResume ? <ResumeButton onClick={onResume}>Resume</ResumeButton> : null}
            {onRetry ? <ResumeButton onClick={onRetry}>Retry</ResumeButton> : null}
            {onCancel ? <CancelButton onClick={onCancel}>Cancel</CancelButton> : null}
          </InfoRowGroup>
          <InfoAmount>{amount || '...'}</InfoAmount>
        </InfoRow>
      </Info2>
    </ItemContainer>
  )
}

export default TxItem
