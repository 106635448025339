import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from './Select'
import Modal from '../ui/Modal'
import Form from '../ui/Form'
import { ButtonCTA, ButtonLink } from '../ui/Button'
import { useWebLogin, useRequestPassCode, useWalletLogin, useVerifyEmail } from '../hooks/useHoraHub'
import { useWeb3Service } from '../hooks/useWeb3Service'
import useToast from '../hooks/useToast'
import { getMessageForAddress } from '../services/HoraHub'
import { isValidEmail, openInNewTab } from '../libs/utils'
import { IconArrowRightLong } from '../ui/Icons'

const codeInputs = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
]

export function EmailForm({ onEmailSubmit, onConnectWallet, disabled, setLoading }) {
  const { toastError } = useToast()
  const { handleSubmit, register, formState, getValues, clearErrors } = useForm({ mode: 'onChange' })
  const { mutate } = useRequestPassCode(
    () => {
      setLoading(false)
      onEmailSubmit(getValues('email'))
    }, (err) => {
      setLoading(false)
      toastError(err)
    })
  const { isValid, isDirty } = formState

  const onSubmit = (data) => {
    setLoading(true)
    clearErrors()
    mutate(data.email)
  }

  return (
    <Form.Container onSubmit={handleSubmit(onSubmit)}>
      <Form.Input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        disabled={disabled}
        {...register('email', { required: 'Email is required', validate: isValidEmail })}
      />

      <ButtonCTA
        type="submit"
        variant="filled"
        marginTop="4xl"
        fullWidth
        disabled={!isDirty || !isValid || disabled}
      >
        Request code
      </ButtonCTA>

      <Modal.Text center color='darker' paddingY='sm'>or</Modal.Text>

      <ButtonCTA
        type="button"
        variant="secondary"
        // marginTop="4xl"
        fullWidth
        onClick={onConnectWallet}
        disabled={disabled}
      >
        Connect with Wallet
      </ButtonCTA>
    </Form.Container>
  )
}

export function WalletForm({ onLoggedIn, setLoading, params }) {
  const [ network, setNetwork ] = useState(params.connectNetwork || undefined)
  const { connect, eachNetwork } = useWeb3Service()
  const { toastError } = useToast()
  const walletLogin = useWalletLogin()

  const onSubmit = async () => {
    setLoading(true)
    try {
      const networkService = await connect(network)
      const address = networkService.address
      const message = await getMessageForAddress(address)
      const signature = await networkService.personalSign(message)
      const user = await walletLogin({ address, network, signature, create: !!params.connectNetwork })
      onLoggedIn(user)
    } catch (err) {
      // console.log(err)
      setLoading(false)
      toastError(err)
      return
    }
    setLoading(false)
  }

  let options = eachNetwork()
    .map((networkService) => {
      const networkId = networkService.network
      const networkName = networkService.networkName

      return {
        id: networkId,
        value: networkId,
        description: networkName,
      }
    })
  if (params.connectNetwork) {
    options = options.filter(opt => opt.id === params.connectNetwork)
  }
  // console.log(params, options, network)

  return (
    <Form.Container>
      <Select
        id="network"
        label='Network'
        placeHolder='Select network to connect'
        value={network}
        onChange={setNetwork}
        options={options}
        disabled={!!params.connectNetwork}
      />
      <Modal.Content>
        <ButtonCTA variant="secondary" marginTop="xl" fullWidth type="button" onClick={onSubmit} disabled={!network}>
          Connect
        </ButtonCTA>
      </Modal.Content>
    </Form.Container>
  )
}

const EnterCodeMessage = ({ email }) => (
  <div>
    <Modal.Text color='darker'>
      A message with verification code has been sent to <strong>{email}</strong>. Please enter code to continue.
    </Modal.Text>
    <Modal.Text color='darker'>Two-factor authentication</Modal.Text>
  </div>
)


export function CodeForm({ email, onLoggedIn, onRegister, onClearEmail, passCode, setLoading }) {
  const { toastError } = useToast()
  const [ code, setCode ] = useState(passCode)
  const [ canSubmit, setCanSubmit ] = useState(false)
  const { isLoading } = useWebLogin(
    email,
    code,
    onLoggedIn,
    (err) => { setLoading(false); toastError(err) }
  )
  const { register, setFocus, getValues, setValue, handleSubmit, clearErrors } = useForm()

  const getCode = () => {
    const values = Object.entries(getValues()).map(([, v]) => v)
    const ar = values.map(v => v && v.length === 1 && /\d/.test(v) ? v : '')
    const str = ar.join('')
    if (str.length === codeInputs.length) {
      return Object.values(getValues()).join('')
    }
  }

  const handleChange = (e, id) => {
    let v = e.target.value || ''
    const k = `code-${id}`

    if (v.length > 1) {
      v = v[0]
      setValue(k, v)
    }

    if (!/\d/.test(v)) {
      v = ''
      setValue(k, '')
    }

    if (!v) return

    if (id < codeInputs.length) {
      setFocus(`code-${id + 1}`)
    }

    if (id === codeInputs[codeInputs.length - 1].id) {
      setValue(k, v)
    }

    clearErrors()
    if (getCode()) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
      setCode('')
    }
  }

  const handleBackSpace = (e, id) => {
    if (e.keyCode !== 8) return
    setCanSubmit(false)
    setCode('')
    clearErrors()
    let v = e.target.value || ''
    if (v.length) return
    if (id === 1) return
    e.preventDefault()
    setFocus(`code-${id - 1}`)
  }

  const onSubmit = () => {
    setLoading(true)
    clearErrors()
    setCode(getCode())
  }

  if (!email) {
    return (
      <Modal.ContentSection>
        <ButtonLink onClick={onRegister} textSize="sm">
          Create new Hora ID
          <IconArrowRightLong color="var(--color-accent)"/>
        </ButtonLink>
      </Modal.ContentSection>
    )
  }

  const reset = () => {
    setCode('')
    clearErrors()
    codeInputs.forEach(({ id }) => setValue(`code-${id}`, ''))
    setCanSubmit(false)
    onClearEmail()
  }

  return (
    <>
      <EnterCodeMessage email={email} />
      <Form.Container autocomplete="off" onSubmit={handleSubmit(onSubmit)}>

        <Form.Label mb="xl" htmlFor="code">Enter code</Form.Label>
        <Form.NumInputContainer>
          {codeInputs.map((input) => (
            <Form.NumInput
              key={input.id}
              id={input.id}
              type="text"
              disabled={isLoading}
              {...register(`code-${input.id}`, {
                required: 'Code is required',
              })}
              onChange={e => handleChange(e, input.id)}
              onKeyDown={e => handleBackSpace(e, input.id)}
            />
          ))}
        </Form.NumInputContainer>

        <ButtonCTA type="submit" fullWidth marginTop="2xl" variant="filled" disabled={!canSubmit}>Login</ButtonCTA>

        <Modal.Text color='darker' mt='xl' textSize='sm'>
          Having problem with passcode?&nbsp;
          <ButtonLink inline type="button" onClick={reset}><strong>Resend code</strong></ButtonLink>
        </Modal.Text>

      </Form.Container>
    </>
  )
}

export function RegisterForm() {
  return (
    <Modal.ContentSection>
      <Modal.Text>Registration trough Hora Hub is not implemented yet,
        please download Crypto Idle Miner and create your Hora ID with your email address.
        Keep in mind that Hora Hub is currently open only to beta testers.
      </Modal.Text>
      <Modal.Text>
        If you want to become a beta tester,
        <br/>
        please join us on discord:
        <br/>
        <br/>
        <a href='https://discord.gg/horahub' target='__blank'>
          <b>https://discord.gg/horahub</b>
        </a>
      </Modal.Text>
      <ButtonCTA
        type="submit"
        fullWidth
        marginTop="4xl"
        variant="filled"
        onClick={() => openInNewTab('https://idleminer.onelink.me/Irub/d1851178')}
      >
        Download iOS / Android
      </ButtonCTA>
    </Modal.ContentSection>
  )
}

const EmailFormV2 = ({ disabled, onSubmit, submitButtonText, altButtonText }) => {
  const { handleSubmit, register, formState, clearErrors } = useForm({ mode: 'onChange' })
  const { isValid, isDirty } = formState

  return (
    <Form.Container
      onSubmit={handleSubmit((data) => {
        clearErrors()
        onSubmit(data.email)
      })}>
      <Form.Input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        disabled={disabled}
        {...register('email', { required: 'Email is required', validate: isValidEmail })}
      />

      <ButtonCTA
        type="submit"
        variant="filled"
        marginTop="4xl"
        fullWidth
        disabled={!isDirty || !isValid || disabled}
      >
        {submitButtonText}
      </ButtonCTA>

      <Modal.Text center color='darker' paddingY='sm'>or</Modal.Text>

      <ButtonCTA
        type="button"
        variant="secondary"
        // marginTop="4xl"
        fullWidth
        onClick={() => {
          clearErrors()
          onSubmit('')
        }}
        disabled={disabled}
      >
        {altButtonText}
      </ButtonCTA>
    </Form.Container>
  )
}

const CodeFormV2 = ({ onSubmit, onReset, disabled }) => {
  const [ canSubmit, setCanSubmit ] = useState(false)
  const { register, setFocus, getValues, setValue, handleSubmit, clearErrors } = useForm()

  const getCode = () => {
    const values = Object.entries(getValues()).map(([, v]) => v)
    const ar = values.map(v => v && v.length === 1 && /\d/.test(v) ? v : '')
    const str = ar.join('')
    if (str.length === codeInputs.length) {
      return Object.values(getValues()).join('')
    }
  }

  const handleChange = (e, id) => {
    let v = e.target.value || ''
    const k = `code-${id}`

    if (v.length > 1) {
      v = v[0]
      setValue(k, v)
    }

    if (!/\d/.test(v)) {
      v = ''
      setValue(k, '')
    }

    if (!v) return

    if (id < codeInputs.length) {
      setFocus(`code-${id + 1}`)
    }

    if (id === codeInputs[codeInputs.length - 1].id) {
      setValue(k, v)
    }

    clearErrors()
    if (getCode()) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const handleBackSpace = (e, id) => {
    if (e.keyCode !== 8) return
    setCanSubmit(false)
    clearErrors()
    let v = e.target.value || ''
    if (v.length) return
    if (id === 1) return
    e.preventDefault()
    setFocus(`code-${id - 1}`)
  }

  const doSubmit = () => {
    clearErrors()
    onSubmit(getCode())
  }

  const reset = () => {
    clearErrors()
    codeInputs.forEach(({ id }) => setValue(`code-${id}`, ''))
    setCanSubmit(false)
  }

  return (
    <>
      <Form.Container autocomplete="off" onSubmit={handleSubmit(doSubmit)}>

        <Form.Label mb="xl" htmlFor="code">Enter code</Form.Label>
        <Form.NumInputContainer>
          {codeInputs.map((input) => (
            <Form.NumInput
              key={input.id}
              id={input.id}
              type="text"
              disabled={disabled}
              {...register(`code-${input.id}`, {
                required: 'Code is required',
              })}
              onChange={e => handleChange(e, input.id)}
              onKeyDown={e => handleBackSpace(e, input.id)}
            />
          ))}
        </Form.NumInputContainer>

        <ButtonCTA type="submit" fullWidth marginTop="2xl" variant="filled" disabled={!canSubmit}>Login</ButtonCTA>

        <Modal.Text color='darker' mt='xl' textSize='sm'>
          Having problem with passcode?&nbsp;
          <ButtonLink inline type="button" onClick={() => { reset(); onReset() }}>
            <strong>Resend code</strong>
          </ButtonLink>
        </Modal.Text>

      </Form.Container>
    </>
  )
}

const EnterEmailMessage = () => (
  <Modal.Text color='darker' mt='xl' textSize='sm'>
    Please enter your email to...
  </Modal.Text>
)


export function VerifyEmailForm({ setLoading, closeModal }) {
  const { toast, toastError } = useToast()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const verifyEmail = useVerifyEmail()

  const onEmailVerified = () => {
    toast('Thank you for registering!')
    closeModal()
  }

  useWebLogin(
    email,
    code,
    () => { setLoading(false); onEmailVerified() },
    (err) => { setLoading(false); toastError(err) }
  )

  const onSubmitEmail = async (result) => {
    if (!result) {
      closeModal()
      return
    }

    setLoading(true)
    try {
      await verifyEmail(result)
      setEmail(result)
    } catch (err) {
      toastError(err)
    }
    setLoading(false)
  }

  const onSubmitCode = (result) => {
    setCode(result)
  }

  return (
    <>
      <EnterEmailMessage />
      <EmailFormV2
        onSubmit={onSubmitEmail}
        submitButtonText="Submit Email"
        altButtonText="Proceed without email"
        disabled={!!email}
      />
      {
        email ?
          <>
            <EnterCodeMessage email={email} />
            <CodeFormV2 onSubmit={onSubmitCode} onReset={() => { setCode(''); setEmail('') }}/>
          </> :
          null
      }

    </>
  )
}
