import React from 'react'
import { Link } from 'react-router-dom'
import 'twin.macro'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledLink = styled(Link)`
  /* min-height: 52px;
  svg {
    max-width: 100%;
  } */
`

const Logo = (props) => (
  <StyledLink to="/" {...props}>
    {/* <HoraHubLogoSVG /> */}
    <img src='/logo-beta.gif' width={60} tw='-mb-0 -mt-4' />
  </StyledLink>
)

export const HoraLogoSVG = () => (
  <svg width="117" height="65" viewBox="0 0 117 65" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.6667 0H0V65H47.6667V43.3333L58.5 32.5L47.6667 21.6667V0Z" />
    <path d="M69.3333 0V21.6667L58.5 32.5L69.3333 43.3333V65H117V0H69.3333Z" />
  </svg>
)

const HoraHubLogoSVG = () => ( // eslint-disable-line no-unused-vars
  <svg width="55" height="52" viewBox="0 0 55 52" fill="var(--sidebar-logo)" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.18652 41.5921L18.3335 36.3882L13.76 28.6019L9.18652 31.1843V41.5921Z"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M9.18638 0L0 5.20391L9.18638 10.4078V20.8157L13.7599 28.602L18.3333 25.9804V15.6117V5.20391L9.18638 0Z"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M45.8534 0L36.667 5.20391L45.8534 10.4078V20.8157L41.2405 28.602L45.8534 31.1844V41.5922L55.0003 36.3883V5.20391L45.8534 0Z"/>
    {/* eslint-disable-next-line max-len */}
    <path d="M36.6663 15.6118L27.5194 10.4078L18.333 15.6118L27.5194 20.8157V52L36.6663 46.7961V36.3883L41.2398 28.602L36.6663 25.9804V15.6118Z"/>
  </svg>
)

export default Logo

export const LogoContainer = styled.div`
  ${tw`
    hidden md:block px-6 pt-8
  `}
`
