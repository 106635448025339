import { useState } from 'react'

export function usePagination({ data, pageSize }) {
  const [page, setPage] = useState(0)
  const offset = page * pageSize
  const totalCount = data.length
  const pageCount = Math.max(Math.ceil(totalCount / pageSize) - 1, 0)
  const list = data.slice(offset, offset + pageSize)
  const handlers = {
    firstPage: pageCount > 0 && page > 0 ? () => setPage(0) : null,
    nextPage: page < pageCount ? () => setPage(page + 1) : null,
    prevPage: page > 0 ? () => setPage(page - 1) : null,
    lastPage: pageCount > 0 && page !== pageCount ? () => setPage(pageCount) : null,
  }

  return {
    page,
    setPage,
    offset,
    totalCount,
    pageCount,
    list,
    handlers,
  }
}
