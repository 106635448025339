import React, { useState } from 'react'
import { IconClose } from '../ui/Icons'
import Item from '../ui/WalletItems'
import { shortenAddress } from '../libs/utils'
import { useWalletAddress } from '../hooks/useHoraHub'
import { useStorage } from '../hooks/useStorage'
import useToast from '../hooks/useToast'

function getStorageKey(networkService) {
  const networkId = networkService.network
  return `${networkId}-addtowallet`
}

function resetAddTokensStorage(networkService, removeItem) {
  const storageKey = getStorageKey(networkService)
  removeItem(storageKey)
}

async function addTokens(networkService, tokensToAdd) {
  const result = []
  if (!networkService.connected) {
    await networkService.connect()
  }
  for (const contractKey of tokensToAdd) {
    await networkService[contractKey].addToWallet()
    result.push(contractKey)
  }
  return result.join(' ')
}

const AddTokensToWalletForm = ({ networkService }) => {
  const { setItem, getItem } = useStorage('local')
  const { toastError } = useToast()

  const tokensToAdd = []
  const storageKey = getStorageKey(networkService)
  const storageValue = getItem(storageKey) || ''
  const contractsConfig = networkService?.config?.contracts
  if (contractsConfig) {
    Object.keys(contractsConfig).forEach((key) => {
      if (networkService[key] && networkService[key].addToWallet) {
        if (!storageValue || !storageValue.includes(key)) {
          tokensToAdd.push(key)
        }
      }
    })
  }

  if (!tokensToAdd.length) {
    return null
  }

  const done = () => {
    setItem(storageKey, tokensToAdd.join(' '))
  }

  const add = () => {
    addTokens(networkService, tokensToAdd)
      .then(result => setItem(storageKey, result))
      .catch(toastError)
  }


  return <Item.AddTokensToWalletForm>
    Would you like to add our tokens contract address to your wallet?
    <strong><a onClick={add}>Yes</a> <a onClick={done}>No thanks</a></strong>
  </Item.AddTokensToWalletForm>
}

export default function WalletList({ networks, allowDelete, offerToAddTokens }) {
  const [uiState, setUiState] = useState({})
  const { removeItem } = useStorage('local')
  const { removeWallet } = useWalletAddress()

  const clearUiState = () => {
    setUiState({})
  }

  const networkServiceItem = ({ networkService, walletAddress }) => {
    const networkId = networkService.network
    const networkName = networkService.networkName
    let warningText, addTokensForm = null
    let itemOptions = null

    if (uiState.networkId === networkId && uiState.deleteActive) {
      const walletData = {
        network: networkId,
        address: walletAddress,
      }

      itemOptions = (
        <Item.Options>
          <Item.OptionText
            onClick={() => {
              clearUiState()
              removeWallet(walletData)
                .then(() => { resetAddTokensStorage(networkService, removeItem) })
                .catch(console.error)
            }}
          >
            Delete
          </Item.OptionText>
          <Item.OptionText onClick={clearUiState}>
            Cancel
          </Item.OptionText>
        </Item.Options>
      )
      warningText = 'WARNING: Be careful when deleting a network, be sure that all transactions are completed.'
    } else if (walletAddress && allowDelete) {
      itemOptions = (
        <Item.Options>
          <Item.OptionDelete onClick={() => { setUiState({ networkId, deleteActive: true }) }}>
            <IconClose color="var(--modal-close)"/>
          </Item.OptionDelete>
        </Item.Options>
      )
      if (offerToAddTokens) {
        addTokensForm = <AddTokensToWalletForm networkService={networkService}/>
      }
    }

    return (
      <Item.Item key={networkId}>
        <Item.ItemRow>
          <Item.Network>
            {networkName}
          </Item.Network>
          {itemOptions}
        </Item.ItemRow>
        <Item.ItemRow>
          <Item.Address>
            {shortenAddress(walletAddress)}
          </Item.Address>
        </Item.ItemRow>
        {warningText ? <Item.WarningText>
          {warningText}
        </Item.WarningText> : null}
        {addTokensForm}
      </Item.Item>
    )
  }

  return (
    <Item.Container>
      {
        networks
          .filter(({ walletAddress }) => walletAddress)
          .map(networkServiceItem)
      }
    </Item.Container>
  )
}

