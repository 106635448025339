import React from 'react'
import 'twin.macro'
import { Button2 } from '../ui/Button'
import { ThemeContext } from '../contexts/ThemeContext.jsx'

const modes = [
  {
    name: 'Auto',
    mode: 'auto',
  },
  {
    name: 'Light',
    mode: 'light',
  },
  {
    name: 'Dark',
    mode: 'dark',
  }
]

const ModeSelector = () => {
  const { themeMode, setTheme } = React.useContext(ThemeContext)

  return (
    <div>
      {
        modes.map(({ name, mode }) =>
          <Button2
            active={mode === themeMode}
            key={mode}
            onClick={() => setTheme(undefined, mode)}
          >
            {name}
          </Button2>
        )
      }
    </div>
  )
}

const ThemeSelector = () => {
  const { theme, themeLight, themeDark, setTheme, themes, themeMode, reset } = React.useContext(ThemeContext)

  return (
    <div tw="flex flex-col items-center space-y-10 p-10 bg-hora2 rounded-lg">
      <ModeSelector/>
      <ul>
        {
          Object.entries(themes)
            .filter(([, { mode }]) => mode && (mode === themeMode || themeMode === 'auto'))
            .map(([key, t]) => <li key={key}>
              <Button2
                active={theme === t || themeLight === t || themeDark === t}
                onClick={() => setTheme(t)}
              >
                {(theme === t ? '>>> ' : '') + t.name}
                {t.mode === 'light' ? <div /> : <div />}
              </Button2>
            </li>)
        }
      </ul>
      <button onClick={reset}>RESET AND RELOAD</button>
    </div>
  )
}

export default ThemeSelector
